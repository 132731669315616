import type { Ref, Component } from 'vue';
import { ref } from 'vue';
import { nanoid } from 'nanoid';
import { ToastType } from './enums/toast-type';
import type { Toast } from './types/toast';
import type { ToastOptions } from './types/toast-options';
import { ToastPlacement } from './enums/toast-placement';

const queue: Ref<Toast[]> = ref([]);

const defaultOptions = ref<ToastOptions>({
  timeout: 5000,
  placement: ToastPlacement.TopRight,
  hideProgress: false,
  closable: true,
  type: ToastType.Info,
  pauseOnHover: true,
  listeners: {},
  props: {},
});

export const useToast = () => {
  const remove = (id: string) => {
    const index = queue.value.findIndex(toast => toast.id === id);
    queue.value.splice(index, 1);
  };

  const add = (toast: Toast) => {
    queue.value = [...queue.value, toast];
    return toast;
  };

  const update = (
    id,
    {
      content,
      options,
    }: {
      content?: string | Component;
      options?: Partial<ToastOptions>;
    }
  ) => {
    const toast = queue.value.find(toast => toast.id === id);
    if (!toast) {
      return;
    }
    if (content) {
      toast.content = content;
    }
    if (options) {
      toast.options = { ...toast.options, ...options };
    }
  };

  const success = (content: string | Component, options: Partial<ToastOptions> = {}) => {
    const toast: Toast = {
      id: nanoid(),
      content,
      options: { ...defaultOptions.value, ...options, type: ToastType.Success },
    };
    return add(toast);
  };

  const info = (content: string | Component, options: Partial<ToastOptions> = {}) => {
    const toast: Toast = {
      id: nanoid(),
      content,
      options: { ...defaultOptions.value, ...options, type: ToastType.Info },
    };
    return add(toast);
  };

  const warning = (content: string | Component, options: Partial<ToastOptions> = {}) => {
    const toast: Toast = {
      id: nanoid(),
      content,
      options: { ...defaultOptions.value, ...options, type: ToastType.Warning },
    };
    return add(toast);
  };

  const danger = (content: string | Component, options: Partial<ToastOptions> = {}) => {
    const toast: Toast = {
      id: nanoid(),
      content,
      options: { ...defaultOptions.value, ...options, type: ToastType.Danger },
    };
    return add(toast);
  };

  const plain = (content: string | Component, options: Partial<ToastOptions> = {}) => {
    const toast: Toast = {
      id: nanoid(),
      content,
      options: { ...defaultOptions.value, ...options, type: ToastType.Plain },
    };
    return add(toast);
  };

  const toast = ({
    content,
    options,
  }: {
    content: string | Component;
    options?: Partial<ToastOptions>;
  }) => {
    const toast: Toast = {
      id: nanoid(),
      content,
      options: { ...defaultOptions.value, ...options },
    };
    return add(toast);
  };

  toast.success = success;
  toast.info = info;
  toast.danger = danger;
  toast.warning = warning;
  toast.plain = plain;

  return {
    queue,
    toast,
    remove,
    defaultOptions,
    update,
  };
};
