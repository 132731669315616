import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import { ThemeQueries } from '../graphql/theme.queries';
import { apolloClient } from '../../../apollo';

export class ThemeService {
  static async load(documentId: string): Promise<SigningTheme> {
    const {
      data: { theme },
    } = await apolloClient.query({
      query: ThemeQueries.theme,
      variables: {
        documentId,
      },
    });
    return theme;
  }
}
