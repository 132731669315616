import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import { entityQuery } from '@getaccept/lib-shared-new/src/entity/signing-site/entity.queries';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import { apolloClient } from '../../../apollo';

export class EntityService {
  static async getEntity(): Promise<Entity> {
    const {
      data: { entity },
    } = await apolloClient.query({
      query: entityQuery,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return entity;
  }
}
