import 'reflect-metadata';
import { createApp } from 'vue';
import VueSignaturePad from 'vue-signature-pad';
import { createPinia } from 'pinia';
import '@getaccept/lib-shared-new/src/bugsnag';
import Bugsnag from '@bugsnag/js';
import AppWrapper from './AppWrapper.vue';
import router from './router';
import i18n from './lang';
import './init';
import 'focus-visible';
import './css/global.scss';
import '@getaccept/fried-tofu/dist/style.css';

const app = createApp(AppWrapper);
const pinia = createPinia();
const bugsnagVue = Bugsnag.getPlugin('vue');
app.use(bugsnagVue);
app.use(pinia).use(router);
app.use(i18n);
app.use(VueSignaturePad);
(window as any).i18n = i18n.global;

router.isReady().then(() => {
  app.mount('#signing-page');
});

if (process.env.NODE_ENV === 'development') {
  console.log(`%c🚀 Running "signing-site" locally 🚀`, 'font-size: 1rem; line-height: 2rem;');
}
