import type { Field } from '../types/field';
import type { FieldDTO } from '../types/field-dto';
import { FieldType } from '../types/enums/field-type';
import { FieldSource } from '../types/enums/field-source';

export class FieldDTOHelper {
  static convertDTOsToFields(response: FieldDTO[]): Field[] {
    if (!response || response.length === 0) {
      return [];
    }
    return response.map((responseField: FieldDTO) => ({
      id: responseField.id,
      pageId: responseField.pageId,
      documentId: responseField.documentId,

      type: responseField.fieldType as FieldType,

      value: responseField.fieldValue,
      label: responseField.fieldLabel,
      options: responseField.fieldOptions.filter((option: string) => option !== ''),

      createdAt: responseField.createdAt,

      isRequired: responseField.isRequired,
      isEditable: responseField.isEditable,
      isCollectable: responseField.isCollectable,

      source: this.getFieldSource(
        responseField.fieldType,
        responseField.fieldValue,
        responseField.recipientId,
        responseField.fieldSource,
        false,
        responseField.isCollectable
      ),

      position: {
        top: responseField.fieldTop,
        left: responseField.fieldLeft,
        width: responseField.fieldWidth,
        height: responseField.fieldHeight,
      },

      validationRegex: responseField.validationRegex || '',
      validationWarning: responseField.validationWarning || '',

      signatureBiometric: responseField.signatureBiometric,

      recipientId: responseField.recipientId,
      verifiedInitials: responseField.verifiedInitials,
    }));
  }

  static convertFieldsToDTOs(fields: Field[]): FieldDTO[] {
    if (!fields || fields.length === 0) {
      return [];
    }
    return fields.map((field: Field) => ({
      id: field.id,
      pageId: field.pageId,
      documentId: field.documentId,

      fieldType: field.type,

      fieldValue: field.value,
      fieldLabel: field.label,
      fieldOptions: field.type === FieldType.Dropdown ? field.options || [''] : [],

      isRequired: field.isRequired,
      isEditable: field.isEditable,
      isCollectable: field.isCollectable,

      fieldSource: this.getFieldSource(
        field.type,
        field.value,
        field.recipientId,
        field.source,
        true,
        field.isCollectable
      ),

      fieldTop: field.position.top,
      fieldLeft: field.position.left,
      fieldHeight: field.position.height,
      fieldWidth: field.position.width,

      validationRegex: field.validationRegex || '',
      validationWarning: field.validationWarning || '',
      recipientId: field.recipientId,
    }));
  }

  static getFieldSource(
    type: FieldType | string,
    value: string,
    recipientId?: string,
    source?: FieldSource,
    override?: boolean,
    isCollectable?: boolean
  ): FieldSource {
    if (!override && !!source && source !== FieldSource.Unknown) {
      return source;
    }
    switch (type) {
      case FieldType.Merge:
        return this.getMergeFieldSource(recipientId, value, isCollectable);
      case FieldType.Link:
        return FieldSource.Document;
      case FieldType.Signature:
      case FieldType.Initials:
        return this.getSignatureFieldSource(value);
      case FieldType.Text:
      case FieldType.Check:
      case FieldType.Formatted:
      case FieldType.Dropdown:
        return this.getInputFieldSource(recipientId, isCollectable);
    }
  }

  static getMergeFieldSource(
    recipientId: string,
    value: string,
    isCollectable?: boolean
  ): FieldSource {
    const [fieldValueSource] = value.split('.');
    switch (fieldValueSource.toLocaleLowerCase()) {
      case 'document':
        return FieldSource.Document;
      case 'entity':
      case 'sender':
        return FieldSource.Sender;
      case 'date':
        return recipientId || isCollectable ? FieldSource.Recipient : FieldSource.Sender;
      case 'recipient':
        return FieldSource.Recipient;
      default:
        return FieldSource.Document;
    }
  }

  static getSignatureFieldSource(value: string): FieldSource {
    if (value.toLocaleLowerCase() === 'recipient') {
      return FieldSource.Recipient;
    }
    return FieldSource.Sender;
  }

  static getInputFieldSource(recipientId?: string, isCollectable?: boolean): FieldSource {
    if (recipientId || isCollectable) {
      return FieldSource.Recipient;
    }
    return FieldSource.Sender;
  }
}
