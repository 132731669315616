import gql from 'graphql-tag';

export class ParticipantsQueries {
  static loadParticipantInput = gql`
    query editorParticipantInput {
      editorParticipantInput {
        fieldId
        value
      }
    }
  `;
}
