export class UniversalLinkHelper {
  static universalLinkRegexOld = /(?:share)\/(?:d\/)(.*?)(?:\/|$|\?)/;
  static universalLinkRegex = /(?:dsr)\/([^/]*?)\/([^/]*?)?(?:\?|$)/;

  static getUniversalLinkKey(url: string): string {
    const match = UniversalLinkHelper.universalLinkRegex.test(url);
    if (match) {
      const [, , universalLinkId]: RegExpMatchArray = url.match(
        UniversalLinkHelper.universalLinkRegex
      );
      return `GA_DEALROOM_PUBLISHED_PAGE_UNIVERSAL_${universalLinkId}`;
    }

    const matchOld = UniversalLinkHelper.universalLinkRegexOld.test(url);
    if (matchOld) {
      const [, universalLinkId]: RegExpMatchArray = url.match(
        UniversalLinkHelper.universalLinkRegexOld
      );
      return `GA_SIGNING_PAGE_UNIVERSAL_${universalLinkId}`;
    }
    return '';
  }
}
