import type { Field } from '@getaccept/lib-shared-new/src/fields/types/field';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import { SigningMergeTagHelper } from '@getaccept/lib-shared-new/src/helpers/signing-merge-tag.helper';

export class MergeFieldHelper {
  public static mapMergeField(field: Field, document: SigningPageDocument, entity: Entity): Field {
    const hasTagsRegex = new RegExp(/{{.*}}/);
    const recipient: SigningPageRecipient =
      document.recipients.find(
        (documentRecipient: SigningPageRecipient) => documentRecipient.id === field.recipientId
      ) || document.recipients[0];
    let value = '';

    if (SigningMergeTagHelper.findTags(field.value).length) {
      value = SigningMergeTagHelper.replaceTags(field.value, {
        recipient,
        document,
        entity,
        field,
      });
    } else if (hasTagsRegex.test(field.value)) {
      value = SigningMergeTagHelper.stripTags(field.value);
    } else {
      field.displayProperty = SigningMergeTagHelper.getMergeDisplayProperty(field.value);
      value = SigningMergeTagHelper.getValue(
        field.displayProperty,
        { recipient, document, entity, field },
        false
      );
    }

    return {
      ...field,
      value,
    };
  }

  public static mapTextField(field: Field, document: SigningPageDocument, entity: Entity): Field {
    const recipient: SigningPageRecipient = document.recipients.find(
      (documentRecipient: SigningPageRecipient) => documentRecipient.id === field.recipientId
    );

    const value: string = SigningMergeTagHelper.replaceTags(field.value, {
      recipient,
      document,
      entity,
    });

    return {
      ...field,
      value,
    };
  }
}
