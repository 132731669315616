export enum MergeFieldDateFormat {
  iso = 'YYYY-MM-DD',
  short = 'LL',
  long = 'LLL',
  yyyy = 'YYYY',
  yy = 'YY',
  mm = 'MM',
  m = 'M',
  dd = 'DD',
  d = 'D',
  hh = 'HH',
  h = 'H',
  ii = 'mm',
  ss = 'ss',
  a = 'a',
  i = 'm',
}
