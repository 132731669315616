import Axios from 'axios';
import { AuthQuery } from '@getaccept/lib-shared-new/src/session/signing-site/session.queries';
import type { Token } from '@getaccept/lib-shared-new/src/session/type/token';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import { apolloClient } from '../../../apollo';
import { HttpThrottleError, HttpLockedError } from '../../../common/errors';

export class SessionService {
  static async getToken(
    documentId: string,
    recipientId: string,
    authId: string,
    authStepsInput?: AuthenticationStepInput[]
  ): Promise<Token> {
    const {
      data: {
        auth: { jwt, exp, authSteps, status, metaData },
      },
    } = await apolloClient.query({
      query: AuthQuery,
      variables: {
        documentId,
        recipientId,
        authId,
        authSteps: authStepsInput,
      },
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return { jwt, exp, authSteps, status, metaData };
  }

  static async postSendSmsLegacy(
    documentId: string,
    recipientId: string,
    authId: string,
    retryCount = 0
  ): Promise<AuthenticationStepInput> {
    const body = new FormData();
    body.append('type', 'open');
    if (retryCount > 0) {
      body.append('retry_count', `${retryCount}`);
    }
    const { data, status } = await Axios.post(
      `/view/${documentId}/${recipientId}/${authId}/send-sms`,
      body
    );

    if (status === 429) {
      throw new HttpThrottleError('Throttled');
    }
    if (status === 423) {
      throw new HttpLockedError('Locked');
    }

    return data;
  }

  static async postVerifySmsLegacy(
    authStepInput: AuthenticationStepInput,
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const body = new FormData();
    body.append('type', 'open');
    body.append('code', authStepInput.value);
    const { data } = await Axios.post(
      `/view/${documentId}/${recipientId}/${authId}/verify-sms`,
      body
    );
    return data.status === 1;
  }

  static async postQAAnswerLegacy(
    authStepInput: AuthenticationStepInput,
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<AuthenticationStepInput> {
    const body = new FormData();
    body.append('type', 'open');
    body.append('answer', authStepInput.value);
    await Axios.post(`/view/${documentId}/${recipientId}/${authId}/verify-qna`, body);
    return authStepInput;
  }

  static async postVerifyConsentLegacy(
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const { data } = await Axios.post(
      `/view/${documentId}/${recipientId}/${authId}/verify-consent`
    );
    return data.status === 1;
  }

  static async postRejectConsentLegacy(
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const { data } = await Axios.post(
      `/view/${documentId}/${recipientId}/${authId}/reject-consent`
    );
    return data.status === 1;
  }

  static async postVerifyTrackingConsentLegacy(
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const { data } = await Axios.post(
      `/view/${documentId}/${recipientId}/${authId}/verify-tracking-consent`
    );
    return data.status === 1;
  }

  static async postRejectTrackingConsentLegacy(
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const { data } = await Axios.post(
      `/view/${documentId}/${recipientId}/${authId}/reject-tracking-consent`
    );
    return data.status === 1;
  }
}
