import gql from 'graphql-tag';

export class QesQueries {
  static getDocumentQesFiles = gql`
    query GetQesFiles($documentId: String!) {
      getDocumentQesFiles(documentId: $documentId) {
        files {
          id
          fileType
          name
        }
      }
    }
  `;

  static getFileDownloadLink = gql`
    query FileDownloadLink($documentId: String!, $fileId: String!) {
      documentQesFileDownloadLink(documentId: $documentId, fileId: $fileId) {
        link
      }
    }
  `;
}
