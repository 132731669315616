export enum HintCodePending {
  outstandingTransaction = 'outstandingTransaction',
  noClient = 'noClient',
  started = 'started',
  userSign = 'userSign',
}
export enum HintCodeError {
  expiredTransaction = 'expiredTransaction',
  invalidParameters = 'invalidParameters',
  certificateErr = 'certificateErr',
  userCancel = 'userCancel',
  cancelled = 'cancelled',
  startFailed = 'startFailed',
  noClient = 'noClient',
  unknownError = 'unknownError',
  networkError = 'networkError',
}

export enum BankIdSeStatus {
  Failed = 'failed',
  Pending = 'pending',
  Complete = 'complete',
}

export enum BankIdStep {
  Start = 'start',
  Polling = 'polling',
  Failed = 'failed',
  Signed = 'signed',
  Completed = 'completed',
}

export enum BankIdSigningType {
  None = 'none',
  SameDevice = 'sameDevice',
  QR = 'qr',
}
