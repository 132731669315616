<template>
  <div class="lobby-container">
    <TopbarWrapper :logo-url="documentLogoUrl" />
    <div class="lobby-content">
      <LobbyCard v-if="isProcessing" :header="t('sealing_document_lobby_header')">
        {{ t('sealing_document_lobby_description') }}
        <FriedSpinner class="sealing-container-loader" />
      </LobbyCard>
      <LobbyCard v-else-if="error" :header="t('document-not-found')" :hide-backdrop="true">
        {{ t('document-not-found-lobby-description') }}
      </LobbyCard>
      <NonActiveDocumentPrompt
        v-else-if="showNonActiveDocumentModal"
        :document="document"
        :is-document-recalled="recalled"
      />
      <LobbyCard
        v-else-if="previewExpired"
        :header="t('preview-has-expired')"
        :hide-backdrop="true"
      >
        {{ t('preview-lobby-description') }}
      </LobbyCard>
      <LobbyCard v-else-if="gdprDeclined" :header="t('gdpr-declined')" :hide-backdrop="true">
        {{ t('gdpr-declined-description') }}
      </LobbyCard>
      <FriedModal v-else-if="showModal" :closable="false" :size="ModalSize.Small">
        <template #container>
          <Consents
            v-if="showConsents"
            :consent-steps="consentSteps"
            :submitting-response="submittingResponse"
            @answer="handleConsentAnswer"
            @decline="handleConsentDecline"
          />
          <template v-else-if="nextAuthStep">
            <IdentifyRecipient
              v-if="nextAuthStep.type === AuthenticationStepType.IdentifyRecipient"
              :auth-step="nextAuthStep"
              :submitting-response="submittingResponse"
              @answer="handleIdentifyRecipient"
            />
            <QnA
              v-else-if="nextAuthStep.type === AuthenticationStepType.VerifyQna"
              :auth-step="nextAuthStep"
              :submitting-response="submittingResponse"
              :error="qnaError"
              @answer="handleQnaAnswer"
            />
            <Sms
              v-else-if="nextAuthStep.type === AuthenticationStepType.VerifySms"
              :auth-step="nextAuthStep"
              :is-correct-pin="isCorrectPin"
              :submitting-response="submittingResponse"
              @send-pin="handleSendPin"
              @answer="handleSmsAnswer"
            />
          </template>
        </template>
      </FriedModal>
    </div>
  </div>
</template>
<script lang="ts">
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import Consents from '@getaccept/lib-shared-new/src/lobby/components/Consents.vue';
import LobbyCard from '@getaccept/lib-shared-new/src/lobby/components/LobbyCard.vue';
import { BrandingHelper } from '@getaccept/lib-shared-new/src/signing-theme/helpers/branding.helper';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import { ModalSize } from '@getaccept/fried-tofu';
import type { Channel } from '@getaccept/pusher';
import { DocumentEvent } from '@getaccept/pusher';
import type { PropType } from 'vue';
import { computed, defineComponent, onBeforeUnmount, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import TopbarWrapper from '../components/TopbarWrapper.vue';
import { DocumentViewableError } from '../api/documents/types/document-viewable-error';
import { DocumentService } from '../api/documents/services/document.service';
import { useDocumentStore } from '../documents/store/document.store';
import IdentifyRecipient from '../lobby/components/IdentifyRecipient.vue';
import NonActiveDocumentPrompt from '../lobby/components/NonActiveDocumentModal.vue';
import QnA from '../lobby/components/Qna.vue';
import Sms from '../lobby/components/Sms.vue';
import { pusherClient } from '../pusher';
import { usePusherStore } from '../pusher/store/pusher.store';
import { useSessionStore } from '../session/store/session.store';

export default defineComponent({
  name: 'Lobby',
  components: {
    LobbyCard,
    QnA,
    Sms,
    IdentifyRecipient,
    Consents,
    TopbarWrapper,
    NonActiveDocumentPrompt,
  },
  props: {
    consentSteps: {
      default: () => [],
      type: Array as PropType<AuthenticationStep[]>,
    },
    nextAuthStep: {
      type: Object as PropType<AuthenticationStep>,
      default: undefined,
    },
    submittingResponse: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    isCorrectPin: {
      type: Boolean,
      required: false,
    },
    recalled: {
      type: Boolean,
      required: false,
    },
    expired: {
      type: Boolean,
      required: false,
    },
    rejected: {
      type: Boolean,
      required: false,
    },
    gdprDeclined: {
      type: Boolean,
      required: false,
    },
    document: {
      type: Object as PropType<SigningPageDocument>,
      default: () => ({}),
    },
    entity: {
      type: Object as PropType<Entity>,
      default: () => ({}),
    },
    theme: {
      type: Object as PropType<SigningTheme>,
      default: () => ({}),
    },
    previewExpired: {
      type: Boolean,
      required: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'identifyRecipient',
    'qnaAnswer',
    'sendPin',
    'smsAnswer',
    'consentAnswer',
    'consentDecline',
  ],
  setup(props, { emit }) {
    const sessionStore = useSessionStore();
    const { qnaError } = storeToRefs(sessionStore);
    const { t } = useI18n();
    const showConsents = computed(
      () => !props.gdprDeclined && props.consentSteps && props.consentSteps.length > 0
    );

    const showModal = computed(() => !!props.nextAuthStep || showConsents.value);

    const documentLogoUrl = computed(() => BrandingHelper.getLogoUrl(props.theme));

    const showNonActiveDocumentModal = computed(
      () => props.recalled || props.rejected || props.expired
    );

    const handleIdentifyRecipient = (answer: AuthenticationStepInput) => {
      emit('identifyRecipient', answer);
    };
    const handleQnaAnswer = (authAnswer: AuthenticationStepInput) => {
      emit('qnaAnswer', authAnswer);
    };
    const handleSendPin = () => {
      emit('sendPin');
    };
    const handleSmsAnswer = (authAnswer: AuthenticationStepInput) => {
      emit('smsAnswer', authAnswer);
    };
    const handleConsentAnswer = (authStepsInput: AuthenticationStepInput[]) => {
      emit('consentAnswer', authStepsInput);
    };

    const handleConsentDecline = () => {
      emit('consentDecline');
    };

    const pusherStore = usePusherStore();
    const documentStore = useDocumentStore();

    const setSealDone = () => {
      documentStore.setProcessingDone();
    };

    const loadDocument = async () => {
      try {
        const document = await DocumentService.getDocument();
        if (document) {
          setSealDone();
        }
      } catch (error) {
        console.error('Error loading document', error);
      }
    };

    const pollDocumentTimeout = 1500;
    let pollMaxTries = 30;
    let pollTries = 0;

    const pollDocument = async () => {
      if (pollTries >= pollMaxTries) {
        return;
      }
      pollTries++;
      try {
        const isDocumentViewable = await DocumentService.isDocumentViewable();
        if (!isDocumentViewable) {
          setTimeout(pollDocument, pollDocumentTimeout);
          return;
        }
      } catch (error) {
        if (error?.message.includes(DocumentViewableError.Processing)) {
          setTimeout(pollDocument, pollDocumentTimeout);
          return;
        }
      }
      loadDocument();
    };

    onMounted(() => {
      if (!props.isProcessing) {
        return;
      }
      pollDocument();
      if (pusherStore.documentChannel) {
        pusherClient.listen(
          pusherStore.documentChannel as Channel,
          DocumentEvent.Sealed,
          setSealDone
        );
      }
    });

    onBeforeUnmount(() => {
      if (pusherStore.documentChannel) {
        pusherClient.unbind(
          pusherStore.documentChannel as Channel,
          DocumentEvent.Sealed,
          setSealDone
        );
      }
    });

    return {
      showConsents,
      showModal,
      handleIdentifyRecipient,
      handleQnaAnswer,
      handleSendPin,
      handleSmsAnswer,
      handleConsentAnswer,
      handleConsentDecline,
      documentLogoUrl,
      showNonActiveDocumentModal,
      AuthenticationStepType,
      ModalSize,
      qnaError,
      t,
    };
  },
});
</script>
<style scoped lang="scss">
.lobby-container {
  height: 100vh;
  overflow: hidden;

  .sealing-container-loader {
    margin-top: var(--spacing-100);
  }
}
</style>
