import { dealroomEditorBlockViewFragment } from '@getaccept/editor-lib-new';
import gql from 'graphql-tag';

export class DSRQueries {
  static load = gql`
    query dealroom {
      dealroom {
        id
        name
        createdAt
        details {
          expirationDate
          uniqueId
          companyName
          value
        }
        settings {
          featureSettings {
            disableChat
            disableActionPlan
            disableComments
            disableFiles
          }
        }
        privacy {
          universalLink
        }
        access
      }
    }
  `;

  static loadPages = gql`
    query dealroomPages {
      dealroomPages {
        id
        contentVersion
        editorBlock {
          ...dealroomEditorBlockViewFragment
        }
        isLandingPage
      }
    }
    ${dealroomEditorBlockViewFragment}
  `;

  static loadRevisions = gql`
    query DealroomRevisions {
      dealroomRevisions {
        viewedByParticipant
        version
        pages {
          contentVersion
          editorBlockId
          isLandingPage
        }
      }
    }
  `;

  static loadParticipants = gql`
    query Participants($includeDeletedParticipants: Boolean) {
      participants(includeDeletedParticipants: $includeDeletedParticipants) {
        id
        role
        userId
        contactId
        legacyRecipientId
        newParticipantId
        details {
          email
          firstName
          lastName
          phone
          title
          companyName
          companyNumber
          thumbUrl
        }
        deletedAt
      }
    }
  `;

  static loadParticipant = gql`
    query Participant {
      participant {
        id
      }
    }
  `;

  static theme = gql`
    query Theme($documentId: String, $entityId: String) {
      theme(documentId: $documentId, entityId: $entityId) {
        name
        backgroundColor
        backgroundImage
        backgroundType
        textColor
        accentColor
        fontSize
        fontFamily
        customCss
        logoUrl
        signButtonText
      }
    }
  `;

  static loadRoomSettings = gql`
    query dealroom {
      dealroom {
        settings {
          featureSettings {
            disableChat
            disableActionPlan
            disableComments
            disableFiles
          }
        }
      }
    }
  `;
}
