import type { DateTime } from 'luxon';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import { EidType } from '../types/enums/eid-type';

export class EidHelper {
  private static readonly staticBaseUrl: string =
    process.env.VUE_APP_EID_API_URL || 'eid.ga-staging.com';

  static criiptoRedirect(
    eid: EidType,
    documentId: string,
    recipientId: string,
    authId: string
  ): void {
    const url = `https://${this.staticBaseUrl}/${this.getPath(
      eid
    )}/login?documentId=${documentId}&recipientId=${recipientId}&authId=${authId}`;
    window.location.assign(url);
  }

  static getPath(eid: EidType): string {
    switch (eid) {
      case EidType.MitID:
        return 'bankid-mitid';
      case EidType.NO:
        return 'bankid-no-v2';
      case EidType.SE:
        return 'bankid-se';
      case EidType.BankIDFI:
        return 'bankid-fi';
    }
  }

  static getElapsedSeconds(time: DateTime) {
    return time.diffNow().negate().as('seconds');
  }

  static getDefaultMethod(timezone: string, userLang: string, entityCountryCode: string): EidType {
    switch (userLang) {
      case 'sv':
        return EidType.SE;
      case 'dk':
        return EidType.MitID;
      case 'no':
      case 'nn':
      case 'nb':
        return EidType.NO;
      case 'fi':
        return EidType.BankIDFI;
    }

    switch (timezone) {
      case 'Europe/Stockholm':
        return EidType.SE;
      case 'Europe/Oslo':
        return EidType.NO;
      case 'Europe/Helsinki':
        return EidType.BankIDFI;
      case 'Europe/Copenhagen':
        return EidType.MitID;
    }

    switch (entityCountryCode) {
      case 'SE':
        return EidType.SE;
      case 'NO':
        return EidType.NO;
      case 'FI':
        return EidType.BankIDFI;
      case 'DK':
        return EidType.MitID;
    }

    return null;
  }

  static getPreselectedMethod(recipient: SigningPageRecipient) {
    switch (recipient?.verifyEidType) {
      case 'bankid':
        return EidType.SE;
      case 'bankid-no':
        return EidType.NO;
      case 'bankid-fi':
        return EidType.BankIDFI;
      case 'mitd':
        return EidType.MitID;
      default:
        return null;
    }
  }
}
