import gql from 'graphql-tag';
import { editorBlockViewFragment } from '@getaccept/editor-lib-new';
import { videoFragment } from '../../video/graphql/video.fragment';

export class DocumentFragments {
  static contentLinkBlockFragment = gql`
    fragment contentLinkBlockFragment on ContentLinkBlock {
      buttonText
      description
      id
      thumbUrl
      title
      url
    }
  `;

  static pageFragment = gql`
    fragment pageFragment on DocumentPage {
      id
      pageHeight
      pageNum
      pageType
      pageWidth
      thumbUrl
      video {
        ...videoFragment
      }
      contentLinkBlock {
        ...contentLinkBlockFragment
      }
      editorBlock {
        ...editorBlockViewFragment
      }
    }
    ${videoFragment}
    ${DocumentFragments.contentLinkBlockFragment}
    ${editorBlockViewFragment}
  `;

  static signingDocumentsRecipientFragment = gql`
    fragment signingDocumentsRecipientFragment on Recipient {
      id
      fullName
      firstName
      lastName
      email
      mobile
      phone
      role
      status
      title
      companyName
      companyNumber
      thumbUrl
      orderNum
      note
      signature
      signDate
      verifyEidType
    }
  `;

  static signaturePageFragment = gql`
    fragment signaturePageFragment on SignaturePage {
      pageWidth
      pageHeight
      pageNum
      thumbUrl
    }
  `;

  static signingUserFragment = gql`
    fragment signingUserFragment on User {
      email
      fullName
      firstName
      lastName
      id
      mobile
      phone
      thumbUrl
      title
      signature
      signDate
    }
  `;
}
