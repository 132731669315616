import type { Token } from '@getaccept/lib-shared-new/src/session/type/token';

export class UrlHelper {
  static formatRedirectUrl(token: Token) {
    const pathRegExp = /\/([a-z,\d]+)\/([a-z,\d]+)\/a\/([a-z,\d]+)/g;
    const newPathRegExp = /(\/dsr)(\/[a-z0-9]+\/[a-z0-9]+\/[a-z0-9]+)(.*)/i;
    const { redirectUrl } = token.metaData;

    let newUrl = '';
    const matchOldUrl = pathRegExp.test(window.location.href);

    if (matchOldUrl) {
      const duplicateEntityId =
        window.location.href.includes('entityId') && redirectUrl.includes('entityId');

      const newRedirectUrl = duplicateEntityId
        ? redirectUrl.replace(/\?entityId.*$/, '')
        : redirectUrl;

      newUrl = window.location.href.replace(pathRegExp, newRedirectUrl);
    } else {
      newUrl = window.location.href.replace(newPathRegExp, `$1${redirectUrl}$3`);
    }

    const formattedUrl = new URL(newUrl);
    formattedUrl.searchParams.append('token', token.jwt);
    return String(formattedUrl);
  }
}
