import { useRouteQuery } from '@vueuse/router';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import type { EidType } from '../../types/enums/eid-type';
import type { CriiptoStatus } from '../../types/enums/criipto-status';

export const useResumeEidSigning = () => {
  const route = useRoute();
  const router = useRouter();

  const routerQueryConfig = computed(() => ({ route, router }));

  const eidType = useRouteQuery('eidType', undefined as EidType, routerQueryConfig.value);

  const criiptoMethodSigningStatus = useRouteQuery(
    'eidStatus',
    undefined as CriiptoStatus,
    routerQueryConfig.value
  );

  const bankIdSwedenOrderRef = useRouteQuery(
    'orderRef',
    undefined as string,
    routerQueryConfig.value
  );

  const bankIdSwedenAutoStartToken = useRouteQuery(
    'autoStartToken',
    undefined as string,
    routerQueryConfig.value
  );

  const resetQueryParams = () => {
    eidType.value = undefined;
    criiptoMethodSigningStatus.value = undefined;
    bankIdSwedenOrderRef.value = undefined;
  };

  return {
    eidType,
    criiptoMethodSigningStatus,
    bankIdSwedenOrderRef,
    bankIdSwedenAutoStartToken,
    resetQueryParams,
  };
};
