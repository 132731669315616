import gql from 'graphql-tag';

export class UniversalLinkQueries {
  static verifySharedDealroom = gql`
    query verifySharedDealroom($dealroomId: String!, $entityId: String!) {
      verifySharedDealroom(dealroomId: $dealroomId, entityId: $entityId) {
        dealroomName
        entityName
        requireGdprConsent
        consentText
        trackingConsent
        trackingText
      }
    }
  `;
}
