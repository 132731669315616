<template>
  <FriedDropdown
    :placement="placement"
    :main-axis-offset="mainAxisOffset"
    :cross-axis-offset="crossAxisOffset"
  >
    <template #trigger>
      <LockButton />
    </template>
    <template #content>
      <UpgradeInfo :text="text" @contact="$emit('contact')" />
    </template>
  </FriedDropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Placement, type PlacementType } from '@getaccept/fried-tofu';
import LockButton from './LockButton.vue';
import UpgradeInfo from './UpgradeInfo.vue';

export default defineComponent({
  components: {
    LockButton,
    UpgradeInfo,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    offset: {
      type: Array as PropType<number[]>,
      default: () => [0, 0],
    },
    placement: {
      type: String as PropType<PlacementType>,
      default: Placement.BottomStart,
    },
    mainAxisOffset: {
      type: Number,
      default: 0,
    },
    crossAxisOffset: {
      type: Number,
      default: 0,
    },
  },
  emits: ['contact'],
});
</script>
