<template>
  <div
    :style="safeBackground"
    :class="['signing-page-app', { 'has-fixed-background': hasFixedBackground }]"
  >
    <div v-if="loading" class="circle">
      <FriedSpinner class="loader" />
    </div>
    <Lobby
      v-else-if="showLobby"
      :consent-steps="consentSteps"
      :next-auth-step="nextAuthStep"
      :recalled="documentRecalled"
      :error="hasError"
      :sms-verified="isCorrectPin"
      :expired="documentExpired"
      :rejected="documentRejected"
      :preview-expired="previewExpired"
      :gdpr-declined="gdprDeclined"
      :document="document"
      :entity="entity"
      :theme="theme"
      :submitting-response="authentication.submittingResponse"
      :is-correct-pin="isCorrectPin"
      :is-processing="isProcessing"
      @identifyRecipient="actionSubmitAnswer([$event])"
      @qnaAnswer="actionSubmitQnaAnswer"
      @sendPin="actionSendSmsCode"
      @consentAnswer="actionSubmitConsent"
      @consentDecline="actionDeclineConsent"
      @smsAnswer="actionSubmitSmsAnswer"
    />
    <router-view v-else-if="documentAvailable" />
    <Toasts :offset="{ top: 88, bottom: 72, right: 16, left: 16 }" />
  </div>
</template>

<script lang="ts">
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
import { BrowserHelper } from '@getaccept/lib-shared-new/src/chat/helpers/browser.helper';
import { BackgroundType } from '@getaccept/lib-shared-new/src/entity/types/enums/background-type';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { BrandingHelper } from '@getaccept/lib-shared-new/src/signing-theme/helpers/branding.helper';
import { Toasts } from '@getaccept/lib-shared-new/src/toast';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { UniversalLinkHelper } from '@getaccept/lib-shared-new/src/universal-link/helpers/universal-link.helper';
import { DateTime } from 'luxon';
import { useLaunchDarkly } from '@getaccept/lib-shared-new/src/launch-darkly/launch-darkly.composable';
import { AnalyticsCompanyTrait } from '@getaccept/lib-shared-new/src/types/analytics';
import { LaunchDarklyContextKind } from '@getaccept/lib-shared-new/src/launch-darkly/types/enums/launch-darkly-context-kind';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { useDocumentStore } from './documents/store/document.store';
import { useEntityStore } from './entity/store/entity.store';
import { DocumentHelper } from './helpers/document.helper';
import Lobby from './pages/Lobby.vue';
import { pusherClient } from './pusher';
import { usePusherStore } from './pusher/store/pusher.store';
import { useSessionStore } from './session/store/session.store';
import { useRootStore } from './store/root.store';
import { useThemeStore } from './theme/theme.store';
import { useLoadFeatures } from './features/composables/load-features.composable';
import router from './router';

export default defineComponent({
  components: {
    Lobby,
    Toasts,
  },
  setup() {
    const rootStore = useRootStore();
    const { authId, documentId, recipientId, userId, isPreview, previewExpired } =
      storeToRefs(rootStore);

    const { initialize: initializeLD } = useLaunchDarkly();
    const { loadFeatures } = useLoadFeatures();

    const actionSetPusherConnected = (connected: boolean) =>
      rootStore.setPusherConnected(connected);

    const sessionStore = useSessionStore();
    const {
      token,
      isCorrectPin,
      error: sessionError,
      authentication,
      nextAuthStep,
      documentRecalled,
      documentUnpublished,
      consentSteps,
      gdprDeclined,
    } = storeToRefs(sessionStore);

    const actionSubmitAnswer = (authStepsInput: AuthenticationStepInput[]) => {
      sessionStore.submitAnswer(authStepsInput);
    };

    const actionSendSmsCode = () => {
      sessionStore.sendSmsCode();
    };

    const actionSubmitSmsAnswer = (authStepsInput: AuthenticationStepInput) => {
      sessionStore.submitSmsAnswer(authStepsInput);
    };

    const actionSubmitQnaAnswer = (authStep: AuthenticationStepInput) => {
      sessionStore.submitQnaAnswer(authStep);
    };

    const actionSubmitConsent = (authStepsInput: AuthenticationStepInput[]) => {
      sessionStore.submitConsent(authStepsInput);
    };

    const actionDeclineConsent = () => {
      sessionStore.declineConsent();
    };

    const documentStore = useDocumentStore();
    const { document, isProcessing, error: documentError } = storeToRefs(documentStore);

    const documentAvailable = ref(false);
    const themeStore = useThemeStore();
    const { theme } = storeToRefs(themeStore);
    const entityStore = useEntityStore();
    const { entity } = storeToRefs(entityStore);
    const loading = ref(true);

    const hasValidToken = computed(() => {
      const notExpired: boolean =
        (token.value.exp && DateTime.now() < DateTime.fromSeconds(token.value.exp)) ||
        !token.value.exp;
      const tokenFound = !!token.value.jwt;
      return tokenFound && notExpired;
    });

    const watchToken = () => {
      if (hasValidToken.value) {
        loadEntity();
        loadPusher();
      }
    };

    watch(hasValidToken, watchToken);
    watch(
      computed(() => token.value.jwt),
      () => {
        if (hasValidToken.value && !rootStore.pusherConnected) {
          loadPusher();
        }
      }
    );

    const showLobby = computed(
      () =>
        !!nextAuthStep.value ||
        !!consentSteps.value ||
        hasError.value ||
        documentRecalled.value ||
        documentExpired.value ||
        documentRejected.value ||
        documentUnpublished.value ||
        previewExpired.value ||
        gdprDeclined.value ||
        isProcessing.value
    );

    const pageTitle = computed(() => (document.value ? document.value.name : 'GetAccept'));
    const documentExpired = computed(() => DocumentHelper.getIsDocumentExpired(document.value));
    const hasFixedBackground = computed(
      () =>
        !theme.value ||
        theme.value.backgroundType === BackgroundType.Fixed ||
        !theme.value.backgroundImage
    );
    const documentRejected = computed(
      () => !!document.value && document.value.status === DocumentStatus.Rejected
    );

    const hasParams = computed(() => {
      if (isPreview.value && documentId.value) {
        return true;
      }
      return !!authId.value && !!documentId.value && (!!recipientId.value || !!userId.value);
    });

    const hasError = computed(() => sessionError.value || !hasParams.value || documentError.value);

    const safeBackground = computed(() =>
      BrandingHelper.getBackground(theme.value, BrowserHelper.isIE(navigator))
    );

    const unhandledrejectionHandler = (event: PromiseRejectionEvent) => {
      if (bugsnagClient) {
        bugsnagClient.notify(event as any);
      }
    };

    const watchRouteMeta = () => {
      const isUniversalRoute = UniversalLinkHelper.isUniversalLink(window.location.href);

      if (isUniversalRoute && router.currentRoute.value.meta.universal) {
        loading.value = false;
      }

      if (!isUniversalRoute) {
        loading.value = false;
      }
    };

    watch(() => router.currentRoute.value.meta, watchRouteMeta, { immediate: true });

    const created = () => {
      window.addEventListener('unhandledrejection', unhandledrejectionHandler);
    };

    created();

    onBeforeUnmount(() => {
      window.removeEventListener('unhandledrejection', unhandledrejectionHandler);
    });

    const setDocumentTitle = () => {
      window.document.title = pageTitle.value;
    };

    const loadEntity = async () => {
      await entityStore.load();
      if (!documentRecalled.value && !documentUnpublished.value) {
        documentAvailable.value = true;
      }
    };

    const loadLaunchDarkly = async () => {
      if (process.env.VUE_APP_LAUNCHDARKLY_KEY && entity.value.id) {
        initializeLD(
          process.env.VUE_APP_LAUNCHDARKLY_KEY,
          {
            kind: LaunchDarklyContextKind.Company,
            key: entity.value.id,
            [AnalyticsCompanyTrait.ID]: entity.value.id,
            [AnalyticsCompanyTrait.Name]: entity.value.name,
            [AnalyticsCompanyTrait.Plan]: entity.value.plan,
            [AnalyticsCompanyTrait.CountryCode]: entity.value.countryCode,
            [AnalyticsCompanyTrait.Language]: entity.value.language,
          },
          {
            bootstrap: 'localStorage',
            logger: basicLogger({ level: 'error' }),
          }
        );
      }
    };

    const pusherStore = usePusherStore();

    const loadPusher = async () => {
      if (isPreview.value) return;

      try {
        await pusherClient.connect(token.value.jwt);
        await pusherStore.subscribeToDocumentChannel();
        await pusherStore.subscribeToRecipientChannel();
        actionSetPusherConnected(true);
      } catch (e) {
        console.error(e);
        if (bugsnagClient) {
          bugsnagClient.notify(e);
        }
      }
    };

    const init = async () => {
      await loadEntity();
      await loadLaunchDarkly();
      Promise.all([loadFeatures(), loadPusher()]);
    };

    watch(
      hasValidToken,
      () => {
        if (hasValidToken.value) {
          init();
        }
      },
      { immediate: true }
    );

    const preventIosInputZoom = () => {
      if (navigator.userAgent.indexOf('iPhone') > -1) {
        window.document
          .querySelector('[name=viewport]')
          .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
      }
    };

    onMounted(async () => {
      preventIosInputZoom();
      if (hasParams.value) {
        themeStore.loadTheme(documentId.value);
      }
      if (!hasValidToken.value && hasParams.value) {
        sessionStore.loadToken([]);
      }
      setDocumentTitle();
    });

    return {
      hasValidToken,
      token,
      authId,
      documentId,
      recipientId,
      safeBackground,
      hasFixedBackground,
      theme,
      themeStore,
      showLobby,
      consentSteps,
      nextAuthStep,
      documentRecalled,
      hasError,
      isCorrectPin,
      documentExpired,
      documentRejected,
      previewExpired,
      gdprDeclined,
      document,
      entity,
      authentication,
      actionSubmitAnswer,
      actionSubmitQnaAnswer,
      actionSendSmsCode,
      actionSubmitConsent,
      actionDeclineConsent,
      actionSubmitSmsAnswer,
      documentAvailable,
      loading,
      isProcessing,
    };
  },
});
</script>

<style lang="scss" scoped>
.signing-page-app {
  background: repeat scroll center;
  background-size: contain;
  min-height: 100%;

  &.has-fixed-background {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }

  .circle {
    display: flex;
    background: white;
    border-radius: 100%;
    padding: var(--spacing-50);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .loader {
    width: auto;
    height: auto;
  }
}
</style>
<style lang="scss">
@import '@getaccept/lib-shared/src/chat/assets/variables';
@import '@getaccept/lib-shared/src/assets/scss/layout';

:root {
  --bottombar-height: 4rem;
  --topbar-height: 4rem;
  --topbar-padding: var(--topbar-height);
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}
</style>
