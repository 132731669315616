import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import type { SubscriptionFeature } from '@getaccept/feature-restriction-new';
import { apolloClient } from '../../../apollo';
import { FeaturesQueries } from '../graphql/features.queries';

export class FeaturesService {
  static async load(): Promise<SubscriptionFeature[]> {
    const {
      data: {
        entityFeatures: { features },
      },
    } = await apolloClient.query({
      query: FeaturesQueries.load,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return features;
  }
}
