import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { EidType } from '../types/enums/eid-type';
import type { Id } from '../types/eid';
import { EIdIconType } from '../types/enums/eid-icon-type';

export function getIdOptions(): Id[] {
  return [
    {
      name: 'BankID SE',
      key: EidType.SE,
      icon: EIdIconType.BankId,
    },
    {
      name: 'BankID Norge',
      key: EidType.NO,
      height: 220,
      width: 400,
      icon: EIdIconType.BankIdNo,
    },
    {
      name: 'MitID',
      key: EidType.MitID,
      icon: EIdIconType.MitIdDark,
    },
    {
      name: t('bankid-finland'),
      key: EidType.BankIDFI,
      icon: EIdIconType.NemId,
    },
  ];
}
