<template>
  <div class="participant-details">
    <div class="half-length-inputs">
      <FriedInput
        v-model.trim="updatedParticipant.firstName"
        :label="t('first_name_optional')"
        autocomplete="given-name"
        data-external="dr-participant-details-firstname-input"
        @keydown.enter="handleEnter"
        @update:model-value="$emit('update', updatedParticipant)"
      />
      <FriedInput
        v-model.trim="updatedParticipant.lastName"
        :label="t('last_name_optional')"
        autocomplete="family-name"
        data-external="dr-participant-details-lastname-input"
        @keydown.enter="handleEnter"
        @update:model-value="$emit('update', updatedParticipant)"
      />
    </div>
    <div class="half-length-inputs">
      <FriedInput
        v-model.trim="updatedParticipant.title"
        autocomplete="organization-title"
        data-external="dr-participant-details-title-input"
        :label="t('title_optional')"
        @keydown.enter="handleEnter"
        @update:model-value="$emit('update', updatedParticipant)"
      />
      <FriedInput
        v-model.trim="updatedParticipant.companyName"
        autocomplete="organization"
        data-external="dr-participant-details-company-input"
        :label="t('company_optional')"
        @keydown.enter="handleEnter"
        @update:model-value="$emit('update', updatedParticipant)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { useI18n } from 'vue-i18n';
import type { PropType } from 'vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    participant: {
      type: Object as PropType<{
        firstName: string;
        lastName: string;
        title?: string;
        companyName?: string;
      }>,
      required: true,
    },
  },
  emits: ['update', 'enter'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const updatedParticipant = ref(
      props.participant as {
        firstName?: string;
        lastName?: string;
        title?: string;
        companyName?: string;
      }
    );
    const handleEnter = () => {
      emit('enter', props.participant);
    };

    return { t, handleEnter, updatedParticipant };
  },
});
</script>
<style lang="scss" scoped>
.half-length-inputs {
  display: flex;
  column-gap: var(--spacing-75);

  & > * {
    width: 100%;
    margin-bottom: var(--spacing-50);
  }

  @media only screen and (max-width: $xs) {
    flex-direction: column;
  }
}
</style>
