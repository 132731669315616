<template>
  <div class="lobby-wrapper">
    <div class="content">
      <img v-if="logoUrl" class="logo" :src="logoUrl" :alt="t('logo')" />
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    logoUrl: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
.lobby-wrapper {
  display: flex;
  height: 100vh;
  background-color: var(--gray-98);

  .logo {
    max-height: 6rem;
    max-width: 12rem;
    object-fit: contain;
    object-position: center;
    padding-bottom: var(--spacing-200);
    margin-bottom: var(--spacing-200);
  }

  .content {
    margin: auto;
    background: var(--white);
    padding: var(--spacing-200);
    border: 1px solid var(--gray-92);
    border-radius: var(--radius-medium);
    min-height: 32rem;
    width: 30rem;
    max-width: 100%;

    @media screen and (max-width: $xs) {
      width: 100%;
      min-height: 100%;
    }
  }
}
</style>
