import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { ThemeService } from '../api/theme/services/theme.service';

export const useThemeStore = defineStore('theme', () => {
  const theme = ref<SigningTheme>(null);
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);

  const loadTheme = async (documentId: string) => {
    try {
      isError.value = false;
      isSuccess.value = false;
      isLoading.value = true;
      const response: SigningTheme = await ThemeService.load(documentId);
      theme.value = response;
      isSuccess.value = true;
    } catch (e) {
      isError.value = true;
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    theme,
    isLoading,
    isSuccess,
    isError,
    loadTheme,
  };
});
