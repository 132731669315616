<template>
  <FriedModal :closable="false">
    <FriedH3>{{ t('dr_accept_terms_and_conditions') }}</FriedH3>
    <Consent
      v-for="(consentStep, i) in consentSteps"
      :key="i"
      :consent-step="consentStep"
      :value="getValue(consentStep)"
      @change="handleConsentChange"
    />
    <template #footer>
      <FriedModalButtonsFooter>
        <FriedButton
          data-external="dr-consent-save-button"
          :disabled="!acceptedGDPR"
          @click="handleAnswer"
        >
          {{ t('dr_consents_save_continue') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModal>
</template>
<script lang="ts">
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeUnmount, onMounted, ref, defineComponent, computed } from 'vue';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import Consent from './Consent.vue';

export default defineComponent({
  components: {
    Consent,
  },
  props: {
    consentSteps: {
      type: Array as PropType<AuthenticationStep[]>,
      required: true,
    },
  },
  emits: ['answer'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const acceptGDPR = ref(false);
    const acceptTracking = ref(true);

    const acceptedGDPR = computed(() => {
      if (!gdprIsActive.value) {
        return true;
      }
      return acceptGDPR.value;
    });

    const gdprIsActive = computed(() =>
      props.consentSteps.some(step => step.type === AuthenticationStepType.GdprConsent)
    );

    const trackingConsentIsActive = computed(() =>
      props.consentSteps.some(step => step.type === AuthenticationStepType.TrackingConsent)
    );

    const authSteps = computed(() =>
      [
        gdprIsActive.value && {
          type: AuthenticationStepType.GdprConsent,
          value: acceptGDPR.value ? '1' : '0',
        },
        trackingConsentIsActive.value && {
          type: AuthenticationStepType.TrackingConsent,
          value: acceptTracking.value ? '1' : '0',
        },
      ].filter(Boolean)
    );

    const handleConsentChange = (value: boolean, type: AuthenticationStepType) => {
      if (type === AuthenticationStepType.TrackingConsent) {
        acceptTracking.value = value;
      } else {
        acceptGDPR.value = value;
      }
    };

    const handleAnswer = () => {
      if (acceptedGDPR.value) {
        emit('answer', authSteps.value);
      }
    };

    const getValue = (consentStep: AuthenticationStep) =>
      consentStep.type === AuthenticationStepType.TrackingConsent
        ? acceptTracking.value
        : acceptGDPR.value;

    const bindEnter = ({ key }: KeyboardEvent) => {
      if (key === 'Enter') {
        handleAnswer();
      }
    };

    onMounted(() => {
      document.addEventListener('keydown', bindEnter);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', bindEnter);
    });

    return {
      acceptGDPR,
      acceptedGDPR,
      acceptTracking,
      handleConsentChange,
      handleAnswer,
      getValue,
      bindEnter,
      t,
      trackingConsentIsActive,
      gdprIsActive,
    };
  },
});
</script>
