import type { IdentifyRecipientPayload } from '@getaccept/lib-shared-new/src/universal-link/types/identify-recipient-payload';
import type { VerifyLinkResponse } from '@getaccept/lib-shared-new/src/universal-link/types/verify-link-response';
import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import { apolloClient } from '../../../apollo';
import { UniversalLinkMutations } from '../graphql/universal-link.mutations';
import { UniversalLinkQueries } from '../graphql/universal-link.queries';

export class UniversalLinkService {
  static async verify(dealroomId: string, entityId: string): Promise<VerifyLinkResponse> {
    const { data } = await apolloClient.query({
      query: UniversalLinkQueries.verifySharedDealroom,
      variables: { dealroomId, entityId },
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return data;
  }

  static async identifyParticipant(
    gdprConsent: boolean,
    dealroomId: string,
    recipient: IdentifyRecipientPayload,
    entityId: string
  ): Promise<string> {
    const {
      data: {
        identifyDealroomParticipant: { roomLink },
      },
    } = await apolloClient.mutate({
      mutation: UniversalLinkMutations.identifyDealroomParticipant,
      variables: { gdprConsent, dealroomId, ...recipient, entityId },
    });
    const formattedUrl = new URL(roomLink);
    formattedUrl.searchParams.set('email', recipient.email);
    return formattedUrl.toString();
  }
}
