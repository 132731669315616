/* eslint-disable sonarjs/no-identical-functions */
import type { SubscriptionPlan } from '@getaccept/lib-shared-new/src/enums/subscription-plan';
import type { Addon } from '@getaccept/lib-shared-new/src/types/addon';
import { featureRuleMapLegacy } from '../constants/legacy-rules.constants';
import { upsellRuleMap } from '../constants/upsell-rules.constants';
import { Feature } from '../types/feature';
import type { FeatureStatusMap } from '../types/feature-status';
import { FeatureType } from '../types/feature-types';
import type { SubscriptionFeature } from '../types/subscription-feature';

export class FeatureRestrictionHelper {
  static getFeatureStatusMap(plan: SubscriptionPlan, features: SubscriptionFeature[]) {
    return Object.values(Feature).reduce((featureStatusMap, featureName) => {
      const feature = features.find(({ id }) => id === featureName);
      const newPlanUpsell = upsellRuleMap[featureName]?.includes(plan);
      const hasThreshold =
        feature?.type === FeatureType.Quantity && !feature?.quantitySettings?.unlimited;

      featureStatusMap[featureName] = {
        restricted: !feature,
      };

      if (!!newPlanUpsell && !feature) {
        featureStatusMap[featureName].upsell = true;
      }

      if (hasThreshold) {
        featureStatusMap[featureName].threshold = feature.quantitySettings?.maxQuantity;
      }

      return featureStatusMap;
    }, {} as FeatureStatusMap);
  }

  static getLegacyFeatureStatusMap(plan: SubscriptionPlan, addons: Addon[] = []) {
    return Object.values(Feature).reduce((featureStatusMap, featureName) => {
      const addon = addons.find(({ addon }) => featureRuleMapLegacy[featureName].includes(addon));
      const legacyPlanAllowed = featureRuleMapLegacy[featureName].includes(plan) || !!addon;
      const legacyPlanUpsell = upsellRuleMap[featureName]?.includes(plan);

      featureStatusMap[featureName] = {
        restricted: !legacyPlanAllowed,
      };

      if (!!legacyPlanUpsell && !legacyPlanAllowed) {
        featureStatusMap[featureName].upsell = true;
      }

      if (addon?.limit) {
        featureStatusMap[featureName].threshold = addon.limit;
      }

      return featureStatusMap;
    }, {} as FeatureStatusMap);
  }

  static getDefaultFeatureStatusMap() {
    return Object.values(Feature).reduce(
      (featureStatusMap, featureName) => ({
        ...featureStatusMap,
        [featureName]: {
          restricted: true,
          upsell: false,
          threshold: 0,
        },
      }),
      {} as FeatureStatusMap
    );
  }
}
