import { computed } from 'vue';
import { useBrowserLocation } from '@vueuse/core';

export function useEnvironment() {
  const location = useBrowserLocation();
  const origin = computed(() => location.value.origin);
  const isLocalHost = computed(() => origin.value.includes('localhost'));

  const isStaging = computed(
    () =>
      process.env.NODE_ENV === 'development' ||
      origin.value.includes('ga-staging') ||
      isLocalHost.value
  );

  const isSandbox = computed(() => origin.value.includes('sandbox'));

  const domain = computed(() => {
    if (isLocalHost.value) return origin.value;

    const firstDotIndex = origin.value.indexOf('.');
    return origin.value.substring(firstDotIndex + 1);
  });

  const getUrl = ({ prefix, pathName }: { prefix?: string; pathName?: string }) => {
    if (isLocalHost.value) {
      return pathName ? `${domain.value}/${pathName}` : domain.value;
    }
    const parsedPrefix = prefix ? `${prefix}.` : '';
    const url = `${location.value.protocol}//${parsedPrefix}${domain.value}`;
    return pathName ? `${url}/${pathName}` : url;
  };

  return {
    isStaging,
    isLocalHost,
    isSandbox,
    isProduction: computed(() => !isStaging.value),
    origin,
    domain,
    getUrl,
  };
}
