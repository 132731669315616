<template>
  <FriedModalSubContainer :loading="false">
    <template #header>
      <FriedModalHeader :header="$t('authenticate-to-access-document')" />
    </template>
    <div class="description">
      {{ $t('sender-wants-to-identify-yourself_v2', { documentName: documentName }) }}
    </div>
    <FriedInput
      v-model.trim="email"
      :type="InputType.Text"
      class="identify-input"
      :label="$t('email')"
      data-external="lobby-identify-recipient-input-email"
      :error-message="invalidEmailMessage"
      @keyup.enter="handleClick"
    />
    <template #footer>
      <FriedModalButtonsFooter>
        <FriedButton
          :disabled="!email.length"
          :loading="submittingResponse"
          data-external="lobby-identify-recipient-view-document-button"
          @click="handleClick"
        >
          {{ $t('view-document') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModalSubContainer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  useIdentifyRecipient,
  identifyRecipientProps,
} from '@getaccept/lib-shared-new/src/lobby/composables/identify-recipient.composable';
import { InputType } from '@getaccept/fried-tofu';

export default defineComponent({
  name: 'IdentifyRecipient',
  props: {
    ...identifyRecipientProps,
  },
  setup(props, { emit }) {
    const { documentName, email, handleClick, invalidEmailMessage } = useIdentifyRecipient(
      props,
      emit
    );
    return { documentName, email, handleClick, invalidEmailMessage, InputType };
  },
});
</script>
<style lang="scss" scoped>
$label-height: var(--spacing-100);
$input-height: var(--paragraph-medium-font-size);
$border: 0.05rem solid var(--gray-96);

.identify-input {
  width: 100%;
}

.description {
  min-height: 1.2rem;
  margin: var(--spacing-50) 0;
}
</style>
