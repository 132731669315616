import Axios from 'axios';
import type { Token } from '@getaccept/lib-shared-new/src/session/type/token';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { apolloClient } from '../../../apollo';
import { AuthQuery } from '../graphql/session.queries';
import { SessionMutations } from '../graphql/session.mutations';

export class SessionService {
  static async getToken(
    dealroomId: string,
    participantId: string,
    authId: string,
    entityId: string,
    authStepsInput?: AuthenticationStepInput[]
  ): Promise<Token> {
    const {
      data: {
        dealroomAuth: { jwt, exp, authSteps, status, metaData },
      },
    } = await apolloClient.query({
      query: AuthQuery,
      variables: {
        dealroomId,
        participantId,
        authId,
        entityId,
        authSteps: authStepsInput,
      },
    });
    return { jwt, exp, authSteps, status, metaData };
  }

  static async verifyConsent(consentPayload: {
    dealroomId: string;
    entityId: string;
    participantId: string;
  }): Promise<void> {
    await apolloClient.mutate({
      mutation: SessionMutations.verifyConsent,
      variables: { payload: { ...consentPayload, consent: true } },
    });
  }

  static async postVerifyTrackingConsentLegacy(
    dealroomId: string,
    participantId: string,
    authId: string
  ): Promise<boolean> {
    const { data } = await Axios.post(
      `/view/${dealroomId}/${participantId}/${authId}/verify-tracking-consent`
    );
    return data.status === 1;
  }

  static async postRejectTrackingConsentLegacy(
    dealroomId: string,
    participantId: string,
    authId: string
  ): Promise<boolean> {
    const { data } = await Axios.post(
      `/view/${dealroomId}/${participantId}/${authId}/reject-tracking-consent`
    );
    return data.status === 1;
  }
}
