import gql from 'graphql-tag';
import { fieldFragment } from './field.fragment';

export const fieldsQuery = gql`
  query DocumentFields {
    document {
      id
      fields {
        ...fieldFragment
      }
    }
  }
  ${fieldFragment}
`;
