import { FontFragments } from '@getaccept/lib-shared-new/src/fonts/font.fragment';
import gql from 'graphql-tag';

export class ThemeQueries {
  static theme = gql`
    query Theme($documentId: String!) {
      theme(documentId: $documentId) {
        name
        backgroundColor
        backgroundImage
        backgroundType
        textColor
        accentColor
        fontSize
        fontFamily
        customCss
        logoUrl
        signButtonText
        fontSettings {
          defaultFont {
            ...fontFragment
          }
          headingsFont {
            ...fontFragment
          }
        }
      }
    }
    ${FontFragments.fontFragment}
  `;
}
