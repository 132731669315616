<template>
  <div class="info-bubble">
    <FriedParagraph class="text">
      {{ text || t('upsell_upgrade_today_to_get_access') }}
    </FriedParagraph>
    <FriedButton
      data-external="upgrade-info-contact-us-button"
      :color="RainbowColor.Lilac"
      @click="$emit('contact')"
    >
      {{ t('contact-us') }}
    </FriedButton>
    <template v-if="link">
      <FriedLink data-external="upgrade-info-link" :href="link.url" target="_blank">
        <FriedParagraph class="link-text">
          {{ link.text }}
        </FriedParagraph>
      </FriedLink>
    </template>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { RainbowColor } from '@getaccept/fried-tofu';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    link: {
      type: Object as PropType<{ text: string; url: string }>,
      default: null,
    },
  },
  emits: ['contact'],
  setup() {
    const { t } = useI18n();

    return {
      RainbowColor,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-bubble {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  padding: var(--spacing-50);
  width: 273px;

  .text {
    white-space: pre-line;
  }

  .link-text {
    color: var(--link-blue);
    white-space: nowrap;
  }

  @media screen and (max-width: $xs) {
    width: 100%;
  }
}
</style>
