<template>
  <div class="request-access">
    <FriedH3 class="header">{{ t('dr_this_is_a_private_room_header') }}</FriedH3>
    <FriedParagraph>{{ t('dr_only_the_room_owner_can_invite_you') }}</FriedParagraph>
    <br />
    <FriedParagraph>{{ t('dr_if_you_have_been_invited_using_wrong_email') }}</FriedParagraph>
    <FriedButton
      :button-type="ButtonType.Secondary"
      data-external="dr-enter-room-try-again-button"
      class="try-again-button"
      @click="$emit('try-again')"
    >
      {{ t('dr_try_again') }}
    </FriedButton>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { ButtonType } from '@getaccept/fried-tofu';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  emits: ['try-again'],
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      const urlEmail = new URL(window.location.href).searchParams.get('email');
      if (urlEmail) {
        const url = new URL(window.location.href);
        url.searchParams.delete('email');
        window.history.replaceState({}, '', url.toString());
      }
    });

    return { t, ButtonType };
  },
});
</script>
<style lang="scss" scoped>
.header {
  margin-bottom: var(--spacing-50);
}

.try-again-button {
  margin-top: var(--spacing-150);
  width: 100%;
}
</style>
