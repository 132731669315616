import type { Ref, ExtractPropTypes, SetupContext, PropType } from 'vue';
import { ref, computed } from 'vue';
import { sanitizeEmail } from '@getaccept/lib-shared-new/src/helpers';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import { AuthenticationErrorType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-error-type';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';

export const identifyRecipientProps = {
  authStep: {
    type: Object as PropType<AuthenticationStep>,
    required: true,
  },
  submittingResponse: {
    type: Boolean,
  },
};

export function useIdentifyRecipient(
  props: ExtractPropTypes<typeof identifyRecipientProps>,
  emit: SetupContext['emit']
) {
  const email: Ref<string> = ref('');

  const hasError = computed(() => props.authStep.data?.error);
  const notMatchingEmail = computed(
    () =>
      props.authStep.data?.errorType ===
      AuthenticationErrorType.DealroomRecipientEmailNotMatchingLink
  );
  const universalLinkUrl = computed(() => props.authStep.data?.universalLinkUrls?.[0]);

  const invalidEmailMessage = computed(() =>
    hasError.value && !notMatchingEmail.value ? t('enter_a_valid_email') : ''
  );

  const documentName = computed(() => props.authStep.data?.textData?.document?.name);
  const handleClick = () => {
    emit('answer', { type: props.authStep.type, value: sanitizeEmail(email.value) });
  };
  return {
    email,
    handleClick,
    documentName,
    invalidEmailMessage,
    universalLinkUrl,
    notMatchingEmail,
  };
}
