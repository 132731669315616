<template>
  <div class="report-email-container">
    <FriedGetAcceptLogo class="logo" data-external="global-nav-logo-link" />
    <FriedModal :closable="false">
      <template #header>
        <FriedModalHeader :header="$t('report-spam')" />
      </template>
      <FriedParagraph v-if="reported">{{ $t('report-spam-description-done') }}</FriedParagraph>
      <template v-else>
        <FriedParagraph>{{
          $t('if_you_decide_to_opt_out_you_will_no_longer_receive_these_emails')
        }}</FriedParagraph>
        <ul>
          <li>{{ $t('links_to_documents_sent_to_you_for_review_approval_or_signature') }}</li>
          <li>{{ $t('invitations_to_rooms_for_you_to_review') }}</li>
          <li>{{ $t('notifications_about_comments_in_documents_or_rooms') }}</li>
          <li>{{ $t('notifications_about_chat_messages_in_documents_or_rooms') }}</li>
        </ul>
        <FriedParagraph>
          {{
            $t(
              'we_are_always_working_to_improve_your_getaccept_experience_we_would_love_your_feedback_about_why_you_are_opting_out'
            )
          }}
        </FriedParagraph>
        <div class="reason-container">
          <FriedRadioButton
            :model-value="reason"
            :value="UnsubscribeReason.NotRelevant"
            :label="$t('i-dont-think-these-emails-are-relevant')"
            @update:model-value="reason = $event"
          />
          <FriedRadioButton
            :model-value="reason"
            :value="UnsubscribeReason.TooManyEmails"
            :label="$t('i-resive-too-many-emails-from-getaccept')"
            @update:model-value="reason = $event"
          />
          <FriedRadioButton
            :model-value="reason"
            :value="UnsubscribeReason.Spam"
            :label="$t('the-emails-are-spam-and-should-be-reported')"
            @update:model-value="reason = $event"
          />
          <FriedRadioButton
            :model-value="reason"
            :value="UnsubscribeReason.Other"
            :label="$t('other-reason')"
            @update:model-value="reason = $event"
          />
          <FriedTextarea
            class="other-input"
            :model-value="feedback"
            fixed-label
            :label="$t('please-add-reason')"
            data-external="report-email-feedback"
            @update:model-value="debounceFeedback"
          />
        </div>
        <FriedParagraph>{{
          $t(
            'please_note_that_this_opt-out_is_only_about_notifications_from_the_getaccept_platform_it_is_not_unsubscribing_you_from_marketing_emails'
          )
        }}</FriedParagraph>
      </template>
      <template #footer>
        <FriedModalButtonsFooter>
          <FriedButton
            v-if="!reported"
            data-external="report-email-submit"
            :loading="loading"
            @click="handleReportSpam"
            >{{ $t('report') }}</FriedButton
          >
        </FriedModalButtonsFooter>
      </template>
    </FriedModal>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { useReportEmail } from '../composables/report-email.composable';
import { UnsubscribeReason } from '../enums/unsubscribe-reason';

export default defineComponent({
  props: {
    isDsr: {
      type: Boolean,
    },
  },
  setup(props) {
    const reason = ref(null);
    const feedback = ref('');

    const handleReportSpam = () => {
      if (props.isDsr) {
        reportDsr(reasonObj.value);
      } else {
        report(reasonObj.value);
      }
    };

    const reasonObj = computed(() =>
      reason.value ? { type: reason.value, text: feedback.value } : undefined
    );

    const debounceFeedback = useDebounceFn((value: string) => {
      feedback.value = value;
    }, 500);

    const showFeedback = computed(() => reason.value === UnsubscribeReason.Other);

    const { loading, reported, report, reportDsr } = useReportEmail();

    return {
      handleReportSpam,
      loading,
      reported,
      reason,
      UnsubscribeReason,
      showFeedback,
      feedback,
      debounceFeedback,
    };
  },
});
</script>
<style lang="scss" scoped>
.report-email-container {
  padding: $spacing-inner;

  .logo {
    height: 2rem;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .reason-container {
    margin: $spacing-inner 0;

    .other-input {
      width: 100%;
    }
  }
}
</style>
