import { AuthenticationHelper } from '@getaccept/lib-shared-new/src/authentication/helpers/authentication.helper';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import type { Token } from '@getaccept/lib-shared-new/src/session/type/token';
import { useToast } from '@getaccept/lib-shared-new/src/toast';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { useSessionStorage, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { LocalStorageSubKey } from '../../local-storage/type/subkey';
import { useRootStore } from '../../store/root.store';
import { SessionService } from '../../api/session/services/session.service';
import { HttpLockedError, HttpThrottleError } from '../../common/errors';
import { LocalStorageHelper } from '../../local-storage/helpers/local-storage.helper';

export const useSessionStore = defineStore('session', () => {
  const recipientFirstOpen = ref(false);

  const qnaError = ref(false);

  const isPersonalURL = LocalStorageHelper.getRecipientIdFromUrl(window.location.href);
  const isPreview = LocalStorageHelper.getDocumentIdFromPreviewUrl(window.location.href);
  const emptyToken = {
    jwt: '',
    exp: null,
  };

  const getTokenRef = () => {
    if (isPersonalURL) {
      return useStorage(
        LocalStorageHelper.buildKey(window.location.href, LocalStorageSubKey.Token),
        emptyToken
      );
    }
    if (isPreview) {
      return useSessionStorage(
        LocalStorageHelper.buildPreviewKey(window.location.href, LocalStorageSubKey.Token),
        emptyToken
      );
    }
    return ref(emptyToken);
  };

  const token: Ref<{ jwt: string; exp: number }> = getTokenRef();

  const setToken = (payload: Token) => {
    token.value.jwt = payload.jwt;
    token.value.exp = payload.exp;
    recipientFirstOpen.value = payload.metaData ? payload.metaData.isFirstVisit : true;
  };

  const authentication: Ref<{
    submittingResponse: boolean;
    steps: AuthenticationStep[];
    answers: AuthenticationStepInput[];
  }> = ref({
    submittingResponse: false,
    steps: [],
    answers: [],
  });
  const setAuthenticationAnswers = (
    steps: AuthenticationStep[],
    answers: AuthenticationStepInput[]
  ) => {
    const stepsWithError: AuthenticationStep[] = steps.filter(
      (step: AuthenticationStep) => step.data.error
    );
    const correctAnswers: AuthenticationStepInput[] = answers.filter(
      (answer: AuthenticationStepInput) =>
        !stepsWithError.some((step: AuthenticationStep) => step.type === answer.type)
    );
    authentication.value.answers = correctAnswers;

    qnaError.value = stepsWithError.some(
      (step: AuthenticationStep) => step.type === AuthenticationStepType.VerifyQna
    );
  };

  const status: Ref<DocumentStatus> = ref(null);
  const setStatus = (payload: DocumentStatus) => {
    status.value = payload;
  };

  const error = ref(false);
  const setError = (payload: boolean) => {
    error.value = payload;
  };

  const loadTokenSuccess = (payload: Partial<Token>, authStepsInput: AuthenticationStepInput[]) => {
    if (payload.authSteps?.length) {
      authentication.value.steps = payload.authSteps;
      if (authStepsInput) {
        setAuthenticationAnswers(payload.authSteps, authStepsInput);
      }
    } else if (payload?.metaData?.redirectUrl) {
      const pathRegExp = /\/([a-z,\d]+)\/([a-z,\d]+)\/a\/([a-z,\d]+)/g;
      const redirectUrl: string = window.location.href.replace(
        pathRegExp,
        payload.metaData.redirectUrl
      );
      const formattedUrl = new URL(redirectUrl);
      formattedUrl.searchParams.append('token', payload.jwt);
      window.location.assign(String(formattedUrl));
    } else if (payload.jwt) {
      setToken({ jwt: payload.jwt, exp: payload.exp, metaData: null, authSteps: [], status: null });
      status.value = payload.status;
      authentication.value.steps = [];
    } else if (payload.status) {
      status.value = payload.status;
      authentication.value.steps = [];
    } else {
      setError(true);
    }
  };

  const loadToken = async (authStepsInput: AuthenticationStepInput[]) => {
    const rootStore = useRootStore();
    try {
      const token: Token = await SessionService.getToken(
        rootStore.documentId,
        rootStore.recipientId,
        rootStore.authId,
        authStepsInput
      );
      loadTokenSuccess(token, authStepsInput);
    } catch (e) {
      error.value = true;
    } finally {
      authentication.value.submittingResponse = false;
    }
  };

  const sendPinRetryCount = ref(0);
  const sendSmsCode = async () => {
    const rootStore = useRootStore();
    try {
      await SessionService.postSendSmsLegacy(
        rootStore.documentId,
        rootStore.recipientId,
        rootStore.authId,
        sendPinRetryCount.value
      );
      sendPinRetryCount.value++;
    } catch (e) {
      if (e instanceof HttpThrottleError) {
        useToast().toast.danger(t('could-not-send-sms-code-throttle'));
      } else if (e instanceof HttpLockedError) {
        useToast().toast.danger(t('could-not-send-sms-code-locked'));
      } else {
        useToast().toast.danger(t('could-not-send-sms-code'));
      }
    }
  };

  const submitAnswer = (input: AuthenticationStepInput[]) => {
    try {
      const existingAuthStepsInput: AuthenticationStepInput[] = [...authentication.value.answers];
      input.forEach((authStepInput: AuthenticationStepInput) => {
        const alreadyHasAnswer: boolean = authentication.value.answers.some(
          (answer: AuthenticationStepInput) => answer.type === authStepInput.type
        );

        if (alreadyHasAnswer) {
          const index: number = authentication.value.answers.findIndex(
            (answer: AuthenticationStepInput) => answer.type === authStepInput.type
          );
          existingAuthStepsInput.splice(index, 1, authStepInput);
        } else {
          existingAuthStepsInput.push(authStepInput);
        }
      });
      loadToken(existingAuthStepsInput);
    } catch (e) {
      authentication.value.submittingResponse = false;
      console.error(e);
    }
  };

  const isCorrectPin = ref(null);
  const submitAnswerFailed = () => {
    authentication.value.submittingResponse = false;
    isCorrectPin.value = false;
  };

  const submitSmsAnswer = async (input: AuthenticationStepInput) => {
    authentication.value.submittingResponse = true;
    const rootStore = useRootStore();
    try {
      const verified: boolean = await SessionService.postVerifySmsLegacy(
        input,
        rootStore.documentId,
        rootStore.recipientId,
        rootStore.authId
      );
      if (verified) {
        submitAnswer([input]);
      } else {
        submitAnswerFailed();
      }
    } catch (e) {
      submitAnswerFailed();
    }
  };

  const submitQnaAnswer = async (authStep: AuthenticationStepInput) => {
    const rootStore = useRootStore();
    authentication.value.submittingResponse = true;
    try {
      await SessionService.postQAAnswerLegacy(
        authStep,
        rootStore.documentId,
        rootStore.recipientId,
        rootStore.authId
      );
      submitAnswer([authStep]);
    } catch (e) {
      authentication.value.submittingResponse = false;
      useToast().toast.danger(t('could-not-save-qna-answer'));
    }
  };

  const handleGdprConsent = async (authStep: AuthenticationStepInput) => {
    const rootStore = useRootStore();
    authentication.value.submittingResponse = true;
    try {
      if (authStep.value === '1') {
        await SessionService.postVerifyConsentLegacy(
          rootStore.documentId,
          rootStore.recipientId,
          rootStore.authId
        );
      } else {
        await SessionService.postRejectConsentLegacy(
          rootStore.documentId,
          rootStore.recipientId,
          rootStore.authId
        );
      }
    } catch (error) {
      useToast().toast.danger(t('could-not-save-consent'));
    } finally {
      authentication.value.submittingResponse = false;
    }
  };

  const handleTrackingConsent = async (input: AuthenticationStepInput) => {
    authentication.value.submittingResponse = true;
    const rootStore = useRootStore();
    try {
      if (input.value === '1') {
        await SessionService.postVerifyTrackingConsentLegacy(
          rootStore.documentId,
          rootStore.recipientId,
          rootStore.authId
        );
      } else {
        await SessionService.postRejectTrackingConsentLegacy(
          rootStore.documentId,
          rootStore.recipientId,
          rootStore.authId
        );
      }
    } catch (error) {
      useToast().toast.danger(t('could-not-save-consent'));
    } finally {
      authentication.value.submittingResponse = false;
    }
  };

  const submitConsent = async (input: AuthenticationStepInput[]) => {
    for (let i = 0; i < input.length; i++) {
      switch (input[i].type) {
        case AuthenticationStepType.GdprConsent:
          await handleGdprConsent(input[i]);
          break;
        case AuthenticationStepType.TrackingConsent:
          await handleTrackingConsent(input[i]);
          break;
      }
    }
    submitAnswer(input);
  };

  const declineConsent = () => {
    const declinedGDPRConsent = AuthenticationHelper.declinedConsent(
      authentication.value.steps,
      AuthenticationStepType.GdprConsent
    );
    const declinedTrackingConsent = AuthenticationHelper.declinedConsent(
      authentication.value.steps,
      AuthenticationStepType.TrackingConsent
    );
    const authStepsInput: AuthenticationStepInput[] = [];
    if (declinedGDPRConsent) {
      authStepsInput.push({ value: '0', type: AuthenticationStepType.GdprConsent });
    }
    if (declinedTrackingConsent) {
      authStepsInput.push({ value: '0', type: AuthenticationStepType.TrackingConsent });
    }
    submitConsent(authStepsInput);
  };

  const setPreviewToken = (jwt: string) => {
    token.value.jwt = jwt;
  };

  const setRecipientFirstOpen = (payload: boolean) => {
    recipientFirstOpen.value = payload;
  };

  const nextAuthStep: ComputedRef<AuthenticationStep> = computed(() => {
    if (!authentication.value?.steps?.length) {
      return null;
    }
    const sortedSteps: AuthenticationStep[] = [...authentication.value.steps].sort(
      (step: AuthenticationStep) =>
        step.type === AuthenticationStepType.IdentifyRecipient ? -1 : 1
    );
    return sortedSteps.find((step: AuthenticationStep) => {
      if (
        step.data.error !== false &&
        step.type !== AuthenticationStepType.TrackingConsent &&
        step.type !== AuthenticationStepType.GdprConsent
      ) {
        return !authentication.value.answers.some(
          (input: AuthenticationStepInput) => input.type === step.type
        );
      }
    });
  });

  const consentSteps: ComputedRef<AuthenticationStep[]> = computed(() => {
    if (!authentication.value?.steps?.length) {
      return null;
    }
    return authentication.value.steps.filter(
      (step: AuthenticationStep) =>
        (step.type === AuthenticationStepType.TrackingConsent && step.data.error !== false) ||
        (step.type === AuthenticationStepType.GdprConsent && step.data.error !== false)
    );
  });

  const documentRecalled: ComputedRef<boolean> = computed(
    () => status.value === DocumentStatus.Recalled
  );

  const documentUnpublished: ComputedRef<boolean> = computed(
    () => status.value === DocumentStatus.Unpublished
  );

  const gdprDeclined: ComputedRef<boolean> = computed(() => {
    if (!authentication.value?.steps?.length) {
      return false;
    }
    return authentication.value.steps.some(
      (step: AuthenticationStep) =>
        step.type === AuthenticationStepType.GdprConsent && !!step.data.error
    );
  });

  return {
    token,
    recipientFirstOpen,
    isCorrectPin,
    error,
    authentication,
    status,

    nextAuthStep,
    consentSteps,
    documentRecalled,
    documentUnpublished,
    gdprDeclined,

    setStatus,
    setError,
    submitConsent,
    declineConsent,

    loadToken,
    loadTokenSuccess,

    setPreviewToken,
    setRecipientFirstOpen,
    sendSmsCode,

    submitAnswer,
    submitSmsAnswer,
    submitQnaAnswer,

    qnaError,
  };
});
