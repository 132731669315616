export enum AnalyticsUserTrait {
  ID = 'id',
  SignupMethod = 'signupMethod',
  SignupVersion = 'signUpVersion',
  SignupStep = 'signUpStep',
  EmailScore = 'emailScore',
  Email = 'email',
  Country = 'country',
  DataCenter = 'dataCenter',
  Browser = 'browserAgent',
  FirstName = 'firstName',
  LastName = 'lastName',
  Language = 'language',
  Mobile = 'mobile',
  UserHash = 'user_hash',
  Title = 'title',
  Plan = 'plan',
  Avatar = 'avatar',
  Role = 'role',
  Currency = 'currency',
  RegistrationSource = 'registration_source',
  RegistrationCampaign = 'registration_campaign',
  RegistrationDevice = 'registration_device',
  AchievementsLevel = 'achievements_level',
  AppMode = 'app_mode',
  CreatedAt = 'createdAt',
  CompanyId = 'company_id',
  CompanyName = 'company_name',
  CompanyPlan = 'company_plan',
  CompanyCreatedAt = 'company_createdAt',
  OnboardingStep = 'onboardingStep',
  OnboardingChoice = 'onboardingChoice',
  TeamCount = 'teamCount',
  //Planhat specific field to track usage within a sub-entity
  ParentEntityId = 'parentEntityId',
  AssetExtId = 'assetExtId',
  IsSubEntity = 'isSubEntity',
}

export enum AnalyticsCompanyTrait {
  ID = 'id',
  Name = 'name',
  Plan = 'plan',
  CreatedAt = 'createdAt',
  CountryCode = 'countryCode',
  Language = 'language',
  //Planhat specific field to track usage within a sub-entity
  ParentEntityId = 'parentEntityId',
  AssetExtId = 'assetExtId',
  IsSubEntity = 'isSubEntity',
  ContextualCommenting = 'contextualCommenting',
}

export enum AnalyticsUTMParameter {
  UTMSource = 'utm_source',
  UTMMedium = 'utm_medium',
  UTMContent = 'utm_content',
  UTMCampaign = 'utm_campaign',
  UTMTerm = 'utm_term',
  Ref = 'ref',
  Hsfirstvisit = 'hsfirstvisit',
}

export enum AnalyticsOnboardingChoice {
  Template = 'template',
  Upload = 'upload',
  Chat = 'chat',
  Dismiss = 'dismiss',
}

export enum AnalyticsEvent {
  SignupLoaded = 'Signup Loaded',
  SignupInitiated = 'Signup Initiated',
  SignupFailed = 'Signup Failed',
  SignupBadEmail = 'Signup Bad Email',
  SignupLoadedStep2 = 'Signup Step 2 Loaded',
  SignupLoadedStep3 = 'Signup Step 3 Loaded',
  SignupCompleted = 'Signup Completed',
  OnboardingLoaded = 'Onboarding Loaded',
  OnboardingStep1 = 'Onboarding Step 1',
  SealedWithEditorPricingTable = 'Sealed With Editor PricingTable',
  SealedWithEditorForm = 'Sealed With Editor Form',
  SealedWithEditorText = 'Sealed With Editor Text',
  SealedWithEditorTable = 'Sealed With Editor Table',
  SealedWithEditorImage = 'Sealed With Editor Image',
  SealedWithEditorSignature = 'Sealed With Editor Signature',
  SentSMS = 'sent-sms',
  AddedAttachment = 'added-attachment',
  SendManually = 'send-manually',
  AddedVideo = 'added-video',
  ChangeEmail = 'change-email',
  SaveDataProtection = 'save-data-protection',
  CreatedEmailTemplate = 'created-email_template',
  CreatedTag = 'created-tag',
  CreatedTeam = 'created-team',
  CreatedTheme = 'created-theme',
  CreatedUser = 'created-user',
  DownloadAllDocuments = 'download-all-documents',
  UploadedLogo = 'uploaded-logo',
  UploadedThumb = 'uploaded-thumb',
  CreatedTemplate = 'created-template',
  CreatedDocument = 'created-document',
  CreatedProduct = 'Created Product',
  DeletedProduct = 'Deleted Product',

  MissingSigner = 'validation-missing-signer',
  NonSignableDocumentWithSigners = 'validation-non-signable-document-with-signers',
  MissingRecipients = 'validation-missing-recipients',
  NoDocumentName = 'validation-no-document-name',
  RequiredFields = 'validation-unconnected-required-fields',
  RegexFieldsValidation = 'regex-fields-validation',
  DocumentLacksPages = 'validation-lacks-pages',
  MissingRecipientForRestrictedAccess = 'validation-missing-recipient-restricted-access',
  RecipientWithSmsAuthButNoMobileNumber = 'validation-recipients-are-missing-phone-number',
  RecipientSignatureFieldHasInvalidRecipientId = 'validation-signature-fields-has-invalid-recipient-id',
  RecipientCollectableFieldHasInvalidRecipientId = 'validation-collectable-fields-has-invalid-recipient-id',
  IsImporting = 'validation-document-is-importing',
  IsSent = 'validation-document-is-already-sent',
  NoSignerButHaveApprovers = 'validation-no-signer-but-have-approvers',
  HaveExpired = 'validation-expiration-date-has-expired',
  UnconnectedRoles = 'validation-unconnected-roles',
  MissingEditorLabels = 'validation-missing-editor-labels',
  FieldsHasEmptyValue = 'validation-field-has-empty-value',
  EditorDropdownHasNoOptions = 'validation-editor-dropdown-has-no-options',
  EditorFormInputFieldsValidationFailed = 'validation-editor-form-input-fields-validation-failed',
  NumberOfDocumentValidationErrors = 'validation-number-of-document-validation-errors',

  SignAndSend = 'send-out-sign-and-send',
  SignAndShare = 'send-out-sign-and-share',
  Send = 'send-out-send',
  Share = 'send-out-share',

  EditorAICommand = 'editor-ai-command',
}

export enum AnalyticsIntegrationEvent {
  CreatedIntegrationAdroll = 'created-integration-adroll',
  CreatedIntegrationAdwords = 'created-integration-adwords',
  CreatedIntegrationApsislead = 'created-integration-apsislead',
  CreatedIntegrationAuthorizenet = 'created-integration-authorizenet',
  CreatedIntegrationBluesnap = 'created-integration-bluesnap',
  CreatedIntegrationCloseio = 'created-integration-closeio',
  CreatedIntegrationConversion = 'created-integration-conversion',
  CreatedIntegrationDocs = 'created-integration-docs',
  CreatedIntegrationDrive = 'created-integration-drive',
  CreatedIntegrationEmail = 'created-integration-email',
  CreatedIntegrationFacebook = 'created-integration-facebook',
  CreatedIntegrationFreshsales = 'created-integration-freshsales',
  CreatedIntegrationG2Crowd = 'created-integration-g2crowd',
  CreatedIntegrationGa = 'created-integration-ga',
  CreatedIntegrationGatv = 'created-integration-gatv',
  CreatedIntegrationSalesloft = 'created-integration-salesloft',
  CreatedIntegrationHubspot = 'created-integration-hubspot',
  CreatedIntegrationHubspotsales = 'created-integration-hubspotsales',
  CreatedIntegrationLimecrm = 'created-integration-limecrm',
  CreatedIntegrationLinkedinsales = 'created-integration-linkedinsales',
  CreatedIntegrationMarketo = 'created-integration-marketo',
  CreatedIntegrationPardot = 'created-integration-pardot',
  CreatedIntegrationPerfectaudience = 'created-integration-perfectaudience',
  CreatedIntegrationPhone = 'created-integration-phone',
  CreatedIntegrationPipedrive = 'created-integration-pipedrive',
  CreatedIntegrationReco = 'created-integration-reco',
  CreatedIntegrationRecurly = 'created-integration-recurly',
  CreatedIntegrationSaml = 'created-integration-saml',
  CreatedIntegrationScim = 'created-integration-scim',
  CreatedIntegrationSlack = 'created-integration-slack',
  CreatedIntegrationStripe = 'created-integration-stripe',
  CreatedIntegrationSympa = 'created-integration-sympa',
  CreatedIntegrationTwitter = 'created-integration-twitter',
  CreatedIntegrationUpsales = 'created-integration-upsales',
  CreatedIntegrationZapier = 'created-integration-zapier',
}
