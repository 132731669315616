<template>
  <div class="lobby-container">
    <DsrUnavailable v-if="showDsrUnavailable" :logo-url="documentLogoUrl" />
    <IdentifyParticipant
      v-else
      :auth-step="nextAuthStep"
      :logo-url="documentLogoUrl"
      :submitting-response="submittingResponse"
      :consent-steps="consentSteps"
      @answer="$emit('identify-participant', $event)"
      @consent-answer="$emit('consent-answer', $event)"
    />
  </div>
</template>
<script lang="ts">
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import { BrandingHelper } from '@getaccept/lib-shared-new/src/signing-theme/helpers/branding.helper';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import DsrUnavailable from '@getaccept/lib-shared-new/src/lobby/components/DsrUnavailable.vue';
import { useI18n } from 'vue-i18n';
import IdentifyParticipant from './IdentifyParticipant.vue';

export default defineComponent({
  components: {
    IdentifyParticipant,
    DsrUnavailable,
  },
  props: {
    consentSteps: {
      default: () => [],
      type: Array as PropType<AuthenticationStep[]>,
    },
    nextAuthStep: {
      type: Object as PropType<AuthenticationStep>,
      default: () => ({}),
    },
    submittingResponse: {
      type: Boolean,
    },
    showDsrUnavailable: {
      type: Boolean,
    },
    theme: {
      type: Object as PropType<SigningTheme>,
      default: () => ({}),
    },
  },
  emits: ['identify-participant', 'consent-answer'],
  setup(props) {
    const { t } = useI18n();
    const documentLogoUrl = computed(() => BrandingHelper.getLogoUrl(props.theme));

    return {
      documentLogoUrl,
      AuthenticationStepType,
      t,
    };
  },
});
</script>
<style scoped lang="scss">
.lobby-container {
  height: 100vh;
  overflow: hidden;
  background-color: var(--white);
}
</style>
