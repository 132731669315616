import { SubscriptionPlan } from '@getaccept/lib-shared-new/src/enums/subscription-plan';
import { Feature } from '../types/feature';
import type { FeatureRuleMap } from '../types/feature-rules';

export const upsellRuleMap: Partial<FeatureRuleMap> = {
  [Feature.ContextualCommenting]: [SubscriptionPlan.Esign],
  [Feature.IdentifyNewRecipients]: [SubscriptionPlan.Esign],
  [Feature.SigningOrder]: [SubscriptionPlan.Esign],
  [Feature.RecipientAttachments]: [
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.Solo,
    SubscriptionPlan.Starter,
    SubscriptionPlan.Business,
    SubscriptionPlan.Free,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Essential,
    SubscriptionPlan.Professional,
  ],
  [Feature.LinkBlock]: [SubscriptionPlan.WebTrial],
  [Feature.Sms]: [SubscriptionPlan.WebTrial],
  [Feature.ElectronicIdentification]: [SubscriptionPlan.Esign, SubscriptionPlan.WebTrial],
  [Feature.CrmIntegrations]: [
    SubscriptionPlan.Esign,
    SubscriptionPlan.Professional,
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.SalesTrial,
  ],
  [Feature.MarketingIntegrations]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.SalesTrial,
  ],
  [Feature.PaymentIntegrations]: [SubscriptionPlan.WebTrial, SubscriptionPlan.SalesTrial],
  [Feature.WorkflowIntegrations]: [SubscriptionPlan.WebTrial, SubscriptionPlan.SalesTrial],
  [Feature.EngageIntegrations]: [SubscriptionPlan.WebTrial, SubscriptionPlan.SalesTrial],
  [Feature.OtherIntegrations]: [SubscriptionPlan.WebTrial, SubscriptionPlan.SalesTrial],
  [Feature.ProvisioningSsoIntegrations]: [
    SubscriptionPlan.Esign,
    SubscriptionPlan.Professional,
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.SalesTrial,
  ],
  [Feature.ExternalApiAccess]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.SalesTrial,
  ],
  [Feature.CrmSalesforce]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.SalesTrial,
  ],
  [Feature.CrmDynamics]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.WebTrial,
    SubscriptionPlan.SalesTrial,
  ],
};
