import gql from 'graphql-tag';

export const entityFragment = gql`
  fragment entityFragment on Entity {
    id
    name
    companyName
    logoUrl
    countryCode
    isDisableComment
    isEsignConsent
    documentDownload
    reminderExpirationDays
    registeredNumber
    currency
    isSigningWrite
    isSigningForward
    signRedirect
    language
    isAutoComment
    timezone
    address
    zip
    state
    city
    country
    website
    email
    plan
  }
`;
