import type { Page } from '@getaccept/dsr-shared-new';
import type { DealroomPublished } from '@getaccept/dsr-shared-new/src/types/dsr';
import type { LoadImagePayload } from '@getaccept/editor-lib-new';
import { getLoadImageURLPayloads, setImageSrcURL } from '@getaccept/editor-lib-new';
import { ImageHeight } from '@getaccept/lib-shared-new/src/enums/image-height';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useLoadedImagesStore } from '@getaccept/editor-lib-new/src/store/loaded-images.store';
import { DsrService } from '../../api/dsr/service/dsr.service';
import { useRevisionsStore } from '../store/revisions.store';
import { useSessionStore } from '../../session/store/session.store';

export function useLoadDSR() {
  const revisionsStore = useRevisionsStore();
  const loadedImagesStore = useLoadedImagesStore();
  const sessionStore = useSessionStore();

  const loading = ref(true);
  const error = ref(false);
  const dsr: Ref<DealroomPublished> = ref(null);

  const pages: Ref<Page[]> = ref([]);
  const currentPageId: Ref<string> = ref(null);

  const loadDSR = async () => {
    try {
      [dsr.value, pages.value] = await Promise.all([DsrService.load(), DsrService.loadPages()]);
      sessionStore.setDealroomId(dsr.value.id);
      initialize();
    } catch (e) {
      error.value = true;
      console.error('Could not load DSR', e);
    } finally {
      loading.value = false;
    }
  };

  const loadNewPages = async () => {
    currentPageId.value = null;
    try {
      pages.value = await DsrService.loadPages();
      initialize();
    } catch (e) {
      error.value = true;
      console.error('Could not load new content', e);
    } finally {
      loading.value = false;
    }
  };

  const initialize = () => {
    pages.value = pages.value.map(page => ({
      ...page,
      editorBlock: loadedImagesStore.mapImagesToBlock(page.editorBlock),
    }));

    const firstVisiblePage = pages.value.find(
      ({ editorBlock }) => !editorBlock?.content?.sections?.every(({ hidden }) => hidden)
    );

    if (firstVisiblePage) {
      setCurrentPage(firstVisiblePage.id);
    }

    revisionsStore.setRevisionViewed();
  };

  const loadEditorImageURLs = (page: Page) => {
    const loadImagePayloads = (page.editorBlock && getLoadImageURLPayloads(page.editorBlock)) || [];
    loadImagePayloads.forEach(loadEditorImageURL);
  };

  const loadEditorImageURL = async (loadImagePayload: LoadImagePayload) => {
    try {
      const {
        url: srcUrl,
        externalId,
        blurhash,
      } = await DsrService.getEditorImageURL(loadImagePayload.imageId, ImageHeight.QHD);

      loadedImagesStore.setImage(loadImagePayload.imageId, srcUrl, externalId, blurhash);
      pages.value = setImageSrcURL(
        { pages: pages.value },
        {
          ...loadImagePayload,
          srcUrl,
          externalId,
          blurhash,
        }
      )?.pages as Page[];
    } catch (err) {
      console.error(err);
    }
  };

  const loadRoomSettings = async () => {
    try {
      const settings = await DsrService.loadRoomSettings();
      dsr.value = {
        ...dsr.value,
        settings,
      };
    } catch (e) {
      console.error(e);
    }
  };

  const setCurrentPage = (id: string) => {
    currentPageId.value = id;
    loadEditorImageURLs(currentPage.value);
  };

  const currentPage = computed(
    () => pages.value.find((page: Page) => page.id === currentPageId.value) || null
  );

  return {
    setCurrentPage,
    currentPage,
    currentPageId,
    loading,
    pages,
    dsr,
    error,
    loadDSR,
    loadNewPages,
    loadRoomSettings,
    loadEditorImageURLs,
    loadEditorImageURL,
  };
}
