import { useSessionStore } from '@getaccept/dsr-published-site/src/session/store/session.store';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { ReportEmailService } from '../../api/report-email/services/report-email.service';
import { useRootStore } from '../../store/root.store';
import type { Reason } from '../types/unsubscribe-reason';

export function useReportEmail() {
  const loading: Ref<boolean> = ref(false);
  const reported: Ref<boolean> = ref(false);

  const report = async (reason?: Reason): Promise<void> => {
    try {
      const store = useRootStore();
      const { documentId, recipientId } = storeToRefs(store);

      loading.value = true;
      await ReportEmailService.reportDocument({
        recipientId: recipientId.value,
        documentId: documentId.value,
        reason,
      });
      reported.value = true;
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const reportDsr = async (reason?: Reason): Promise<void> => {
    try {
      const sessionStore = useSessionStore();
      const { entityId, participantId, dealroomId } = storeToRefs(sessionStore);

      loading.value = true;
      await ReportEmailService.reportDsr({
        entityId: entityId.value,
        participantId: participantId.value,
        dealroomId: dealroomId.value,
        reason,
      });
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  return { loading, reported, report, reportDsr };
}
