import type { DealroomSettings, DealroomPublished } from '@getaccept/dsr-shared-new/src/types/dsr';
import { getImageURLQuery } from '@getaccept/editor-lib-new';
import { entityQuery } from '@getaccept/lib-shared-new/src/entity/signing-site/entity.queries';
import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import type { Page, ParticipantPublishedDTO } from '@getaccept/dsr-shared-new';
import { apolloClient } from '../../../apollo';
import { DSRMutations } from '../graphql/dsr.mutations';
import { DSRQueries } from '../graphql/dsr.queries';
import type { DealroomRevision } from '../types/dealroom-revision';
import { ParticipantsQueries } from '../../participants/graphql/participants.queries';
import type { ParticipantInvite } from '../../../published/types/participant-invite';

export class DsrService {
  static async load(): Promise<DealroomPublished> {
    const {
      data: { dealroom },
    } = await apolloClient.query({
      query: DSRQueries.load,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return dealroom;
  }

  static async loadPages(): Promise<Page[]> {
    const {
      data: { dealroomPages },
    } = await apolloClient.query({
      query: DSRQueries.loadPages,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return dealroomPages;
  }

  static async loadRevisions(): Promise<DealroomRevision[]> {
    const {
      data: { dealroomRevisions },
    } = await apolloClient.query({
      query: DSRQueries.loadRevisions,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return dealroomRevisions || [];
  }

  static async loadRoomSettings(): Promise<DealroomSettings> {
    const {
      data: { dealroom },
    } = await apolloClient.query({
      query: DSRQueries.loadRoomSettings,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return dealroom.settings;
  }

  static async setRevisionViewed(revision: number): Promise<boolean> {
    const {
      data: { setRevisionViewed },
    } = await apolloClient.mutate({
      mutation: DSRMutations.setRevisionViewed,
      variables: { revision },
    });
    return setRevisionViewed;
  }

  static async loadParticipants(
    includeDeletedParticipants: boolean
  ): Promise<ParticipantPublishedDTO[]> {
    const { data } = await apolloClient.query({
      query: DSRQueries.loadParticipants,
      variables: { includeDeletedParticipants },
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return data?.participants || [];
  }

  static async loadParticipant(): Promise<{ id: string }> {
    const {
      data: { participant },
    } = await apolloClient.query({
      query: DSRQueries.loadParticipant,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return participant;
  }

  static async loadEntity(): Promise<Entity> {
    const {
      data: { entity },
    } = await apolloClient.query({
      query: entityQuery,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return entity;
  }

  static async loadParticipantInput(): Promise<EditorRecipientInput[]> {
    const {
      data: { editorParticipantInput },
    } = await apolloClient.query({
      query: ParticipantsQueries.loadParticipantInput,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return editorParticipantInput;
  }

  static async loadTheme({
    documentId,
    entityId,
  }: {
    documentId?: string;
    entityId?: string;
  }): Promise<SigningTheme> {
    const {
      data: { theme },
    } = await apolloClient.query({
      query: DSRQueries.theme,
      variables: {
        documentId,
        entityId,
      },
    });
    return theme;
  }

  static async getEditorImageURL(
    id: string,
    height?: string
  ): Promise<{ url: string; externalId: string; blurhash: string }> {
    const {
      data: { image },
    } = await apolloClient.query({
      query: getImageURLQuery,
      variables: { id, height },
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });

    return {
      url: image?.url || '',
      externalId: image?.externalId || undefined,
      blurhash: image?.blurhash || '',
    };
  }

  static async inviteParticipant(
    { id, ...invitePayload }: ParticipantInvite,
    notificationMessage: string
  ): Promise<void> {
    try {
      await apolloClient.mutate({
        mutation: DSRMutations.inviteParticipant,
        variables: {
          payload: {
            ...invitePayload,
            notificationMessage,
          },
        },
      });
    } catch {
      throw invitePayload.email;
    }
  }
}
