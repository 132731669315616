import gql from 'graphql-tag';

export class DSRMutations {
  static setRevisionViewed = gql`
    mutation dealroomSetRevisionViewed($revision: Int!) {
      dealroomSetRevisionViewed(revision: $revision)
    }
  `;

  static inviteParticipant = gql`
    mutation AddParticipant($payload: AddParticipantInput!) {
      addParticipant(payload: $payload)
    }
  `;
}
