export enum FieldType {
  Unknown = 'unknown',
  Check = 'check',
  Dropdown = 'dropdown',
  Formatted = 'formatted',
  Initials = 'initials',
  Link = 'link',
  Merge = 'merge',
  Signature = 'signature',
  Text = 'text',
}
