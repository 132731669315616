<template>
  <div class="sidebar-overlay" @click="$emit('close')" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],
});
</script>

<style lang="scss" scoped>
.sidebar-overlay {
  background: rgba(#000, 0.55);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
}
</style>
