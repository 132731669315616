export class StatePersistHelper {
  static regex = /(?:dsr)\/([^/]*?)\/([^/]*?)\/a\/([^/]*?)(?:\/|$)/;
  static newModelRegex = /(?:dsr)\/([^/]*?)\/([^/]*?)\/([^/]*?)(\/[^/?]*?)?(?:\?|$)/;

  static getSessionKey(url: string): string {
    const match = StatePersistHelper.regex.test(url);
    if (match) {
      const [, , participantId]: RegExpMatchArray = url.match(StatePersistHelper.regex);
      return `GA_PUBLISHED_PAGE_${participantId}`;
    }
    const matchNewModelUrl = StatePersistHelper.newModelRegex.test(url);
    if (matchNewModelUrl) {
      const [, , , participantId]: RegExpMatchArray = url.match(StatePersistHelper.newModelRegex);
      return `GA_PUBLISHED_PAGE_${participantId}`;
    }
    return '';
  }

  static getEmailKey(url: string, participantId: string): string {
    const match = StatePersistHelper.regex.test(url);
    if (match) {
      const [, dealroomId]: RegExpMatchArray = url.match(StatePersistHelper.regex);
      return `GA_PUBLISHED_DR_${dealroomId}_${participantId}_EMAIL`;
    }
    const matchNewModelUrl = StatePersistHelper.newModelRegex.test(url);
    if (matchNewModelUrl) {
      const [, , dealroomId]: RegExpMatchArray = url.match(StatePersistHelper.newModelRegex);
      return `GA_PUBLISHED_DR_${dealroomId}_${participantId}_EMAIL`;
    }
    return '';
  }

  static getOldEmailKey(url: string): string {
    const match = StatePersistHelper.regex.test(url);
    if (match) {
      const [, dealroomId]: RegExpMatchArray = url.match(StatePersistHelper.regex);
      return `GA_PUBLISHED_DR_${dealroomId}_EMAIL`;
    }
    const matchNewModelUrl = StatePersistHelper.newModelRegex.test(url);
    if (matchNewModelUrl) {
      const [, , dealroomId]: RegExpMatchArray = url.match(StatePersistHelper.newModelRegex);
      return `GA_PUBLISHED_DR_${dealroomId}_EMAIL`;
    }
    return '';
  }
}
