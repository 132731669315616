<template>
  <FriedModal :closable="false">
    <template #header>
      <FriedModalHeader :header="title" />
    </template>
    <FriedParagraph>{{ text }}</FriedParagraph>
    <Person
      v-if="!isDocumentRecalled"
      class="sender"
      :thumb-url="sender.thumbUrl"
      :name="sender.fullName"
      :title="sender.fullName"
      :subtitle="sender.email"
    />
    <template #footer>
      <FriedModalButtonsFooter v-if="!isDocumentRecalled">
        <FriedButton
          v-tooltip="{
            distance: 12,
            content: $t('dead_ends_tooltip_email_copied_to_clipboard'),
            triggers: ['click'],
            shown: showCopyTooltip,
          }"
          :button-type="ButtonType.Secondary"
          data-external="non-active-document-modal-copy-email-button"
          @click="handleCopyEmailToClipboard"
        >
          <template #leftIcon>
            <FriedIcon icon="copy" />
          </template>
          {{ $t('dead_ends__button_copy_email_address') }}
        </FriedButton>
        <FriedButton
          :button-type="ButtonType.Primary"
          data-external="non-active-document-modal-open-email-client-button"
          @click="openEmailClient"
        >
          <template #leftIcon>
            <FriedIcon icon="mail-sign-at" />
          </template>
          {{ $t('dead_ends__button_open_email_client') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModal>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import copy from 'copy-to-clipboard';
import { computed, defineComponent, ref } from 'vue';
import { ButtonType } from '@getaccept/fried-tofu';
import Person from '@getaccept/lib-shared-new/src/components/Person.vue';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { DocumentHelper } from '../../helpers/document.helper';

export default defineComponent({
  name: 'NonActiveDocumentModal',
  components: { Person },
  props: {
    document: {
      type: Object as PropType<SigningPageDocument>,
      default: null,
    },
    isDocumentRecalled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const showCopyTooltip = ref(false);

    const documentName = computed(() => props.document?.name);
    const sender = computed(() => props.document?.user);
    const expirationDate = computed(() => props.document?.expirationDate);
    const isDocumentExpired = computed(() => DocumentHelper.getIsDocumentExpired(props.document));
    const isDocumentRejected = computed(() => props.document?.status === DocumentStatus.Rejected);

    const title = computed(() => {
      if (props.isDocumentRecalled) {
        return t('document-has-been-recalled');
      }

      if (isDocumentExpired.value) {
        return t('document-has-expired');
      }

      if (isDocumentRejected.value) {
        return t('document-has-been-declined');
      }

      return '';
    });

    const text = computed(() => {
      if (props.isDocumentRecalled) {
        return t('document-recalled-lobby-description');
      }

      if (isDocumentExpired.value) {
        return t('document-expired-lobby-description', {
          'document-name': documentName.value,
          'sender-name': sender.value?.fullName,
          'expire-date': DateTime.fromISO(expirationDate.value).toLocaleString(
            DateTime.DATETIME_FULL
          ),
        });
      }

      if (isDocumentRejected.value) {
        return t('document-rejected-lobby-description_v2', {
          documentName: documentName.value,
          senderName: sender.value?.fullName,
        });
      }

      return '';
    });

    const handleCopyEmailToClipboard = () => {
      copy(sender.value.email);
      showCopyTooltip.value = true;
      setTimeout(() => (showCopyTooltip.value = false), 2000);
    };

    const openEmailClient = () => {
      const link = `mailto:${encodeURIComponent(
        `${sender.value.fullName} <${sender.value.email}>`
      )}?subject=${encodeURIComponent(documentName.value)}`;

      window.open(link);
    };

    return {
      title,
      text,
      sender,
      showCopyTooltip,
      handleCopyEmailToClipboard,
      openEmailClient,
      ButtonType,
    };
  },
});
</script>
<style lang="scss" scoped>
.sender {
  padding-top: var(--spacing-200);
}
</style>
