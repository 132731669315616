<template>
  <div>
    <div class="lobby-card-container">
      <FriedCard class="lobby-card">
        <div class="card-header">
          <FriedH2 class="header">{{ header }}</FriedH2>
        </div>
        <slot />
        <div v-if="$slots.footer" class="footer">
          <slot name="footer" />
        </div>
      </FriedCard>
    </div>
    <Backdrop v-if="!hideBackdrop" />
  </div>
</template>
<script lang="ts">
import Backdrop from '@getaccept/lib-shared-new/src/components/Backdrop.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Backdrop,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style scoped lang="scss">
.lobby-card-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  z-index: $z-modal;

  @media screen and (max-width: $sm) {
    padding: var(--spacing-100);
  }

  .lobby-card {
    border-radius: var(--radius-medium);
    min-height: 15rem;
    padding: var(--spacing-150);

    :deep(.card-header) {
      padding: 0;

      .header {
        margin: 0 0 var(--spacing-150) 0;
      }
    }

    .footer {
      justify-content: flex-end;
      display: flex;
    }
  }
}
</style>
