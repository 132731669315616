import type { LoadImagePayload } from '@getaccept/editor-lib-new';
import { getLoadImageURLPayloads, setImageSrcURL } from '@getaccept/editor-lib-new';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { ImageHeight } from '@getaccept/lib-shared-new/src/enums/image-height';
import { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import { useToast } from '@getaccept/lib-shared-new/src/toast';
import type { DocumentAttachment } from '@getaccept/lib-shared-new/src/types/document-attachment';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAsyncState } from '@vueuse/core';
import { DateTime } from 'luxon';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { DocumentAttachmentService } from '../../api/document-attachments/services/document-attachment.service';
import { DocumentService } from '../../api/documents/services/document.service';
import { DocumentViewableError } from '../../api/documents/types/document-viewable-error';
import { useFieldsStore } from '../../fields/store/fields.store';
import { DocumentHelper } from '../../helpers/document.helper';
import type { InviteRecipientInfo } from '../../signing/types/invite-recipient-info';
import { DocumentEvent } from '../types/enums/document-event';
import { QesService } from '../../api/qes/services/qes.service';

export const useDocumentStore = defineStore('document', () => {
  const isLoading = ref(true);
  const isProcessing = ref(false);
  const document: Ref<SigningPageDocument> = ref(null);
  const error = ref(false);

  const { state: qesFiles, execute: loadQesFiles } = useAsyncState(
    (documentId: string) => QesService.getQesFiles(documentId),
    null,
    { immediate: false }
  );

  const loadDocument = async () => {
    isProcessing.value = false;
    isLoading.value = true;
    try {
      const isDocumentViewable = await DocumentService.isDocumentViewable();
      if (isDocumentViewable) {
        const loadedDocument: SigningPageDocument = await DocumentService.getDocument();
        document.value = loadedDocument;
        loadQesFiles(0, loadedDocument.id);
        reload(loadedDocument.expirationDate);
        loadEditorImageURLs();
      }
    } catch (e: any | DocumentViewableError) {
      if (e?.message?.includes(DocumentViewableError.Processing)) {
        isProcessing.value = true;
      } else {
        error.value = true;
        useToast().toast.danger(t('failed-to-load-document'));
      }
    } finally {
      isLoading.value = false;
    }
  };

  const reload = (expirationDate: string) => {
    const timeout: number = DateTime.fromISO(expirationDate).diffNow().as('milliseconds');
    const maxTimeout: number = 8 * 3600 * 1000;
    if (timeout > 0) {
      setTimeout(
        () => {
          window.location.reload();
        },
        Math.min(timeout, maxTimeout)
      );
    }
  };

  const loadEditorImageURLs = () => {
    const loadImagePayloads = document.value.pages
      .filter(({ editorBlock }) => editorBlock)
      .flatMap(({ editorBlock }) => getLoadImageURLPayloads(editorBlock));

    loadImagePayloads.forEach(loadImagePayload => loadEditorImageURL(loadImagePayload));
  };

  const loadEditorImageURL = async (loadImagePayload: LoadImagePayload) => {
    try {
      const {
        url: srcUrl,
        externalId,
        blurhash,
      } = await DocumentService.getEditorImageURL(loadImagePayload.imageId, ImageHeight.QHD);
      document.value = setImageSrcURL(document.value, {
        ...loadImagePayload,
        srcUrl,
        externalId,
        blurhash,
      }) as SigningPageDocument;
    } catch (err) {
      console.error(err);
    }
  };

  const revisionsIsLoading = ref(false);
  const loadDocumentRevisions = async () => {
    revisionsIsLoading.value = true;
    try {
      const { documentRevisions } = await DocumentService.getDocumentRevisions();
      document.value.documentRevisions = documentRevisions;
    } catch (error) {
      error.value = true;
      useToast().toast.danger(t('failed-to-load-document-revision'));
    } finally {
      revisionsIsLoading.value = false;
    }
  };

  const revisionsViewedLoading = ref(false);
  const setRevisionViewedByRecipient = async (revision: number) => {
    revisionsViewedLoading.value = true;
    try {
      await DocumentService.addRecipientView(revision);
    } catch (error) {
      console.error(error);
    } finally {
      revisionsViewedLoading.value = false;
    }
  };

  const isEditorRecipientInputLoading = ref(true);
  const editorRecipientInput: Ref<EditorRecipientInput[]> = ref([]);

  const loadEditorRecipientInput = async () => {
    isEditorRecipientInputLoading.value = true;
    try {
      const editorRecipientInputResponse: EditorRecipientInput[] =
        await DocumentService.loadEditorRecipientInput();
      editorRecipientInput.value = editorRecipientInputResponse;
    } catch (err) {
      useToast().toast.danger(t('failed-to-load-editor-recipient-input'));
    } finally {
      isEditorRecipientInputLoading.value = false;
    }
  };

  const setEditorRecipientInput = (inputs: EditorRecipientInput[]) => {
    editorRecipientInput.value = inputs;
  };

  const focusedRequiredId = ref(null);
  const setFocusedRequiredId = (id: string) => {
    focusedRequiredId.value = id;
  };

  const updateRecipientStatus = (recipientId: string, status: RecipientStatus) => {
    const recipientsUpdated: SigningPageRecipient[] = document.value.recipients.map(recipient =>
      recipient.id === recipientId ? { ...recipient, status } : recipient
    );
    document.value.recipients = recipientsUpdated;
  };

  const completeSigning = (signeesWaiting: number, recipientId: string) => {
    updateRecipientStatus(recipientId, RecipientStatus.Signed);
    if (signeesWaiting === 0) {
      useToast().toast.info(t('loading-document-certificate'));
      document.value.signDate = DateTime.now().toISO();
    }
  };

  const setAttachmentView = (id: string) => {
    const attachments: DocumentAttachment[] = [...document.value.documentAttachments];
    const index: number = attachments.findIndex((item: DocumentAttachment) => item.id === id);
    attachments[index].lastView = DateTime.now().toISO();
    document.value.documentAttachments = attachments;
  };

  const rejectDocument = async (
    reason: string,
    recipientId: string,
    documentId: string,
    authId: string
  ) => {
    try {
      await DocumentService.reject(reason, documentId, recipientId, authId);
    } catch (e) {
      console.error('Could not reject document', e);
    }
  };

  const sendEventLog = async (event: DocumentEvent, recipientId: string, authId: string) => {
    try {
      await DocumentService.logEvent(document.value.id, recipientId, authId, event);
    } catch (error) {
      console.error(error);
    }
  };

  const setDocumentStatus = (status: DocumentStatus) => {
    document.value.status = status;
  };

  const sendDocumentStatus = async (
    newDocumentStatus: DocumentStatus,
    recipientId: string,
    authId: string
  ) => {
    const { recipients, status } = document.value;
    const recipient = DocumentHelper.getRecipientById(recipientId, recipients);

    if (status === newDocumentStatus && status === recipient.status) {
      return;
    }
    switch (newDocumentStatus) {
      case DocumentStatus.Reviewed:
        await sendEventLog(DocumentEvent.Reviewed, recipientId, authId);
        setDocumentStatus(DocumentStatus.Reviewed);
        break;
      case DocumentStatus.Viewed:
        setDocumentStatus(DocumentStatus.Viewed);
        break;
      case DocumentStatus.Rejected:
        sendEventLog(DocumentEvent.Rejected, recipientId, authId);
        setDocumentStatus(DocumentStatus.Rejected);
        break;
    }
  };

  const setRecipientStatus = (status: RecipientStatus, recipientId: string, authId: string) => {
    switch (status) {
      case RecipientStatus.Reviewed:
        updateRecipientStatus(recipientId, RecipientStatus.Reviewed);
        sendEventLog(DocumentEvent.Reviewed, recipientId, authId);
        break;
      case RecipientStatus.Viewed:
        updateRecipientStatus(recipientId, RecipientStatus.Viewed);
        break;
      case RecipientStatus.Rejected:
        updateRecipientStatus(recipientId, RecipientStatus.Rejected);
        sendEventLog(DocumentEvent.Rejected, recipientId, authId);
        break;
    }
  };

  const loadDocumentAttachments = async () => {
    try {
      const documentAttachments: DocumentAttachment[] = await DocumentAttachmentService.load();
      document.value.documentAttachments = documentAttachments;
    } catch (e) {
      useToast().toast.danger(t('failed-to-load-attachments'));
    }
  };

  const loadRecipients = async () => {
    try {
      const recipients: SigningPageRecipient[] = await DocumentService.getRecipients();
      document.value.recipients = recipients;
    } catch (e) {
      console.error('Could not load recipients', e);
    }
  };

  const forwarded = ref(false);
  const setForwardStatus = (status: boolean) => {
    forwarded.value = status;
  };

  const forwardToRecipientSuccess = (
    recipientInfo: InviteRecipientInfo,
    recipientId: string,
    authId: string
  ) => {
    sendEventLog(DocumentEvent.Forwarded, recipientId, authId);
    if (recipientInfo.transferSignature) {
      useFieldsStore().load();
    }
    setForwardStatus(true);
    loadRecipients();
  };

  const isButtonLoading = ref(false);
  const reachedMaxRecipients = 'Limit for max amount of recipients reached';
  const forwardToRecipient = async (
    recipientInfo: InviteRecipientInfo,
    recipientId: string,
    authId: string
  ) => {
    isButtonLoading.value = true;
    try {
      const response: { status: number; error: string } = await DocumentService.forward(
        document.value.id,
        recipientId,
        authId,
        recipientInfo
      );
      if (response.status === 1) {
        forwardToRecipientSuccess(recipientInfo, recipientId, authId);
      } else if (response.error === reachedMaxRecipients) {
        useToast().toast.danger(t('error_toast_cannot_add_more_recipients'));
      } else {
        useToast().toast.danger(t('failed-to-invite-recipient'));
      }
    } catch (e) {
      useToast().toast.danger(t('failed-to-invite-recipient'));
    } finally {
      isButtonLoading.value = false;
    }
  };

  const pdfUrl = ref('');
  const getPdfUrl = async () => {
    try {
      const pdfUrlResponse: string = await DocumentService.getDownloadUrl();
      pdfUrl.value = pdfUrlResponse;
    } catch (e) {
      useToast().toast.danger(t('failed-to-download-pdf'));
    }
  };

  const isDownloading = ref(false);

  const downloadDocument = async () => {
    isDownloading.value = true;
    try {
      await DocumentService.download();
    } catch {
      useToast().toast.danger(t('failed-to-download-document'));
      isDownloading.value = false;
    }
  };

  const downloadDocumentComplete = () => {
    isDownloading.value = false;
  };

  const partiallySignedFields: Ref<PartiallySignedEvent[]> = ref([]);
  const loadPartiallySignedFields = async () => {
    try {
      const partiallySignedFieldsResponse = await DocumentService.loadPartiallySignedFields();
      partiallySignedFields.value = partiallySignedFieldsResponse;
    } catch (error) {
      useToast().toast.danger(t('could_not_load_data_please_try_again_or_contact_support'));
    }
  };

  const setProcessingDone = () => {
    isProcessing.value = false;
  };

  const approveDocument = (recipientId: string) => {
    updateRecipientStatus(recipientId, RecipientStatus.Approved);
  };

  return {
    isEditorRecipientInputLoading,
    isLoading,
    revisionsIsLoading,
    revisionsViewedLoading,
    isButtonLoading,
    forwarded,
    document,
    focusedRequiredId,
    error,
    isProcessing,
    pdfUrl,
    isDownloading,
    editorRecipientInput,
    partiallySignedFields,
    qesFiles,
    loadDocument,
    loadEditorImageURL,
    setDocumentStatus,
    setProcessingDone,
    forwardToRecipient,
    setForwardStatus,
    setFocusedRequiredId,
    setRecipientStatus,
    sendDocumentStatus,
    setAttachmentView,
    rejectDocument,
    loadRecipients,
    loadDocumentAttachments,
    sendEventLog,
    getPdfUrl,
    loadDocumentRevisions,
    setRevisionViewedByRecipient,
    loadEditorRecipientInput,
    loadPartiallySignedFields,
    downloadDocument,
    downloadDocumentComplete,
    setEditorRecipientInput,
    completeSigning,
    approveDocument,
  };
});
