export class HttpThrottleError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, HttpThrottleError.prototype);
  }
}

export class HttpLockedError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, HttpLockedError.prototype);
  }
}
