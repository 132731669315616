import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { Field } from '@getaccept/lib-shared-new/src/fields/types/field';
import { useToast } from '@getaccept/lib-shared-new/src/toast';
import { useStorage } from '@vueuse/core';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { LocalStorageSubKey } from '../../local-storage/type/subkey';
import { LocalStorageHelper } from '../../local-storage/helpers/local-storage.helper';
import { FieldService } from '../../api/fields/services/field.service';

export const useFieldsStore = defineStore('fields', () => {
  const loading: Ref<boolean> = ref(false);
  const fields: Ref<Field[]> = ref([]);

  const load = async () => {
    loading.value = true;
    try {
      fields.value = await FieldService.loadFields();
    } catch (err) {
      console.error('Could not load fields', err);
    } finally {
      loading.value = false;
    }
  };

  const pageWidth: Ref<number> = ref(null);

  const updatePageWidth = (newWidth: number) => {
    pageWidth.value = newWidth;
  };

  const updateField = (updatedField: Field) => {
    fields.value = fields.value.map((stateField: Field) =>
      stateField.id === updatedField.id ? updatedField : stateField
    );
  };

  const updateValue = async (fieldId: string, value: string) => {
    const staleField: Field = fields.value.find((field: Field) => field.id === fieldId);
    const updatedField: Field = { ...staleField, value };
    updateField(updatedField);
    try {
      await FieldService.updateField(fieldId, value);
    } catch (error) {
      updateField(staleField);
      useToast().toast.danger(t('failed-to-update-fields'));
    }
  };

  const verifiedInitialFieldIds: Ref<string[]> = useStorage(
    LocalStorageHelper.buildKey(window.location.href, LocalStorageSubKey.VerifiedFields),
    []
  );

  const addVerifiedInitialId = (fieldId: string) => {
    if (verifiedInitialFieldIds.value.includes(fieldId)) {
      return;
    }
    verifiedInitialFieldIds.value.push(fieldId);
  };

  return {
    fields,
    loading,
    pageWidth,
    verifiedInitialFieldIds,

    load,
    updatePageWidth,
    updateValue,
    addVerifiedInitialId,
  };
});
