<template>
  <div class="toast-item" :class="toast.options.type" role="alert">
    <FriedH4 v-if="textContent" :data-external="`alert-content-msg:${textContent}`">{{
      textContent
    }}</FriedH4>
    <component
      :is="toast.content"
      v-else
      :toast="toast"
      v-bind="toast.options.props"
      v-on="toast.options.listeners"
    />
    <FriedIconButton
      v-if="toast.options.closable"
      class="close-button"
      data-external="alert-container-popup"
      aria-label="close"
      @click="remove(toast.id)"
    >
      <FriedIcon :size="IconSize.Small" icon="close" />
    </FriedIconButton>
    <div
      v-if="toast.options.timeout"
      ref="toastProgress"
      class="progress"
      :style="style"
      :class="{ hide: toast.options.hideProgress, pause: toast.options.pauseOnHover }"
    />
  </div>
</template>

<script lang="ts">
import { IconSize } from '@getaccept/fried-tofu';
import type { PropType } from 'vue';
import { computed, ref, defineComponent } from 'vue';
import { useEventListener } from '@vueuse/core';
import { useToast } from '../toast.composable';
import type { Toast } from '../types/toast';

export default defineComponent({
  props: {
    toast: { type: Object as PropType<Toast>, required: true },
  },
  setup(props) {
    const { remove } = useToast();
    const toastProgress = ref(null);

    const style = computed(() => ({
      ['animation-name']: `width-animation`,
      ['animation-direction']: `forwards`,
      ['animation-duration']: `${props.toast.options.timeout}ms`,
      ['animation-timing-function']: 'linear',
    }));

    useEventListener(toastProgress, 'animationend', () => {
      remove(props.toast.id);
    });

    const textContent = computed(() => {
      if (typeof props.toast.content === 'string') {
        return props.toast.content;
      }
      return '';
    });

    return { toastProgress, style, IconSize, remove, textContent };
  },
});
</script>

<style lang="scss" scoped>
$success-button-background: #c2f7e8;
$info-button-background: #bbd7fb;
$warning-button-background: var(--warning-dark);
$danger-button-background: #fdd6ce;

.toast-item {
  min-width: 200px;
  justify-content: space-between;
  padding: var(--spacing-25) var(--spacing-25) var(--spacing-25) var(--spacing-100);
  pointer-events: all;
  animation-play-state: running;
  border-radius: var(--radius-medium);
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-2);
  display: inline-flex;
  gap: var(--spacing-100);
  align-items: center;
  margin-bottom: var(--spacing-100);
  min-height: 3rem;
  background: white;

  &::after {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    position: absolute;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background: currentcolor;
    opacity: 0.5;
    animation-play-state: running;

    &.hide {
      opacity: 0;
    }
  }

  &:hover {
    .progress.pause {
      animation-play-state: paused;
    }
  }

  h4.heading-4 {
    color: currentcolor;
  }

  .close-button.button {
    color: currentcolor;
  }

  &.info {
    background: var(--info-light);
    color: var(--info-dark);

    &::after {
      background: var(--info-dark);
    }

    .close-button.button:hover,
    .close-button.button:focus {
      background: $info-button-background;
    }
  }

  &.danger {
    background: var(--error-light);
    color: var(--error-dark);

    &::after {
      background: var(--error-dark);
    }

    .close-button.button:hover,
    .close-button.button:focus {
      background: $danger-button-background;
    }
  }

  &.warning {
    background: var(--warning-light);
    color: var(--text-gray);

    &::after {
      background: var(--warning-dark);
    }

    .close-button.button:hover,
    .close-button.button:focus {
      background: $warning-button-background;
    }

    .progress {
      background: var(--warning-dark);
      opacity: 1;
    }
  }

  &.success {
    background: var(--success-light);
    color: var(--success-dark);

    &::after {
      background: var(--success-dark);
    }

    .close-button.button:hover,
    .close-button.button:focus {
      background: $success-button-background;
    }
  }
}
</style>

<style lang="scss">
@keyframes width-animation {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
</style>
