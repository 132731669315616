<template>
  <button
    v-wave
    class="button"
    data-external="restricted-lock-button"
    aria-label="lock button"
    @click="$emit('click')"
  >
    <FriedIcon icon="lock" />
  </button>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['click'],
});
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  padding: 0;
  height: 40px;
  width: 40px;
  border-radius: var(--radius-large);
  cursor: pointer;
  color: var(--lilac-55);
  background-color: var(--lilac-93);
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: var(--lilac-97);
  }
}
</style>
