import axios from 'axios';
import { SigningMutations } from '../graphql/signing.mutations';
import type { SignatureDTO } from '../../../fields/types/signature-dto';
import { SigningStep as ESigningStep } from '../../../signing/types/enums/signing-step';
import type { SigningStep } from '../../../signing/types/signing-step';
import type { SignResponse } from '../../../signing/types/sign-response';
import { apolloClient } from '../../../apollo';

export class SignatureService {
  static async getSignatures(
    recipientId: string,
    documentId: string,
    authId: string
  ): Promise<SignatureDTO[]> {
    if (!recipientId || !documentId || !authId) {
      //can't get signatures in preview mode since legacy api requires recipientId to fetch all signatures for a document.
      return [];
    }
    return (await axios.get(`/view/${documentId}/${recipientId}/${authId}/signatures`)).data;
  }

  static async verifySignatureBase64(signature: string): Promise<boolean> {
    const {
      data: { createSignature },
    } = await apolloClient.mutate({
      mutation: SigningMutations.createSignature,
      variables: { signature },
    });
    return createSignature;
  }

  static async verifySignatureBase30(
    recipientId: string,
    documentId: string,
    authId: string,
    signature: string
  ): Promise<boolean> {
    const body: FormData = new FormData();
    body.append('signature', signature);
    const { data } = await axios.post(
      `/view/${documentId}/${recipientId}/${authId}/verify-signature`,
      body
    );
    return data.status === 1;
  }

  static async verifyQna(
    answer: string,
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const body: FormData = new FormData();
    body.append('type', 'sign');
    body.append('answer', answer);
    const { data } = await axios.post(
      `/view/${documentId}/${recipientId}/${authId}/verify-qna`,
      body
    );
    return data.status === 1;
  }

  static async sendSmsCode(
    documentId: string,
    recipientId: string,
    authId: string,
    retryCount = 0
  ): Promise<boolean> {
    const body = new FormData();
    body.append('type', 'sign');
    if (retryCount > 0) {
      body.append('retry_count', `${retryCount}`);
    }
    const { data } = await axios.post(
      `/view/${documentId}/${recipientId}/${authId}/send-sms`,
      body
    );

    return data.status === 1;
  }

  static async verifySmsCode(
    pin: string,
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<boolean> {
    const body = new FormData();
    body.append('type', 'sign');
    body.append('code', pin);
    const { data } = await axios.post(
      `/view/${documentId}/${recipientId}/${authId}/verify-sms`,
      body
    );
    return data.status === 1;
  }

  static async checkVerificationStep(
    recipientId: string,
    documentId: string,
    authId: string
  ): Promise<SigningStep> {
    const {
      data: { mobile, verify_qna_question, verify },
    } = await axios.get(`/view/${documentId}/${recipientId}/${authId}/status`);
    if (!verify) {
      return { type: ESigningStep.Click, data: {} };
    }
    const strippedMobile: string = mobile ? mobile.substring(mobile.length - 4) : '';
    return { type: verify, data: { question: verify_qna_question || '', mobile: strippedMobile } };
  }

  static async sign(
    initials: string,
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<SignResponse> {
    const body = new FormData();
    body.append('initials', initials);
    const source = 'v3';
    const { data } = await axios.post(
      `/view/${documentId}/${recipientId}/${authId}/sign?source=${source}`,
      body
    );

    return {
      status: data.status,
      signeesWaiting: data.signees_waiting,
      description: data.description,
    };
  }

  static async approve(documentId: string, recipientId: string, authId: string): Promise<boolean> {
    const { data } = await axios.post(`/view/${documentId}/${recipientId}/${authId}/approve`);
    return data.status === 1;
  }
}
