import { ChannelType } from '@getaccept/pusher';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { Channel } from '@getaccept/pusher';
import { pusherClient } from '../../pusher';

export const usePusherStore = defineStore('pusher', () => {
  const documentChannel: Ref<Channel> = ref(null);
  const subscribeToDocumentChannel = async () => {
    documentChannel.value = await pusherClient.subscribe({ type: ChannelType.Document });
  };

  const recipientChannel: Ref<Channel> = ref(null);
  const subscribeToRecipientChannel = async () => {
    recipientChannel.value = await pusherClient.subscribe({ type: ChannelType.Recipient });
  };

  const editorChannels: Ref<Channel[]> = ref([]);
  const subscribeToEditorChannels = async (contentIds: string[]) => {
    editorChannels.value = await Promise.all(
      contentIds.map((contentId: string) =>
        pusherClient.subscribe({ type: ChannelType.Editor, contentId })
      )
    );
  };
  const unsubscribeFromEditorChannels = () => {
    editorChannels.value.forEach(channel => {
      pusherClient.unsubscribe(channel);
    });
  };

  return {
    documentChannel,
    subscribeToDocumentChannel,

    recipientChannel,
    subscribeToRecipientChannel,

    editorChannels,
    subscribeToEditorChannels,
    unsubscribeFromEditorChannels,
  };
});
