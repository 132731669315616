import { sanitizeUrl } from '@braintree/sanitize-url';
import { DateTime } from 'luxon';
import { MergeType } from '@getaccept/lib-shared-new/src/fields/types/enums/merge-type';
import { initialHelper } from '@getaccept/lib-shared-new/src/helpers';
import { MergeFieldDateFormat } from '@getaccept/lib-shared-new/src/fields/types/enums/merge-field-date-format';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import type { SigningPageUser } from '@getaccept/lib-shared-new/src/types/signing-page-user';
import type { Field } from '@getaccept/lib-shared-new/src/fields/types/field';

export class SigningMergeTagHelper {
  static replaceTags(
    text,
    {
      recipient,
      document,
      entity,
      field,
    }: {
      recipient?: Partial<SigningPageRecipient> | SigningPageRecipient;
      sender?: Partial<SigningPageUser> | SigningPageUser;
      document?: Partial<SigningPageDocument> | SigningPageDocument;
      entity?: Partial<Entity> | Entity;
      field?: Field;
    }
  ): string {
    const textWithLinks: string = this.replaceLinks(text);
    const matches: string[] = this.findTags(textWithLinks);
    const textWithReplacedTags: string = matches.reduce((result: string, code: string) => {
      const strippedTag: string = this.stripTags(code);
      const convertedTag: string = this.getMergeDisplayProperty(strippedTag);
      const codeValue: string = this.getValue(convertedTag, { recipient, document, entity, field });

      return result.replace(code, codeValue);
    }, textWithLinks);

    return this.stripTags(textWithReplacedTags);
  }

  static stripTags(value: string): string {
    return value.replace(/{{/g, '').replace(/}}/g, '');
  }

  static findTags(text): string[] {
    /* eslint-disable no-useless-escape */
    /* eslint-disable-next-line prettier/prettier */
    const codePattern = new RegExp(/\{\{([^\d]{1}.[^\{\s\,\@]+\.[^\d\s]??[^\}\@\s\,]+)\}\}/gi);
    return text.match(codePattern) || [];
  }

  static replaceLinks(text: string): string {
    const regex = /{{link (?:'|")(.*?)(?:'|")}}(.*?){{unlink}}/;
    const match: boolean = regex.test(text);
    if (!match) {
      return text;
    }
    const [, url, linkText]: RegExpMatchArray = text.match(regex);
    const sanitizedURL: string = sanitizeUrl(url);
    return text.replace(regex, `<a href="${sanitizedURL}" target="_blank">${linkText}</a>`);
  }

  static getValue(
    tag,
    {
      recipient,
      document,
      entity = {},
      field,
    }: {
      recipient?: Partial<SigningPageRecipient> | SigningPageRecipient;
      document?: Partial<SigningPageDocument> | SigningPageDocument;
      entity?: Partial<Entity> | Entity;
      field?: Field;
    },
    emptyWhenUnknownObject = true
  ): string {
    const [objectName, propertyName] = tag.split('.');
    if (!(objectName && propertyName)) {
      return tag;
    }

    switch (objectName) {
      case 'recipient':
        return this.getPropertyInObject(propertyName, recipient);
      case 'sender':
        return this.getPropertyInObject(propertyName, document.user);
      case 'document':
        return this.getPropertyInObject(propertyName, {
          ...document,
          expirationDate: document.expirationDate
            ? DateTime.fromISO(document.expirationDate, {
                zone: 'utc',
              }).toLocaleString(DateTime.DATETIME_MED)
            : DateTime.now().toLocaleString(DateTime.DATETIME_MED),
          sendDate: document.sendDate
            ? DateTime.fromISO(document.sendDate, {
                zone: 'utc',
              }).toLocaleString(DateTime.DATETIME_MED)
            : '',
        });
      case 'entity':
        return this.getPropertyInObject(propertyName, entity);
      case 'date':
        return this.getDate(propertyName, field, document);
      default:
        return emptyWhenUnknownObject ? '' : tag;
    }
  }

  static getPropertyInObject(
    propertyName: string,
    obj:
      | Partial<SigningPageRecipient>
      | Partial<SigningPageDocument>
      | Partial<Entity>
      | Partial<SigningPageUser>
  ): any {
    if (propertyName === 'initials' && 'fullName' in obj) {
      return initialHelper(obj.fullName);
    }
    if (!obj || !(propertyName in obj)) {
      return '';
    }
    return obj[propertyName];
  }

  static getMergeDisplayProperty(tag: string): string {
    switch (tag) {
      case MergeType.DocumentName:
        return 'document.name';
      case MergeType.DocumentValue:
        return 'document.value';
      case MergeType.SendDate:
        return 'document.sendDate';
      case MergeType.UniqueId:
        return 'document.uniqueId';
      case MergeType.ExpirationDate:
        return 'document.expirationDate';

      case MergeType.SenderName:
        return 'sender.fullName';
      case MergeType.SenderFirstName:
        return 'sender.firstName';
      case MergeType.SenderLastName:
        return 'sender.lastName';
      case MergeType.SenderTitle:
        return 'sender.title';
      case MergeType.SenderEmail:
        return 'sender.email';
      case MergeType.SenderMobile:
        return 'sender.mobile';
      case MergeType.SenderEntityName:
        return 'entity.name';
      case MergeType.SenderCompanyName:
        return 'entity.companyName';
      case MergeType.SenderOrganisationNumber:
        return 'entity.registeredNumber';
      case MergeType.SenderInitials:
        return 'sender.initials';
      case MergeType.SenderPhone:
        return 'sender.phone';

      case MergeType.RecipientName:
        return 'recipient.fullName';
      case MergeType.RecipientFirstName:
        return 'recipient.firstName';
      case MergeType.RecipientLastName:
        return 'recipient.lastName';
      case MergeType.RecipientTitle:
        return 'recipient.title';
      case MergeType.RecipientEmail:
        return 'recipient.email';
      case MergeType.RecipientMobile:
        return 'recipient.mobile';
      case MergeType.RecipientCompanyName:
        return 'recipient.companyName';
      case MergeType.RecipientOrganisationNumber:
        return 'recipient.companyNumber';
      case MergeType.RecipientInitials:
        return 'recipient.initials';
      case MergeType.RecipientNote:
        return 'recipient.note';
      case MergeType.RecipientPhone:
        return 'recipient.phone';

      case MergeType.SignDateStandard:
        return 'date.iso';
      case MergeType.SignDateShort:
        return 'date.short';
      case MergeType.SignDateLong:
        return 'date.long';
      default:
        return tag;
    }
  }

  static getDate(
    propertyName: string,
    field,
    document: SigningPageDocument | Partial<SigningPageDocument>
  ): string {
    const formatMap = {
      iso: 'yyyy-MM-dd',
      short: { month: 'long', day: 'numeric', year: 'numeric' },
      long: {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    };
    const dateFormat = formatMap[propertyName];
    if (!dateFormat) {
      return '';
    }
    if (field && field.recipientId) {
      const { signDate } = document.recipients.find(rec => rec.id === field.recipientId);
      if (!signDate) {
        return '';
      }
      const dateTime = DateTime.fromSeconds(+signDate, {
        zone: 'utc',
      });

      if (propertyName === 'iso') {
        return dateTime.toISODate();
      }

      return dateTime.toLocaleString(dateFormat);
    } else {
      const { signDate } = document.user;
      if (!signDate) {
        return '';
      }
      const dateTime = DateTime.fromSeconds(+signDate, {
        zone: 'utc',
      });

      if (propertyName === 'iso') {
        return dateTime.toISODate();
      }

      return dateTime.toLocaleString(dateFormat);
    }
  }

  static getDateFormat(dateFormatIdentifier): string {
    switch (dateFormatIdentifier) {
      case 'iso':
        return MergeFieldDateFormat.iso;
      case 'short':
        return MergeFieldDateFormat.short;
      case 'long':
        return MergeFieldDateFormat.long;
      case 'yyyy':
        return MergeFieldDateFormat.yyyy;
      case 'yy':
        return MergeFieldDateFormat.yy;
      case 'mm':
        return MergeFieldDateFormat.mm;
      case 'm':
        return MergeFieldDateFormat.m;
      case 'dd':
        return MergeFieldDateFormat.dd;
      case 'd':
        return MergeFieldDateFormat.d;
      case 'hh':
        return MergeFieldDateFormat.hh;
      case 'h':
        return MergeFieldDateFormat.h;
      case 'ii':
        return MergeFieldDateFormat.ii;
      case 'ss':
        return MergeFieldDateFormat.ss;
      case 'a':
        return MergeFieldDateFormat.a;
      case 'i':
        return MergeFieldDateFormat.i;
      default:
        return '';
    }
  }
}
