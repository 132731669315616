import gql from 'graphql-tag';

export const addRecipientViewMutation = gql`
  mutation addView($revision: Int!) {
    addRecipientView(revision: $revision)
  }
`;

export const downloadDocumentMutation = gql`
  mutation Download {
    download
  }
`;

export const inviteMutation = gql`
  mutation inviteRecipient($payload: InviteRecipientInput!) {
    inviteRecipient(payload: $payload) {
      id
    }
  }
`;
