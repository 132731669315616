export enum MergeType {
  DocumentName = 'document.name',
  DocumentValue = 'document.value',
  SendDate = 'document.send_date',
  UniqueId = 'document.unique_id',
  ExpirationDate = 'document.expiration_date',
  ExternalId = 'document.external_id',

  SenderName = 'sender.fullname',
  SenderFirstName = 'sender.first_name',
  SenderLastName = 'sender.last_name',
  SenderTitle = 'sender.title',
  SenderEmail = 'sender.email',
  SenderMobile = 'sender.mobile',
  SenderEntityName = 'entity.name',
  SenderCompanyName = 'entity.companyName',
  SenderOrganisationNumber = 'entity.registered_number',
  SenderInitials = 'sender.initials',
  SenderPhone = 'sender.phone',

  RecipientName = 'recipient.fullname',
  RecipientFirstName = 'recipient.first_name',
  RecipientLastName = 'recipient.last_name',
  RecipientTitle = 'recipient.title',
  RecipientEmail = 'recipient.email',
  RecipientMobile = 'recipient.mobile',
  RecipientCompanyName = 'recipient.company_name',
  RecipientOrganisationNumber = 'recipient.company_number',
  RecipientInitials = 'recipient.initial',
  RecipientNote = 'recipient.note',
  RecipientPhone = 'recipient.phone',
  RecipientStatus = 'recipient.status',

  SignDateStandard = 'date.iso',
  SignDateShort = 'date.short',
  SignDateLong = 'date.long',

  Custom = '',
}
