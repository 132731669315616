import { Addons } from '@getaccept/lib-shared-new/src/enums/addons';
import { SubscriptionPlan } from '@getaccept/lib-shared-new/src/enums/subscription-plan';
import { Feature } from '../types/feature';
import type { FeatureRuleMap } from '../types/feature-rules';

const hasAtLeastEnterprise = [
  SubscriptionPlan.Enterprise,
  SubscriptionPlan.EnterprisePlus,
  SubscriptionPlan.Partner,
  SubscriptionPlan.Trial,
];

const hasAtLeastBusiness = [
  SubscriptionPlan.Business,
  SubscriptionPlan.Free,
  SubscriptionPlan.Pro,
  SubscriptionPlan.Essential,
  SubscriptionPlan.Professional,
  SubscriptionPlan.Enterprise,
  SubscriptionPlan.EnterprisePlus,
  SubscriptionPlan.Partner,
  SubscriptionPlan.Trial,
];

const hasAtLeastProfessional = [
  SubscriptionPlan.Professional,
  SubscriptionPlan.Enterprise,
  SubscriptionPlan.EnterprisePlus,
  SubscriptionPlan.Partner,
  SubscriptionPlan.Trial,
];

const isPaidPlan = [
  SubscriptionPlan.Starter,
  SubscriptionPlan.Business,
  SubscriptionPlan.Pro,
  SubscriptionPlan.Essential,
  SubscriptionPlan.Professional,
  SubscriptionPlan.Enterprise,
  SubscriptionPlan.EnterprisePlus,
  SubscriptionPlan.Partner,
  SubscriptionPlan.Trial,
];

const allLegacyPlans = [
  SubscriptionPlan.Free,
  SubscriptionPlan.Essential,
  SubscriptionPlan.Professional,
  SubscriptionPlan.EnterprisePlus,
  SubscriptionPlan.Trial,
  SubscriptionPlan.Solo,
  SubscriptionPlan.Starter,
  SubscriptionPlan.Business,
  SubscriptionPlan.Pro,
  SubscriptionPlan.Enterprise,
  SubscriptionPlan.Partner,
];

export const featureRuleMapLegacy: FeatureRuleMap = {
  [Feature.CommunicationTemplates]: [...allLegacyPlans],
  [Feature.Tags]: [...hasAtLeastEnterprise],
  [Feature.Webhooks]: [...allLegacyPlans],
  [Feature.CrmIntegrations]: [...allLegacyPlans],
  [Feature.MarketingIntegrations]: [...allLegacyPlans],
  [Feature.PaymentIntegrations]: [...allLegacyPlans],
  [Feature.WorkflowIntegrations]: [...allLegacyPlans],
  [Feature.EngageIntegrations]: [...allLegacyPlans],
  [Feature.ProvisioningSsoIntegrations]: [...hasAtLeastEnterprise],
  [Feature.OtherIntegrations]: [...allLegacyPlans],
  [Feature.ExternalApiAccess]: [...allLegacyPlans],
  [Feature.CrmSalesforce]: [...allLegacyPlans],
  [Feature.CrmDynamics]: [...allLegacyPlans],
  [Feature.ContextualCommenting]: [...allLegacyPlans],
  [Feature.BaseBranding]: [...isPaidPlan],
  [Feature.ExtendedBranding]: [
    SubscriptionPlan.Business,
    SubscriptionPlan.Essential,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Partner,
    SubscriptionPlan.Trial,
  ],
  [Feature.FullBranding]: [...hasAtLeastEnterprise],
  [Feature.DigitalCertificate]: [
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Partner,
  ],
  [Feature.Sms]: [
    SubscriptionPlan.Trial,
    SubscriptionPlan.Business,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Partner,
  ],
  [Feature.ElectronicIdentification]: [
    SubscriptionPlan.Trial,
    SubscriptionPlan.Business,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Partner,
  ],
  [Feature.Archive]: [
    SubscriptionPlan.Pro,
    SubscriptionPlan.Business,
    SubscriptionPlan.Essential,
    SubscriptionPlan.Professional,
  ],
  [Feature.ContractManagement]: [...hasAtLeastEnterprise],
  [Feature.IdentifyNewRecipients]: [...hasAtLeastBusiness],
  [Feature.DocumentAttachments]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Business,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.Partner,
    SubscriptionPlan.Trial,
  ],
  [Feature.RecipientAttachments]: [...hasAtLeastEnterprise],
  [Feature.UniqueDocumentId]: [...hasAtLeastBusiness],
  [Feature.EmailCopyOfNonPrivateSignedDocs]: [...hasAtLeastBusiness],
  [Feature.FingerprintOffset]: [...hasAtLeastBusiness],
  [Feature.DisableComments]: [...hasAtLeastBusiness],
  [Feature.EmailSenderNameOverride]: [...hasAtLeastProfessional],
  [Feature.EmailSenderAddressOverride]: [...hasAtLeastProfessional],
  [Feature.UniversalLink]: [...isPaidPlan],
  [Feature.TransferDocumentNotifications]: [...hasAtLeastEnterprise],
  [Feature.Teams]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Business,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.Partner,
  ],
  [Feature.Dealboard]: [...hasAtLeastBusiness],
  [Feature.Reports]: [...hasAtLeastBusiness],
  [Feature.QES]: [Addons.QES],
  [Feature.VideoBlock]: [...allLegacyPlans],
  [Feature.SigningOrder]: [...allLegacyPlans],
  [Feature.FullTracking]: [...allLegacyPlans],
  [Feature.NonSignableDocuments]: [...allLegacyPlans],
  [Feature.ExtendedElements]: [...allLegacyPlans],
  [Feature.CustomMergeTags]: [...allLegacyPlans],
  [Feature.RecipientLimit]: [...isPaidPlan],
  [Feature.ProductLibrary]: [
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Partner,
    SubscriptionPlan.Trial,
    Addons.ProductLibrary,
  ],
  [Feature.LinkBlock]: [...allLegacyPlans],
  [Feature.GlobalTemplateLibrary]: [...allLegacyPlans],
  [Feature.RestrictRoomAccess]: [
    SubscriptionPlan.Essential,
    SubscriptionPlan.Professional,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Solo,
    SubscriptionPlan.Starter,
    SubscriptionPlan.Business,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.Partner,
  ],
  [Feature.ContractroomPurchase]: [],
  [Feature.DealroomPurchase]: [],
  [Feature.RoomLimit]: [...allLegacyPlans],
  [Feature.FileSizeLimit]: [
    SubscriptionPlan.Professional,
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.Partner,
  ],
  [Feature.CustomColumns]: [...hasAtLeastEnterprise],
  [Feature.MyDocuments]: [
    SubscriptionPlan.EnterprisePlus,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Business,
    SubscriptionPlan.Enterprise,
    SubscriptionPlan.Trial,
    SubscriptionPlan.Pro,
    SubscriptionPlan.Partner,
  ],
};
