import type { BankId } from '../types/bankid';
import { BankIdSigningType, BankIdStep } from '../types/enums/bankid-se';
import type { Qna } from '../types/qna';
import type { Sign } from '../types/sign';
import type { Signature } from '../types/signature';
import type { SigningStep } from '../types/signing-step';
import type { Sms } from '../types/sms';

export class SigningStoreConstants {
  static bankId = (): BankId => ({
    step: BankIdStep.Start,
    autoStartToken: '',
    error: '',
    orderRef: '',

    signingType: BankIdSigningType.None,
    isLoading: false,
    hintCode: undefined,
    qrCode: undefined,
  });

  static sms = (): Sms => ({
    isVerifying: false,
    error: false,
    sendPinRetryCount: 0,
  });

  static signature = (): Signature => ({
    isVerifying: false,
    error: false,
  });

  static sign = (): Sign => ({
    isVerifying: false,
    error: null,
  });

  static step = (): SigningStep => ({ type: null, data: null });

  static qna = (): Qna => ({
    isVerifying: false,
    error: false,
  });
}
