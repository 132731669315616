// import Vue from 'vue';
import axios from 'axios';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';

const requestAborted = 'Request aborted';
axios.interceptors.response.use(
  response => response,
  error => {
    if (error !== requestAborted && error.message !== requestAborted && bugsnagClient) {
      bugsnagClient.notify(error, event => {
        event.groupingHash = error.toString();
      });
    }
    return Promise.reject(error);
  }
);
