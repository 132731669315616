export enum PathName {
  Root = 'root',
  Archive = 'archive',
  Contact = 'contact',
  Contacts = 'contacts',
  ContentLibrary = 'content-library',
  ContentLibraryEdit = 'content-library-edit',
  ContractManagement = 'contract-management',
  Contracts = 'contracts',
  Dashboard = 'dashboard',
  NewDashboard = 'new-dashboard',
  Dealboard = 'dealboard',
  DocumentEdit = 'document-edit',
  Documents = 'document',
  DocumentView = 'document-view',
  DocumentViewEngagement = 'document-view-engagement',
  DocumentViewContent = 'document-view-content',
  DocumentViewTimeline = 'document-view-timeline',
  DocumentViewSettings = 'document-view-settings',
  DsrEdit = 'dsr-edit',
  Library = 'library',
  Reports = 'reports',
  Settings = 'settings',
  SettingsUserProfile = 'settings-user-profile',
  SettingsEntity = 'settings-entity',
  SettingsBilling = 'settings-billing',
  SettingsSubscription = 'settings-subscription',
  SettingsSubscriptionNew = 'settings-subscription-new',
  SettingsSubscriptionNewAddSeats = 'settings-subscription-new-add-seats',
  SettingsReminder = 'settings-reminder',
  SettingsNotification = 'settings-notification',
  SettingsEmailTemplates = 'settings-email-templates',
  SettingsUsers = 'settings-users',
  SettingsTeams = 'settings-teams',
  SettingsThemes = 'settings-themes',
  SettingsTags = 'settings-tags',
  SettingsIntegrations = 'settings-integrations',
  SettingsBranding = 'settings-branding',
  SettingsSecurity = 'settings-security',
  SettingsWebhooks = 'settings-webhooks',
  SettingsBeta = 'settings-beta',
  DocumentSettings = 'document-settings',
  Template = 'template',
  Templates = 'templates',
  ProductLibrary = 'products',
  NotFound = 'not-found',
  SetupWizard = 'setup-wizard',
  CommunicationTemplates = 'communication-templates',
  CommunicationTemplatesEdit = 'communication-templates-edit',
  Rooms = 'rooms',
  ManageUsers = 'manage-users',
  ManageUsersAddSeats = 'manage-users-add-seats',
  EditUser = 'edit-user',
  InviteUser = 'invite-user',
  InactiveUser = 'inactive-user',
  Modules = 'modules',
  Intro = 'intro',
  InactiveSubscription = 'inactive-subscription',
  MyDocumentsRedirect = 'my-documents-redirect',
  DrTemplate = 'dr-template',
  DrResource = 'dr-resource',
  DrPublished = 'dr-published',
  DrPublishedOld = 'dr-published-old',
}
