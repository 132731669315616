export enum Feature {
  Archive = 'archive',
  BaseBranding = 'base-branding',
  CommunicationTemplates = 'communication-templates',
  ContextualCommenting = 'contextual-commenting',
  ContractManagement = 'contract-management',
  Dealboard = 'dealboard',
  DigitalCertificate = 'digital-certificate',
  DocumentAttachments = 'document-attachments',
  ElectronicIdentification = 'electronic-identification',
  ExtendedBranding = 'extended-branding',
  FullBranding = 'full-branding',
  FullTracking = 'full-tracking',
  GlobalTemplateLibrary = 'global-template-library',
  IdentifyNewRecipients = 'identify-new-recipients',
  LinkBlock = 'link-block',
  NonSignableDocuments = 'non-signable-documents',
  ProductLibrary = 'product-library',
  QES = 'qes',
  RecipientAttachments = 'recipient-attachments',
  RecipientLimit = 'recipient-limit',
  Reports = 'reports',
  SigningOrder = 'signing-order',
  Sms = 'sms',
  Tags = 'tags',
  TransferDocumentNotifications = 'transfer-document-notifications',
  UniversalLink = 'universal-link',
  VideoBlock = 'video-block',
  Webhooks = 'webhooks',
  RestrictRoomAccess = 'restrict-room-access',
  ContractroomPurchase = 'contractroom-purchase',
  DealroomPurchase = 'dealroom-purchase',
  RoomLimit = 'room-limit',
  FileSizeLimit = 'file-size-limit',
  ExtendedElements = 'extended-elements',
  CustomMergeTags = 'custom-merge-tags',
  CustomColumns = 'custom-columns',
  MyDocuments = 'my-documents',
  CrmIntegrations = 'crm-integrations',
  MarketingIntegrations = 'marketing-integrations',
  PaymentIntegrations = 'payment-integrations',
  WorkflowIntegrations = 'workflow-integrations',
  EngageIntegrations = 'engage-integrations',
  ProvisioningSsoIntegrations = 'provisioning-sso-integrations',
  OtherIntegrations = 'other-integrations',
  ExternalApiAccess = 'external-api-access',
  CrmSalesforce = 'crm-salesforce',
  CrmDynamics = 'crm-dynamics',

  //Legacy restrictions
  DisableComments = 'disable-comments',
  EmailCopyOfNonPrivateSignedDocs = 'email-copy-of-non-private-signed-docs',
  EmailSenderAddressOverride = 'email-sender-address-override',
  EmailSenderNameOverride = 'email-sender-name',
  FingerprintOffset = 'fingerprint-offset',
  Teams = 'teams',
  UniqueDocumentId = 'unique-document-id',
}
