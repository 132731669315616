import { ref } from 'vue';
import { FeatureRestrictionHelper, useFeatureStore } from '@getaccept/feature-restriction-new';
import { storeToRefs } from 'pinia';
import type { SubscriptionPlan } from '@getaccept/lib-shared-new/src/enums/subscription-plan';
import { useLaunchDarkly } from '@getaccept/lib-shared-new/src/launch-darkly/launch-darkly.composable';
import { until } from '@vueuse/core';
import { FeaturesService } from '../../api/features/services/features.service';
import { useEntityStore } from '../../entity/store/entity.store';

export function useLoadFeatures() {
  const loading = ref(false);

  const { entity } = storeToRefs(useEntityStore());
  const { flags, ready: launchDarklyReady } = useLaunchDarkly();
  const featureStore = useFeatureStore();

  const loadFeatures = async () => {
    loading.value = true;
    await until(launchDarklyReady).toBe(true);

    try {
      const featureMap = flags.value.newSubscription
        ? FeatureRestrictionHelper.getFeatureStatusMap(
            entity.value.plan.toLowerCase() as SubscriptionPlan,
            await FeaturesService.load()
          )
        : FeatureRestrictionHelper.getLegacyFeatureStatusMap(
            entity.value.plan.toLowerCase() as SubscriptionPlan,
            []
          );

      featureStore.setFeatures(featureMap);
    } catch (e) {
      console.error('Could not load features: ', e);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadFeatures,
    loading,
  };
}
