<template>
  <FriedModalSubContainer :loading="false">
    <template #header>
      <FriedModalHeader :header="$t('authenticate-to-access-document')" />
    </template>
    <div class="sms-body">
      {{ description }}
      <div v-if="hasSentPin" class="pin-container">
        <div class="pin-header">{{ $t('sms-code') }}</div>
        <Pin
          data-external="sms-auth-open-pin-code-input"
          :pin-length="pinLength"
          @pin="handlePin"
        />
        <div v-if="wrongPin" class="error" data-external="sms-on-open-wrong-code-msg">
          {{ $t('wrong-code-please-try-again-or-contact-the-sender') }}
        </div>
        <template v-else>
          {{ $t('code-has-been-sent-did-not-receive-a-code') }}
        </template>
      </div>
      <div v-else class="code-info">{{ infoText }}</div>
    </div>
    <template #footer>
      <FriedModalButtonsFooter>
        <FriedButton
          v-if="hasSentPin"
          data-external="sms-on-open-validate-pin-btn"
          :loading="submittingResponse"
          :disabled="disableValidate"
          @click="handleAnswer"
        >
          {{ $t('validate') }}
        </FriedButton>
        <FriedButton v-else data-external="sms-on-open-sendPin-btn" @click="sendPin">
          {{ $t('get-pin-code') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModalSubContainer>
</template>

<script lang="ts">
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { PropType } from 'vue';
import { onBeforeUnmount, onMounted, computed, ref, defineComponent } from 'vue';
import Pin from '@getaccept/lib-shared-new/src/components/Pin.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    Pin,
  },
  props: {
    authStep: {
      type: Object as PropType<AuthenticationStep>,
      required: true,
    },
    isCorrectPin: {
      type: Boolean,
      default: false,
    },
    submittingResponse: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['answer', 'sendPin'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const pin = ref('');
    const hasSentPin = ref(false);
    const pinLength = 6;

    const description = computed(
      (): string => `${t('sms-auth-description')} ${lastFourDigits.value}.`
    );
    const infoText = computed((): string => `${t('sms-info-text')} ${lastFourDigits.value}.`);
    const lastFourDigits = computed((): string => props.authStep.data.mobile);
    const disableValidate = computed((): boolean => pin.value.length !== pinLength);
    const wrongPin = computed((): boolean => props.isCorrectPin === false);
    const codeSent = computed((): boolean => hasSentPin.value && !wrongPin.value);

    const handlePin = (value: string) => {
      pin.value = value;
    };

    const sendPin = () => {
      hasSentPin.value = true;
      emit('sendPin');
    };

    const handleAnswer = () => {
      emit('answer', { type: AuthenticationStepType.VerifySms, value: pin.value });
    };

    const bindEnter = ({ key }: KeyboardEvent) => {
      if (key === 'Enter') {
        handleAnswer();
      }
    };

    onMounted(() => {
      document.addEventListener('keydown', bindEnter);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', bindEnter);
    });

    return {
      pin,
      hasSentPin,
      pinLength,
      description,
      infoText,
      lastFourDigits,
      disableValidate,
      wrongPin,
      codeSent,
      handlePin,
      sendPin,
      handleAnswer,
      bindEnter,
    };
  },
});
</script>

<style lang="scss" scoped>
.sms-body {
  .code-info {
    margin: var(--spacing-150);
    background-color: var(--gray-96);
    padding: var(--spacing-50) var(--spacing-150);
    border-radius: var(--radius-medium);
    text-align: center;
    font-weight: 600;
  }

  .pin-header {
    margin-top: var(--spacing-150);
    color: var(--text-gray);
  }

  .error {
    color: var(--error-dark);
  }
}
</style>
