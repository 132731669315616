import gql from 'graphql-tag';

export class ReportEmailMutations {
  static unsubscribeEmailDocument = gql`
    mutation UnsubscribeEmailDocument($payload: UnsubscribeEmailDocumentInput!) {
      unsubscribeEmailDocument(payload: $payload)
    }
  `;

  static unsubscribeEmailDealroom = gql`
    mutation UnsubscribeEmailDealroom($payload: UnsubscribeEmailDealroomInput!) {
      unsubscribeEmailDealroom(payload: $payload)
    }
  `;
}
