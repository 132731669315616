<template>
  <IdentifyWrapper :logo-url="logoUrl">
    <RequestAccess
      v-if="isPrivateRoomError && !privateFormIsVisible"
      @try-again="privateFormIsVisible = true"
    />
    <UniversalForm
      v-else-if="isPublicRoomError && !privateFormIsVisible"
      :consents="storedConsents"
      :email="email"
      :can-go-back="true"
      @try-again="privateFormIsVisible = true"
    />
    <PrivateForm
      v-else-if="isIdentifyStep"
      :disabled="hasUnansweredConsents"
      :email="email"
      @submit="handleSubmit"
    />
    <Consents v-if="hasUnansweredConsents" :consent-steps="consentSteps" @answer="handleAnswer" />
  </IdentifyWrapper>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { useIdentifyRecipient } from '@getaccept/lib-shared-new/src/lobby/composables/identify-recipient.composable';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import Consents from './Consents.vue';
import IdentifyWrapper from './IdentifyWrapper.vue';
import RequestAccess from './RequestAccess.vue';
import UniversalForm from './UniversalForm.vue';
import PrivateForm from './PrivateForm.vue';

export default defineComponent({
  components: {
    Consents,
    IdentifyWrapper,
    RequestAccess,
    UniversalForm,
    PrivateForm,
  },
  props: {
    authStep: {
      type: Object as PropType<AuthenticationStep>,
      required: true,
    },
    submittingResponse: {
      type: Boolean,
    },
    consentSteps: {
      default: () => [],
      type: Array as PropType<AuthenticationStep[]>,
    },
    logoUrl: {
      type: String,
      default: '',
    },
  },
  emits: ['consent-answer'],
  setup(props, { emit }) {
    const { email, handleClick, universalLinkUrl, notMatchingEmail } = useIdentifyRecipient(
      props,
      emit
    );

    const privateFormIsVisible = ref(true);
    const showConsents = ref(true);
    const isPrivateRoomError = computed(() => !universalLinkUrl.value && notMatchingEmail.value);
    const isPublicRoomError = computed(() => universalLinkUrl.value && notMatchingEmail.value);
    const isIdentifyStep = computed(
      () => props.authStep.type === AuthenticationStepType.IdentifyRecipient
    );

    const hasUnansweredConsents = computed(
      () => showConsents.value && props.consentSteps.length > 0
    );

    const handleSubmit = (updatedEmail: string) => {
      if (props.authStep.data?.error && email.value === updatedEmail) {
        privateFormIsVisible.value = false;
        return;
      }

      email.value = updatedEmail;
      const url = new URL(window.location.href);
      url.searchParams.set('email', updatedEmail);
      window.history.replaceState({}, '', url.toString());
      handleClick();
    };

    const storedConsents = ref<{ type: AuthenticationStepType; value: string }[]>([]);

    const handleAnswer = (
      consents: {
        type: AuthenticationStepType;
        value: string;
      }[]
    ) => {
      showConsents.value = false;
      storedConsents.value = consents;
      emit('consent-answer', consents);
    };

    watch(
      () => props.authStep,
      () => {
        if (props.authStep.data?.error) {
          privateFormIsVisible.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    return {
      email,
      handleClick,
      handleAnswer,
      showConsents,
      hasUnansweredConsents,
      isPublicRoomError,
      isPrivateRoomError,
      privateFormIsVisible,
      handleSubmit,
      storedConsents,
      isIdentifyStep,
    };
  },
});
</script>
