import { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import { RecipientRole } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-role';
import type { Field } from '@getaccept/lib-shared-new/src/fields/types/field';
import { FieldType } from '@getaccept/lib-shared-new/src/fields/types/enums/field-type';
import { SignatureConstants } from '@getaccept/lib-shared-new/src/signature/constants/signature.constants';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { HintCodeError } from '../types/enums/bankid-se';
import type { BankIdSigningType, BankIdStep } from '../types/enums/bankid-se';
import type { BankId } from '../types/bankid';
import { MergeFieldHelper } from '../../fields/helpers/merge-field.helper';
import type { EidType } from '../types/enums/eid-type';
import type { CriiptoStatus } from '../types/enums/criipto-status';
import type { SignError } from '../types/sign';

export class SigningHelper {
  static isSigningTurnNoSigningOrder({ role, status }: SigningPageRecipient): boolean {
    switch (role) {
      case RecipientRole.Approver:
      case RecipientRole.InternalApproverAPIFallback:
      case RecipientRole.ExternalApprover:
        return status !== RecipientStatus.Approved;

      case RecipientRole.Signer:
        return status !== RecipientStatus.Signed;

      default:
        return false;
    }
  }

  static isSigningTurnSigningOrder(
    currentRecipientId: string,
    recipientsNextInLine: SigningPageRecipient[]
  ): boolean {
    if (!recipientsNextInLine || !recipientsNextInLine.length) {
      return false;
    }

    return recipientsNextInLine
      .map(({ id }: SigningPageRecipient) => id)
      .includes(currentRecipientId);
  }

  public static isChromeiOS(): boolean {
    return !!navigator.userAgent.match('CriOS');
  }

  public static isFirefoxiOS(): boolean {
    return !!navigator.userAgent.match('FxiOS');
  }

  static getBankIDiOSRedirectUrl(bankId: BankId, jwt: string): string {
    if (this.isChromeiOS()) {
      return 'googlechromes://';
    } else if (this.isFirefoxiOS()) {
      return 'firefox://';
    }
    const { step, orderRef, signingType, autoStartToken } = bankId;
    return `${document.location.href}?token=${jwt}&step=${step}&orderRef=${orderRef}&signingType=${signingType}&autoStartToken=${autoStartToken}`;
  }

  static hasValidBankIdParams(
    orderRef: string,
    step: BankIdStep,
    signingType: BankIdSigningType,
    autoStartToken: string
  ): boolean {
    return orderRef && step && signingType && !!autoStartToken;
  }

  static hasValidCriiptoParams(selectedEid: EidType, status: CriiptoStatus): boolean {
    return !!status && !!selectedEid;
  }

  static getBankIdHintCodeTranslation(hintCode: HintCodeError): string {
    switch (hintCode) {
      case HintCodeError.cancelled:
        return t('bankid-order-cancelled'); //RFA3
      case HintCodeError.certificateErr:
        return t('bankid-order-revoked'); //RFA16
      case HintCodeError.expiredTransaction:
        return t('bankid-order-expired'); //RFA8
      case HintCodeError.startFailed:
        return t('bankid-order-qr-error'); //RFA17
      case HintCodeError.userCancel:
        return t('bankid-order-user-cancelled'); //RFA6
      case HintCodeError.networkError:
        return t('bankid-network-error');
      default:
        return t('bankid-order-unknown-error'); //RFA22
    }
  }

  static hasScrolledToBottom(
    scrollPosition: number,
    windowHeight: number,
    documentHeight: number
  ): boolean {
    return scrollPosition + windowHeight >= documentHeight;
  }

  static isFullDocumentHeightVisible(windowHeight: number, documentHeight: number): boolean {
    return windowHeight >= documentHeight;
  }

  static mappedFields(field: Field, document: SigningPageDocument, entity: Entity): Field {
    switch (field.type) {
      case FieldType.Merge:
        return MergeFieldHelper.mapMergeField(field, document, entity);
      case FieldType.Text:
      case FieldType.Formatted:
        return MergeFieldHelper.mapTextField(field, document, entity);
      default:
        return field;
    }
  }

  static isSignedByRecipient(recipients: SigningPageRecipient[], recipientId: string): boolean {
    return (
      recipients.find((recipient: SigningPageRecipient) => recipient.id === recipientId)?.status ===
        RecipientStatus.Signed || false
    );
  }

  static validateInitials(initials: string): string {
    if (!initials) {
      return t('initials-required');
    } else if (initials.length > SignatureConstants.initialsMaxLength) {
      return t('initials-too-long');
    } else if (!SignatureConstants.initialsValidationRegex.test(initials)) {
      return t('initials-can-only-contain');
    } else {
      return '';
    }
  }

  static isPartiallySigned(recipients: SigningPageRecipient[]): boolean {
    return recipients.some(
      recipient =>
        (recipient.role === RecipientRole.Signer ||
          recipient.role === RecipientRole.ExternalApprover) &&
        (recipient.status === RecipientStatus.Signed ||
          recipient.status === RecipientStatus.Approved)
    );
  }

  static getSignErrorMessage(signError: SignError): { title: string; description: string } {
    return signError === 'ssn_mismatch'
      ? {
          title: t('cr_ssn_mismatch_error_title'),
          description: t('cr_ssn_mismatch_error_description'),
        }
      : {
          title: t('something-went-wrong'),
          description: t('cr_error_msg_try_again_or_contact_sender'),
        };
  }
}
