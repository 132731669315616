import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import { DocumentType } from '@getaccept/lib-shared-new/src/enums/document-type';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { DateTime } from 'luxon';

export class DocumentHelper {
  static getRecipientById(recipientId: string, recipients: Recipient[]): Recipient {
    return recipients.find((recipient: Recipient) => recipient.id === recipientId);
  }

  static getIsDocumentExpired(document: SigningPageDocument): boolean {
    if (!document) {
      return false;
    }
    if (document.status === DocumentStatus.Signed) {
      return false;
    }
    if (document.type === DocumentType.Template) {
      return false;
    }

    return DateTime.fromISO(document.expirationDate) < DateTime.now();
  }
}
