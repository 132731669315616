import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import type { DealroomRevision } from '../../api/dsr/types/dealroom-revision';
import { DsrService } from '../../api/dsr/service/dsr.service';

export const useRevisionsStore = defineStore('revisions', () => {
  const revisions: Ref<DealroomRevision[]> = ref([]);
  const loadingRevisions = ref(false);

  const loadRevisions = async () => {
    loadingRevisions.value = true;

    try {
      revisions.value = await DsrService.loadRevisions();
    } catch (e) {
      loadingRevisions.value = false;
      revisions.value = [];
    } finally {
      loadingRevisions.value = false;
    }
  };

  const setViewedLoading = ref(false);

  const setRevisionViewed = async () => {
    if (!latestRevision.value || latestRevision.value?.viewedByParticipant) {
      return;
    }

    setViewedLoading.value = true;

    try {
      await DsrService.setRevisionViewed(latestRevision.value.version);
    } catch (e) {
      setViewedLoading.value = false;
      console.error(e);
    } finally {
      setViewedLoading.value = false;
    }
  };

  const revisionsLoading = computed(() => loadingRevisions.value || setViewedLoading.value);
  const latestRevision = computed(() => [...revisions.value].reverse()[0]);

  const latestViewedRevision = computed(() =>
    [...revisions.value].reverse().find(revision => revision.viewedByParticipant)
  );
  const version = computed(() => latestRevision.value?.version);

  const showRevisionPromptBar = ref(false);
  const waitingToLoadUpdatedContent = ref(false);

  const handlePusherEvent = (timerDuration = 20000) => {
    loadRevisions();
    showRevisionPromptBar.value = true;
    startForcedReviewTimer(timerDuration);
    waitingToLoadUpdatedContent.value = true;

    setTimeout(() => {
      if (waitingToLoadUpdatedContent.value) {
        loadUpdatedContent();
      }
    }, timerDuration);
  };

  const forcedReviewTimer = ref(20);

  const startForcedReviewTimer = (duration: number) => {
    let timeLeft = duration / 1000;
    const timer = setInterval(() => {
      if (timeLeft === 0) {
        clearInterval(timer);
      } else {
        timeLeft -= 1;
      }
      forcedReviewTimer.value = timeLeft;
    }, 1000);
  };

  const loadNewContent = ref(false);

  const loadUpdatedContent = () => {
    waitingToLoadUpdatedContent.value = false;
    showRevisionPromptBar.value = false;
    loadNewContent.value = true;
  };

  return {
    setRevisionViewed,
    loadRevisions,
    latestViewedRevision,
    revisionsLoading,
    waitingToLoadUpdatedContent,
    loadUpdatedContent,
    showRevisionPromptBar,
    forcedReviewTimer,
    loadNewContent,
    revisions,
    version,
    handlePusherEvent,
    latestRevision,
  };
});
