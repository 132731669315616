import gql from 'graphql-tag';

export class DocumentAttachmentQueries {
  static load = gql`
    query Document {
      document {
        id
        documentAttachments {
          id
          requireView
          previewUrl
          lastView
          lastUpload
          attachment {
            id
            title
            description
            type
            filename
          }
        }
      }
    }
  `;
}
