import type { DocumentQesFile } from '@getaccept/lib-shared-new/src/qes/types/document-qes-file';
import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import { apolloClient } from '../../../apollo';
import { QesQueries } from '../graphql/qes.queries';

export class QesService {
  static async getQesFiles(documentId: string): Promise<DocumentQesFile[]> {
    const {
      data: { getDocumentQesFiles },
    } = await apolloClient.query({
      query: QesQueries.getDocumentQesFiles,
      variables: { documentId },
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return getDocumentQesFiles.files;
  }
  static async getFileDownloadLink(documentId: string, fileId: string): Promise<string> {
    const {
      data: { documentQesFileDownloadLink },
    } = await apolloClient.query({
      query: QesQueries.getFileDownloadLink,
      variables: { documentId, fileId },
    });
    return documentQesFileDownloadLink.link;
  }
}
