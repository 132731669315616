import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { DsrService } from '../api/dsr/service/dsr.service';
import { useEntityStore } from '../entity/store/entity.store';

export function useData() {
  const loadingParticipantInput: Ref<boolean> = ref(false);
  const loadedParticipantInput: Ref<boolean> = ref(false);
  const loadParticipantInputFailed: Ref<boolean> = ref(false);
  const participantInput: Ref<EditorRecipientInput[]> = ref([]);

  const loadParticipantInput = async () => {
    loadParticipantInputFailed.value = false;
    loadingParticipantInput.value = true;
    try {
      participantInput.value = await DsrService.loadParticipantInput();
      loadedParticipantInput.value = true;
    } catch (e) {
      participantInput.value = [];
      loadParticipantInputFailed.value = true;
      console.error('Could not load participant input', e);
    } finally {
      loadingParticipantInput.value = false;
    }
  };

  const updateParticipantInput = (input: EditorRecipientInput[]) => {
    participantInput.value = input;
  };

  const loadingEntity: Ref<boolean> = ref(false);
  const loadedEntity: Ref<boolean> = ref(false);
  const loadEntityFailed: Ref<boolean> = ref(false);

  const { setEntity } = useEntityStore();

  const loadEntity = async () => {
    loadEntityFailed.value = false;
    loadingEntity.value = true;
    try {
      const entity = await DsrService.loadEntity();
      setEntity(entity);
      loadedEntity.value = true;
    } catch (e) {
      loadEntityFailed.value = true;
      console.error('Could not load entity', e);
    } finally {
      loadingEntity.value = false;
    }
  };

  const loaded: ComputedRef<boolean> = computed(
    () => loadedEntity.value && loadedParticipantInput.value
  );
  const loading: ComputedRef<boolean> = computed(
    () => loadingEntity.value && loadingParticipantInput.value
  );
  const error: ComputedRef<boolean> = computed(
    () => loadEntityFailed.value && loadParticipantInputFailed.value
  );

  return {
    participantInput,
    loaded,
    loading,
    error,
    loadParticipantInput,
    loadEntity,
    updateParticipantInput,

    loadedEntity,
  };
}
