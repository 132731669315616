import gql from 'graphql-tag';
import { videoFragment } from '../../video/graphql/video.fragment';
import { DocumentFragments } from './document.fragments';

export const documentQuery = gql`
  query Document {
    document {
      expirationDate
      id
      isAutoComment
      isSigning
      isSigningOrder
      isVideo
      isSigningInitials
      name
      status
      type
      value
      sendDate
      signDate
      nextRecipientIdToSign
      nextRecipientsInLine {
        ...signingDocumentsRecipientFragment
      }
      uniqueId
      documentAttachments {
        id
        requireView
        previewUrl
        lastView
        lastUpload
        createdAt
        attachment {
          id
          title
          description
          type
          url
          filename
        }
      }
      user {
        ...signingUserFragment
      }
      recipients {
        ...signingDocumentsRecipientFragment
      }
      pages {
        ...pageFragment
      }
      manuallySignedPages {
        ...signaturePageFragment
      }
      video {
        ...videoFragment
      }
      documentVideos {
        ...videoFragment
      }
      activeTwilioIntegration
      isSigningForward
      documentRevisions {
        message
        revision
        status
        createdAt
        viewedByRecipient
      }
      settings {
        qualifiedElectronicSignature
      }
    }
  }
  ${DocumentFragments.signingUserFragment}
  ${DocumentFragments.signingDocumentsRecipientFragment}
  ${DocumentFragments.pageFragment}
  ${DocumentFragments.signaturePageFragment}
  ${videoFragment}
`;

export const documentRevisionsQuery = gql`
  query Document {
    document {
      documentRevisions {
        message
        revision
        status
        createdAt
        viewedByRecipient
      }
    }
  }
`;

export const getDownloadUrlQuery = gql`
  query getDownloadUrl {
    getDownloadUrl
  }
`;

export const recipientsQuery = gql`
  query Document {
    document {
      id
      recipients {
        ...signingDocumentsRecipientFragment
      }
    }
  }
  ${DocumentFragments.signingDocumentsRecipientFragment}
`;

export const getRecipientsSSNQuery = gql`
  query getRecipientsSSN {
    getRecipientsSSN {
      ssn
      recipientId
    }
  }
`;

export const partiallySignedEventQuery = gql`
  query partiallySignedEvent {
    partiallySignedEvent {
      userId
      recipientId
      tsaTime
      eventName
      eventMetaData
    }
  }
`;

export const externalId = gql`
  query ExternalId {
    document {
      id
      externalId
    }
  }
`;

export const isDocumentViewable = gql`
  query DocumentViewable {
    documentViewable
  }
`;
