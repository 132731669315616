import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
import { useToast } from '@getaccept/lib-shared-new/src/toast';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useStorage, until } from '@vueuse/core';
import { defineStore } from 'pinia';
import { DateTime } from 'luxon';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { LocalStorageSubKey } from '../../local-storage/type/subkey';
import { useRootStore } from '../../store/root.store';
import { DocumentService } from '../../api/documents/services/document.service';
import { BankIdSeService } from '../../api/signing/services/bankid-se.service';
import { SignatureService } from '../../api/signing/services/signature.service';
import { useDocumentStore } from '../../documents/store/document.store';
import { LocalStorageHelper } from '../../local-storage/helpers/local-storage.helper';
import { EidHelper } from '../helpers/eid.helper';
import { SigningHelper } from '../helpers/signing.helper';
import type { BankId } from '../types/bankid';
import type { Id } from '../types/eid';
import {
  BankIdSeStatus,
  BankIdSigningType,
  BankIdStep,
  HintCodeError,
  HintCodePending,
} from '../types/enums/bankid-se';
import { CriiptoStatus } from '../types/enums/criipto-status';
import { SigningStep as ESigningStep } from '../types/enums/signing-step';
import type { Qna } from '../types/qna';
import type { Sign } from '../types/sign';
import type { SignResponse } from '../types/sign-response';
import type { Signature } from '../types/signature';
import type { SigningStep } from '../types/signing-step';
import type { Sms } from '../types/sms';
import { useResumeEidSigning } from '../electronic-identification/composables/resume-eid-signing.composable';
import { SigningStoreConstants } from './signing.store.constants';

export const useSigningStore = defineStore('signing', () => {
  const bankId: Ref<BankId> = ref(SigningStoreConstants.bankId());
  const documentStore = useDocumentStore();
  const rootStore = useRootStore();
  const bankIdTransactionStartTime: Ref<DateTime> = ref(null);
  const { resetQueryParams } = useResumeEidSigning();

  const setBankIdState = (step: BankIdStep, signingType: BankIdSigningType) => {
    bankId.value = { ...bankId.value, step, signingType };
  };

  const pollBankIdTransactionSuccess = () => {
    bankId.value = { ...bankId.value, step: BankIdStep.Signed, hintCode: undefined };
    setTimeout(() => {
      bankId.value.step = BankIdStep.Completed;
    }, 5000);
  };

  const checkSignedDocument = async () => {
    try {
      const recipients = await DocumentService.getRecipients();
      const rootStore = useRootStore();
      if (SigningHelper.isSignedByRecipient(recipients, rootStore.recipientId)) {
        pollBankIdTransactionSuccess();
      } else {
        setError(SigningHelper.getBankIdHintCodeTranslation(HintCodeError.unknownError));
      }
    } catch (error) {
      if (bugsnagClient) {
        bugsnagClient.notify(error);
      }
      const errorCode: HintCodeError = error.response?.status
        ? HintCodeError.unknownError
        : HintCodeError.networkError;

      setError(SigningHelper.getBankIdHintCodeTranslation(errorCode));
    }
  };

  const startBankIdPolling = () => {
    bankId.value.step = BankIdStep.Polling;
    pollBankIdTransaction();
  };

  const pollBankIdTransaction = async () => {
    if (!bankId.value.orderRef || bankId.value.step !== BankIdStep.Polling) {
      return;
    }

    if (bugsnagClient) {
      bugsnagClient.leaveBreadcrumb('pollBankIdTransaction');
    }

    try {
      const { status, hintCode } = await BankIdSeService.poll(
        bankId.value.orderRef,
        documentStore.document.id,
        rootStore.recipientId
      );
      // Check if transaction has been cancelled before continuing
      if (!bankId.value.orderRef) {
        return;
      }
      bankId.value.hintCode = hintCode;
      switch (status) {
        case BankIdSeStatus.Pending:
          if (EidHelper.getElapsedSeconds(bankIdTransactionStartTime.value) < 180) {
            setTimeout(() => {
              pollBankIdTransaction();
            }, 1000);
          } else {
            setError(t('bankid-order-expired'));
          }
          break;
        case BankIdSeStatus.Complete:
          pollBankIdTransactionSuccess();
          break;
        case BankIdSeStatus.Failed:
          if (
            [HintCodeError.startFailed, HintCodeError.noClient].includes(hintCode as HintCodeError)
          ) {
            startBankIdTransaction();
          } else {
            checkSignedDocument();
          }
          break;
      }
    } catch (error) {
      if (bugsnagClient) {
        bugsnagClient.notify(error);
      }

      if (bankId.value.orderRef) {
        checkSignedDocument();
      }
    }
  };

  const isTransactionOutstanding = computed(
    () =>
      bankId.value.step === BankIdStep.Polling &&
      bankId.value.hintCode === HintCodePending.outstandingTransaction
  );

  const requestNewQRCode = async () => {
    if (!isTransactionOutstanding.value) {
      return;
    }

    try {
      const { qrCode } = await BankIdSeService.reset(bankId.value.orderRef);
      bankId.value.qrCode = qrCode;

      if (EidHelper.getElapsedSeconds(bankIdTransactionStartTime.value) > 28) {
        setError(t('bankid-order-expired'));
        return;
      }

      setTimeout(requestNewQRCode, 1000);
    } catch (error) {
      if (error.response?.data === 'QR code expired') {
        setError(t('bankid-order-expired'));
        return;
      }
      console.error(error);
    }
  };

  const startBankIdQRMethod = () => {
    bankId.value.signingType = BankIdSigningType.QR;
    startBankIdTransaction();
  };

  const startBankIdSameDeviceMethod = () => {
    bankId.value.signingType = BankIdSigningType.SameDevice;
    startBankIdTransaction();
  };

  const startBankIdTransaction = async () => {
    bankId.value.isLoading = true;
    if (bugsnagClient) {
      bugsnagClient.leaveBreadcrumb('startBankIdTransaction');
    }

    try {
      const { orderRef, autoStartToken, qrCode } = await BankIdSeService.start(
        rootStore.documentId,
        rootStore.recipientId
      );

      bankIdTransactionStartTime.value = DateTime.now();

      bankId.value = {
        ...bankId.value,
        orderRef,
        autoStartToken,
        isLoading: false,
        qrCode: `bankid:///${qrCode}`,
      };

      startBankIdPolling();

      if (bankId.value.signingType === BankIdSigningType.QR) {
        await until(isTransactionOutstanding).toBe(true);
        requestNewQRCode();
      }
    } catch (error) {
      if (bugsnagClient) {
        bugsnagClient.notify(error);
      }
      const errorCode: HintCodeError = error.response?.status
        ? HintCodeError.unknownError
        : HintCodeError.networkError;
      setError(SigningHelper.getBankIdHintCodeTranslation(errorCode));
    }
  };

  const cancelBankIdTransaction = async (orderRef: string) => {
    if (orderRef) {
      try {
        await BankIdSeService.cancel(orderRef);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const setError = (error: string) => {
    bankId.value = {
      ...SigningStoreConstants.bankId(),
      error,
      step: BankIdStep.Failed,
    };
  };

  const resetBankId = async () => {
    const { orderRef } = bankId.value;
    const shouldCancelTransaction = ![BankIdStep.Completed, BankIdStep.Signed].includes(
      bankId.value.step
    );

    bankId.value = { ...SigningStoreConstants.bankId() };

    if (shouldCancelTransaction) {
      cancelBankIdTransaction(orderRef);
    }
  };

  const signDocumentSuccess = (signeesWaiting: number) => {
    const rootStore = useRootStore();
    const documentStore = useDocumentStore();
    documentStore.completeSigning(signeesWaiting, rootStore.recipientId);
  };

  const sign: Ref<Sign> = ref(SigningStoreConstants.sign());
  const initials: Ref<string> = useStorage(
    LocalStorageHelper.buildKey(window.location.href, LocalStorageSubKey.Initials),
    ''
  );
  const signDocument = async (signInitials = '') => {
    const rootStore = useRootStore();
    sign.value.isVerifying = true;
    sign.value.error = null;
    const documentStore = useDocumentStore();
    try {
      const { status, description, signeesWaiting }: SignResponse = await SignatureService.sign(
        initials.value || signInitials,
        documentStore.document.id,
        rootStore.recipientId,
        rootStore.authId
      );

      if (status === 1) {
        signDocumentSuccess(signeesWaiting);
        return;
      }

      if (status === -1 && description.includes('SSN from eID does not match')) {
        sign.value.error = 'ssn_mismatch';
        criiptoStatus.value = CriiptoStatus.Default;
        resetQueryParams();
      }
    } catch (e) {
      console.error(e);
    } finally {
      sign.value.isVerifying = false;
    }
  };

  const loading = ref(false);
  const criiptoStatus: Ref<CriiptoStatus> = ref(CriiptoStatus.Default);
  const step: Ref<SigningStep> = ref(SigningStoreConstants.step());

  const checkVerificationStep = async () => {
    loading.value = true;
    const rootStore = useRootStore();

    const documentStore = useDocumentStore();
    try {
      const stepResponse: SigningStep = await SignatureService.checkVerificationStep(
        rootStore.recipientId,
        documentStore.document.id,
        rootStore.authId
      );

      const shouldSign: boolean =
        stepResponse.type === ESigningStep.Click &&
        !documentStore.document.isSigningInitials &&
        criiptoStatus.value === CriiptoStatus.Success;

      if (shouldSign) {
        await signDocument();
      } else {
        loading.value = false;
        step.value = stepResponse;
      }
    } catch (e) {
      loading.value = false;
    }
  };

  const setBankIdQueryParams = (
    orderRef: string,
    stepPayload: BankIdStep,
    signingType: BankIdSigningType,
    autoStartToken: string
  ) => {
    step.value.type = ESigningStep.Eid;
    bankId.value = {
      ...bankId.value,
      orderRef,
      autoStartToken,
      signingType,
      step: stepPayload,
    };
  };

  const selectedEid: Ref<Id> = ref(undefined);

  const setCriiptoQueryParams = (selectedEidPayload: Id, status: CriiptoStatus) => {
    step.value.type = ESigningStep.Eid;
    selectedEid.value = selectedEidPayload;
    criiptoStatus.value = status;
  };

  const redirectCriipto = () => {
    const rootStore = useRootStore();
    EidHelper.criiptoRedirect(
      selectedEid.value?.key,
      rootStore.documentId,
      rootStore.recipientId,
      rootStore.authId
    );
  };

  const signature: Ref<Signature> = ref(SigningStoreConstants.signature());

  const verifySignatureFailed = () => {
    signature.value.isVerifying = false;
    useToast().toast.danger(t('could-not-verify-signature'));
  };

  const timeout = 2000;
  const verifySignatureSuccess = () => {
    setTimeout(() => {
      checkVerificationStep();
      signature.value.isVerifying = false;
    }, timeout);
  };

  const verifySignature = async (signatureBase30: string, signatureBase64: string) => {
    signature.value.isVerifying = true;
    if (!signatureBase30 || !signatureBase64) {
      verifySignatureFailed();
      return;
    }
    const rootStore = useRootStore();
    const documentStore = useDocumentStore();
    try {
      const base64Verified: boolean = await SignatureService.verifySignatureBase64(signatureBase64);
      if (!base64Verified) {
        verifySignatureFailed();
        return;
      }
      const base30Verified: boolean = await SignatureService.verifySignatureBase30(
        rootStore.recipientId,
        documentStore.document.id,
        rootStore.authId,
        signatureBase30
      );
      if (!base30Verified) {
        verifySignatureFailed();
        return;
      }
      verifySignatureSuccess();
    } catch (error) {
      verifySignatureFailed();
    }
  };

  const qna: Ref<Qna> = ref(SigningStoreConstants.qna());

  const verifyQnaSuccess = () => {
    qna.value.isVerifying = false;
    qna.value.error = false;
    checkVerificationStep();
  };

  const verifyQna = async (answer: string) => {
    qna.value.isVerifying = true;
    const rootStore = useRootStore();
    const documentStore = useDocumentStore();
    try {
      const success: boolean = await SignatureService.verifyQna(
        answer,
        documentStore.document.id,
        rootStore.recipientId,
        rootStore.authId
      );
      if (success) {
        verifyQnaSuccess();
      } else {
        qna.value.error = true;
      }
    } catch (e) {
      qna.value.error = true;
    } finally {
      qna.value.isVerifying = false;
    }
  };

  const sms: Ref<Sms> = ref(SigningStoreConstants.sms());

  const sendSmsCode = async () => {
    sms.value.error = false;
    sms.value.isVerifying = false;
    const rootStore = useRootStore();
    const documentStore = useDocumentStore();
    try {
      await SignatureService.sendSmsCode(
        documentStore.document.id,
        rootStore.recipientId,
        rootStore.authId,
        sms.value.sendPinRetryCount
      );
      sms.value.sendPinRetryCount++;
    } catch (e) {
      useToast().toast.danger(t('could-not-send-sms-code'));
    }
  };

  const verifySmsSuccess = () => {
    sms.value.error = false;
    checkVerificationStep();
  };

  const verifySms = async (pin: string) => {
    sms.value.isVerifying = true;
    const rootStore = useRootStore();
    const documentStore = useDocumentStore();
    try {
      const success: boolean = await SignatureService.verifySmsCode(
        pin,
        documentStore.document.id,
        rootStore.recipientId,
        rootStore.authId
      );
      if (success) {
        verifySmsSuccess();
      } else {
        sms.value.error = true;
      }
    } catch (e) {
      sms.value.error = true;
    } finally {
      sms.value.isVerifying = false;
    }
  };

  const approveDocument = async () => {
    const rootStore = useRootStore();
    const documentStore = useDocumentStore();
    try {
      const success: boolean = await SignatureService.approve(
        documentStore.document.id,
        rootStore.recipientId,
        rootStore.authId
      );
      if (success) {
        documentStore.approveDocument(rootStore.recipientId);
      } else {
        useToast().toast.danger(t('could-not-approve-document'));
      }
    } catch (e) {
      useToast().toast.danger(t('could-not-approve-document'));
    }
  };

  const selectedInitialFieldId: Ref<string> = ref('');

  const setSelectedInitialFieldId = (payload: string) => {
    selectedInitialFieldId.value = payload;
  };

  const addInitials = (initialsPayload: string) => {
    initials.value = initialsPayload;
    setSelectedInitialFieldId('');
  };

  const setCriiptoStatus = (payload: CriiptoStatus) => {
    criiptoStatus.value = payload;
  };

  const setSelectedEid = (eid: Id) => {
    selectedEid.value = eid;
    sign.value = SigningStoreConstants.sign();
  };

  const resetState = () => {
    bankId.value = SigningStoreConstants.bankId();
    selectedEid.value = undefined;
    criiptoStatus.value = CriiptoStatus.Default;
    qna.value = SigningStoreConstants.qna();
    sms.value = SigningStoreConstants.sms();
    signature.value = SigningStoreConstants.signature();
    sign.value = SigningStoreConstants.sign();
    step.value = SigningStoreConstants.step();
    loading.value = false;
  };

  const resetEid = () => {
    resetBankId();
    selectedEid.value = undefined;
    criiptoStatus.value = CriiptoStatus.Default;
    sign.value = SigningStoreConstants.sign();
  };

  return {
    setSelectedEid,
    selectedEid,
    resetEid,

    verifySms,
    sendSmsCode,
    sms,

    signature,
    sign,
    step,
    loading,

    qna,
    verifyQna,

    bankId,
    cancelBankIdTransaction,
    startBankIdPolling,
    resetBankId,
    setBankIdQueryParams,

    initials,
    addInitials,
    selectedInitialFieldId,
    setSelectedInitialFieldId,
    setBankIdState,
    startBankIdTransaction,

    criiptoStatus,
    setCriiptoQueryParams,
    setCriiptoStatus,
    redirectCriipto,

    approveDocument,
    signDocument,

    resetState,
    verifySignature,
    checkVerificationStep,

    startBankIdQRMethod,
    startBankIdSameDeviceMethod,
    bankIdTransactionStartTime,
  };
});
