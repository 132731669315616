import gql from 'graphql-tag';

export class FeaturesQueries {
  static load = gql`
    query EntityFeatures {
      entityFeatures {
        features {
          id
          name
          active
          type
          quantitySettings {
            maxQuantity
            unlimited
          }
        }
      }
    }
  `;
}
