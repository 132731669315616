import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';
export const useEntityStore = defineStore('entity', () => {
  const entity: Ref<Entity> = ref(null);
  const setEntity = (newEntity: Entity) => (entity.value = newEntity);

  return { entity, setEntity };
});
