import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { FeatureStatusMap } from '../types/feature-status';
import { FeatureRestrictionHelper } from '../helpers/feature-restriction.helper';

export const useFeatureStore = defineStore('features', () => {
  const features: Ref<FeatureStatusMap> = ref(
    FeatureRestrictionHelper.getDefaultFeatureStatusMap()
  );

  const setFeatures = (newFeatures: FeatureStatusMap) => {
    features.value = newFeatures;
  };

  return {
    setFeatures,
    features,
  };
});
