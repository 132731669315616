import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import type { Field } from '@getaccept/lib-shared-new/src/fields/types/field';
import { FieldDTOHelper } from '@getaccept/lib-shared-new/src/fields/helpers/field-dto.helper';
import { fieldsQuery } from '../graphql/field.queries';
import { apolloClient } from '../../../apollo';
import { updateDocumentFieldMutation } from '../graphql/field.mutations';

export class FieldService {
  static async loadFields(): Promise<Field[]> {
    const {
      data: {
        document: { fields },
      },
    } = await apolloClient.query({
      query: fieldsQuery,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return FieldDTOHelper.convertDTOsToFields(fields);
  }

  static async updateField(id: string, value: string): Promise<Field> {
    const {
      data: { updateDocumentField },
    } = await apolloClient.mutate({
      mutation: updateDocumentFieldMutation,
      variables: {
        id,
        value,
      },
    });
    return updateDocumentField;
  }
}
