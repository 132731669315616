import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient, ApolloLink, createHttpLink, Observable } from '@apollo/client/core';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import bugsnagClient, { breadcrumbLink } from '@getaccept/lib-shared-new/src/bugsnag';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { AuthQuery } from '@getaccept/lib-shared-new/src/session/signing-site/session.queries';
import { UrlHelper } from '@getaccept/lib-shared-new/src/helpers/url.helper';
import { getPrefixedPublicApiBaseUrl } from '@getaccept/lib-shared-new/src/helpers/get-prefixed-api-base-url';
import type { OperationDefinitionNode } from 'graphql';
import { useDocumentStore } from './documents/store/document.store';
import { useSessionStore } from './session/store/session.store';
import { useRootStore } from './store/root.store';
import { DocumentViewableError } from './api/documents/types/document-viewable-error';
const pubBaseUrl = process.env.VUE_APP_PUBLIC_API_BASE_URL;

const domain = pubBaseUrl
  ? getPrefixedPublicApiBaseUrl(pubBaseUrl, window.location.hostname)
  : 'localhost:3002';

const abortController = new AbortController();
export const abortApollo = () => abortController.abort();
const httpLink = createHttpLink({
  uri: ({ operationName }) => UrlHelper.getHttpsUrl(`${domain}/graphql?op=${operationName}`),
  fetchOptions: {
    signal: abortController.signal,
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
  if (graphQLErrors) {
    const sessionExpiredErrors: boolean = graphQLErrors.some(
      error =>
        error.extensions?.code === 'UNAUTHENTICATED' &&
        error.message !== 'You do not have access to this document'
    );

    const accessDenied: boolean = graphQLErrors.some(
      error =>
        error.extensions?.code === 'UNAUTHENTICATED' &&
        error.message === 'You do not have access to this document'
    );
    const recalledError: boolean = graphQLErrors.some(
      error => error.extensions?.code === 'RECALLED'
    );

    const unpublishedError: boolean = graphQLErrors.some(
      error => error.extensions?.code === 'UNPUBLISHED'
    );
    const signedDocumentError: boolean = graphQLErrors.some(
      error => error.message === 'Action not allowed for signed documents'
    );
    if (signedDocumentError) {
      useDocumentStore().setDocumentStatus(DocumentStatus.Signed);
    }
    const sessionStore = useSessionStore();
    if (recalledError) {
      sessionStore.setStatus(DocumentStatus.Recalled);
    }

    if (unpublishedError) {
      sessionStore.setStatus(DocumentStatus.Unpublished);
    }

    if (accessDenied) {
      sessionStore.setError(true);
    }

    const filteredErrors = graphQLErrors.filter(
      error =>
        !(
          error.extensions?.code === 'UNAUTHENTICATED' ||
          error.extensions?.code === 'RECALLED' ||
          error.message?.includes(DocumentViewableError.Processing)
        )
    );

    const store = useRootStore();

    if (sessionExpiredErrors && store.isPreview) {
      store.setPreviewExpired(true);
    } else if (sessionExpiredErrors) {
      return new Observable(observer => {
        apolloClient
          .query({
            query: AuthQuery,
            variables: {
              documentId: store.documentId,
              recipientId: store.recipientId,
              authId: store.authId,
            },
          })
          .then(response => {
            sessionStore.loadTokenSuccess(response.data.auth, []);
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            operation.setContext({ headers: { authorization: getAuthToken() } });
            forward(operation).subscribe(subscriber);
          })
          .catch(error => console.error('Token renegotation failed', error));
      });
    }
    if (bugsnagClient) {
      filteredErrors.forEach(graphQLError => {
        bugsnagClient.notify(new Error(JSON.stringify(graphQLError)), event => {
          event.groupingHash = graphQLError.message;
          event.addMetadata('GraphQL', {
            operationName: operation?.operationName,
            variables: operation?.variables,
            type: (operation?.query?.definitions[0] as OperationDefinitionNode)?.operation,
          });
        });
      });
    }
  }
  if (networkError && Object.keys(networkError).length && bugsnagClient && !graphQLErrors?.length) {
    bugsnagClient.notify(new Error(JSON.stringify(networkError)), event => {
      event.groupingHash = JSON.stringify(networkError);
      event.addMetadata('GraphQL', {
        operationName: operation?.operationName,
        variables: operation?.variables,
        type: (operation?.query?.definitions[0] as OperationDefinitionNode)?.operation,
      });
    });
  }
});

const getAuthToken = () => {
  const sessionStore = useSessionStore();
  const { jwt } = sessionStore.token as any;
  return `Bearer ${jwt}`;
};

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({ headers: { authorization: getAuthToken() } });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, breadcrumbLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
