<template>
  <div class="consent-container">
    <FriedH3 class="title">{{ title }}</FriedH3>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <FriedParagraph class="description" v-html="description" />
    <div class="consent-checkbox-container" @click="toogleValue">
      <FriedCheckbox
        data-external="consent-checkbox"
        class="consent-checkbox"
        :model-value="value"
        :label="label"
        :tabindex="0"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { SigningMergeTagHelper } from '../../helpers/signing-merge-tag.helper';

export default defineComponent({
  props: {
    consentStep: {
      type: Object as PropType<AuthenticationStep>,
      default: undefined,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const title = computed((): string => {
      switch (props.consentStep.type) {
        case AuthenticationStepType.GdprConsent:
          return t('header-gdpr').toString();
        case AuthenticationStepType.TrackingConsent:
          return t('header-tracking').toString();
        default:
          return null;
      }
    });

    const label = computed((): string => {
      switch (props.consentStep.type) {
        case AuthenticationStepType.GdprConsent:
          return t('checkbox-label-gdpr').toString();
        case AuthenticationStepType.TrackingConsent:
          return t('checkbox-label-tracking').toString();
        default:
          return null;
      }
    });

    const description = computed((): string => {
      let defaultText: string;
      switch (props.consentStep.type) {
        case AuthenticationStepType.GdprConsent:
          defaultText = t('default-gdpr-consent-text').toString();
          break;
        case AuthenticationStepType.TrackingConsent:
          defaultText = t('default-tracking-consent-text').toString();
          break;
      }
      return SigningMergeTagHelper.replaceTags(props.consentStep.data.text || defaultText, {
        entity: props.consentStep.data.textData?.entity,
      });
    });

    const toogleValue = () => {
      emit('change', !props.value, props.consentStep.type);
    };

    return {
      title,
      label,
      description,
      toogleValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.consent-container {
  margin-bottom: var(--spacing-150);

  .description {
    :deep(a) {
      display: initial;
      font-size: inherit;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .title,
  .consent-checkbox-container {
    margin: var(--spacing-75) 0;
  }
}
</style>
