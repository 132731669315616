import { ReportEmailMutations } from '../graphql/report-email.mutations';
import { apolloClient } from '../../../apollo';
import type { Reason } from '../../../report-email/types/unsubscribe-reason';

export class ReportEmailService {
  static async reportDocument({
    recipientId,
    documentId,
    reason,
  }: {
    recipientId: string;
    documentId: string;
    reason?: Reason;
  }) {
    await apolloClient.mutate({
      mutation: ReportEmailMutations.unsubscribeEmailDocument,
      variables: { payload: { recipientId, documentId, reason } },
    });
  }

  static async reportDsr({
    entityId,
    participantId,
    dealroomId,
    reason,
  }: {
    entityId: string;
    participantId: string;
    dealroomId: string;
    reason?: Reason;
  }) {
    await apolloClient.mutate({
      mutation: ReportEmailMutations.unsubscribeEmailDealroom,
      variables: { payload: { entityId, participantId, dealroomId, reason } },
    });
  }
}
