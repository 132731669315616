import { ref } from 'vue';
import axios from 'axios';
import type { LocaleMessageObject } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import { Site, Language } from '@getaccept/lib-shared-new/src/languages/enums/translation';
import { TranslationHelper } from '@getaccept/lib-shared-new/src/languages/helpers/translation-setup.helper';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
export const languageLoaded = ref(false);
const fileUrl = process.env.LOKALISE_URL || 'https://translations.getaccept.com';
const project = Site.RecipientPage;
const loadedLanguages = [];
const fallbackLocale = Language.En;

export const i18n = createI18n({
  legacy: false,
  locale: fallbackLocale,
  fallbackLocale,
  allowComposition: true,
  postTranslation: str => {
    if (typeof str === 'string') {
      return str.replace(/\[\[/g, '{{').replace(/\]\]/g, '}}');
    }
    return str;
  },
});
export default i18n;
function setI18nLanguage(lang) {
  i18n.global.locale.value = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}
const getLanguageFromFile = async (lang: string): Promise<LocaleMessageObject> => {
  const languageObj = TranslationHelper.getLangObject(lang, project);
  const msgs = await import(`./i18n/generated/${languageObj.file}.json`);
  return msgs.default;
};
const getLanguageMessages = async (lang: string): Promise<LocaleMessageObject> => {
  const languageObj = TranslationHelper.getLangObject(lang, project);
  if (window.location.href.includes('localhost')) {
    return await getLanguageFromFile(lang);
  }
  try {
    const { data } = await axios.get(`${fileUrl}/${project}/${languageObj.key}`);
    return data;
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e);
    }
    return await getLanguageFromFile(lang);
  }
};
const loadFallbackLanguage = async () => {
  if (loadedLanguages.includes(fallbackLocale)) {
    return;
  }
  try {
    const messages = await getLanguageMessages(fallbackLocale);
    i18n.global.setLocaleMessage(fallbackLocale, messages);
    loadedLanguages.push(fallbackLocale);
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e);
    }
    console.error(e);
  }
};
export async function loadLanguageAsync(langKey: string): Promise<string | void> {
  const { id: lang } = TranslationHelper.getLangObject(langKey, project);
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  try {
    const messages = await getLanguageMessages(lang);
    i18n.global.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e);
    }
    if (lang !== fallbackLocale) {
      loadFallbackLanguage();
    }
    console.error(e);
  } finally {
    languageLoaded.value = true;
  }
}
export function userLanguage(): string {
  try {
    const lang = window.navigator['userLanguage'] || window.navigator.language;
    return lang.substring(0, 2);
  } catch (e) {
    return fallbackLocale;
  }
}

export const { t } = i18n.global;
