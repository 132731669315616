import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { LocalStorageSubKey } from '../local-storage/type/subkey';
import { LocalStorageHelper } from '../local-storage/helpers/local-storage.helper';
import { useSigningStore } from '../signing/store/signing.store';
import type { IdsPayload } from './types/ids-payload';

export const useRootStore = defineStore('root', () => {
  const setIds = (payload: IdsPayload) => {
    if (payload.recipientId !== recipientId.value) {
      const signingStore = useSigningStore();
      signingStore.resetBankId();
    }
    if (payload.userId) {
      isPreview.value = true;
    }
    documentId.value = payload.documentId;
    recipientId.value = payload.recipientId;
    authId.value = payload.authId;
    userId.value = payload.userId;
    videoReminderId.value = payload.videoReminderId;
  };

  const setPreviewMode = (payload: boolean) => {
    isPreview.value = payload;
  };

  const setPreviewExpired = (payload: boolean) => {
    previewExpired.value = payload;
  };

  const setPusherConnected = (payload: boolean) => {
    pusherConnected.value = payload;
  };

  const authId = ref(null);
  const documentId = ref(null);
  const recipientId: Ref<string> = useStorage(
    LocalStorageHelper.buildKey(window.location.href, LocalStorageSubKey.RecipientId),
    null
  );

  const userId = ref(null);
  const isPreview = ref(false);
  const previewExpired = ref(false);
  const pusherConnected = ref(false);
  const videoReminderId = ref(null);

  return {
    authId,
    documentId,
    recipientId,
    userId,
    isPreview,
    previewExpired,
    pusherConnected,
    videoReminderId,
    setIds,
    setPreviewMode,
    setPreviewExpired,
    setPusherConnected,
  };
});
