import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import type { DocumentAttachment } from '@getaccept/lib-shared-new/src/types/document-attachment';
import { DocumentAttachmentQueries } from '../graphql/document-attachment.queries';
import { apolloClient } from '../../../apollo';

export class DocumentAttachmentService {
  static async load(): Promise<DocumentAttachment[]> {
    const {
      data: {
        document: { documentAttachments },
      },
    } = await apolloClient.query({
      query: DocumentAttachmentQueries.load,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return documentAttachments;
  }
}
