import gql from 'graphql-tag';
import { entityFragment } from './entity.fragment';

export const entityQuery = gql`
  query Entity {
    entity {
      ...entityFragment
      id
      isDisableComment
      settings {
        contextualCommenting
      }
    }
  }
  ${entityFragment}
`;
