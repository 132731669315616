import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import { EntityService } from '../../api/entity/services/entity.service';

export const useEntityStore = defineStore('entity', () => {
  const loading = ref(false);
  const loaded = ref(false);
  const error = ref(false);

  const entity: Ref<Entity> = ref(null);

  const load = async () => {
    try {
      error.value = false;
      loading.value = true;
      entity.value = await EntityService.getEntity();
      loaded.value = true;
    } catch (e) {
      console.error(e);
      error.value = true;
    } finally {
      loading.value = false;
    }
  };

  return {
    entity,
    loading,
    loaded,
    error,
    load,
  };
});
