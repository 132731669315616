import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { Token } from '@getaccept/lib-shared-new/src/session/type/token';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { AuthenticationHelper } from '@getaccept/lib-shared-new/src/authentication/helpers/authentication.helper';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import { useToast } from '@getaccept/lib-shared-new/src/toast';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { useLocalStorage } from '@vueuse/core';
import { DsrService } from '../../api/dsr/service/dsr.service';
import { SessionService } from '../../api/session/services/session.service';
import { StatePersistHelper } from '../helpers/state-persist.helper';
import type { Authentication } from '../types/authentication';
import { UrlHelper } from '../helpers/url.helper';

export const useSessionStore = defineStore('session', () => {
  const storageKey = StatePersistHelper.getSessionKey(window.location.href);
  const token = useLocalStorage(storageKey, { jwt: '', exp: 0 });
  const participantId = ref('');
  const dealroomId = ref('');
  const entityId = ref('');
  const authId = ref('');
  const sessionError = ref(false);
  const authentication = ref<Authentication>({
    submittingResponse: false,
    steps: [],
    answers: [],
  });
  const status = ref<DocumentStatus>(null);
  const tokenError = ref(false);
  const tokenLoading = ref(true);

  const storeEmail = (email: string, participantId: string) => {
    const emailStorageKey = StatePersistHelper.getEmailKey(window.location.href, participantId);
    localStorage.setItem(emailStorageKey, email);
  };

  const getStoredEmail = (participantId: string) => {
    const emailStorageKey = StatePersistHelper.getEmailKey(window.location.href, participantId);
    return localStorage.getItem(emailStorageKey);
  };

  const setToken = (newToken: { jwt: string; exp: number }) => (token.value = newToken);
  const tokenLoaded = computed(() => !!token.value.jwt);

  const setTokenError = (error: boolean) => (tokenError.value = error);
  const setTokenLoading = (loading: boolean) => (tokenLoading.value = loading);

  const setIds = ({ dealroomId, participantId, authId: newAuthId, entityId: newEntityId }) => {
    setDealroomId(dealroomId);
    setParticipantId(participantId);
    entityId.value = newEntityId;
    authId.value = newAuthId;
  };

  const setDealroomId = (id: string) => {
    dealroomId.value = id;
  };

  const setParticipantId = (id: string) => {
    participantId.value = id;
  };

  const loadToken = async (authStepsInput?: AuthenticationStepInput[]) => {
    setTokenLoading(true);

    try {
      const token: Token = await SessionService.getToken(
        dealroomId.value,
        participantId.value,
        authId.value,
        entityId.value,
        authStepsInput
      );
      setNewToken({ token, authStepsInput });
    } catch (e) {
      setError(true);
    } finally {
      setTokenLoading(false);
    }
  };

  const setNewToken = ({
    token: currentToken,
    authStepsInput,
  }: {
    token: Token;
    authStepsInput: AuthenticationStepInput[];
  }) => {
    authentication.value.submittingResponse = false;
    if (currentToken.authSteps?.length) {
      authentication.value.steps = currentToken.authSteps;

      if (authStepsInput) {
        setAuthenticationAnswers({
          authSteps: currentToken.authSteps,
          authAnswers: authStepsInput,
        });
      }
    } else if (currentToken.metaData && currentToken.metaData.redirectUrl) {
      const formattedUrl = UrlHelper.formatRedirectUrl(currentToken);
      window.location.assign(String(formattedUrl));
    } else if (currentToken.jwt) {
      const { jwt, exp } = currentToken;
      token.value = { jwt, exp };
      handleStatus(currentToken.status);
    } else if (currentToken.status) {
      handleStatus(currentToken.status);
    } else {
      setError(true);
    }
  };

  const handleStatus = (status: DocumentStatus) => {
    setStatus(status);
    authentication.value.steps = [];
  };

  const setStatus = (newStatus: DocumentStatus) => {
    status.value = newStatus;
  };

  const setError = (error: boolean) => {
    sessionError.value = error;
  };

  const submitConsent = async (authStepsInput: AuthenticationStepInput[]) => {
    for (let i = 0; i < authStepsInput.length; i++) {
      if (authStepsInput[i].type === AuthenticationStepType.GdprConsent) {
        await handleGDPRConsent(authStepsInput[i]);
      } else if (authStepsInput[i].type === AuthenticationStepType.TrackingConsent) {
        await handleTrackingConsent(authStepsInput[i]);
      }
    }
    submitAnswer(authStepsInput);
  };

  const setAuthenticationAnswers = ({
    authSteps,
    authAnswers,
  }: {
    authSteps: AuthenticationStep[];
    authAnswers: AuthenticationStepInput[];
  }) => {
    const correctAnswers = AuthenticationHelper.getAuthenticationAnswers(authSteps, authAnswers);
    authentication.value.answers = correctAnswers;
  };

  const submitAnswer = async (authStepsInput: AuthenticationStepInput[]) => {
    try {
      const existingAuthStepsInput = AuthenticationHelper.getExistingAuthStepsInput(
        authStepsInput,
        authentication.value.answers
      );
      await loadToken(existingAuthStepsInput);
    } catch (e) {
      authentication.value.submittingResponse = false;
      console.error(e);
    }
  };

  const declineConsent = () => {
    const authStepsInput = AuthenticationHelper.getDeclinedConsents(authentication.value.steps);
    submitConsent(authStepsInput);
  };

  const handleGDPRConsent = async (authStepInput: AuthenticationStepInput) => {
    authentication.value.submittingResponse = true;
    try {
      if (authStepInput.value !== '1') {
        return;
      }

      await SessionService.verifyConsent({
        dealroomId: dealroomId.value,
        entityId: entityId.value,
        participantId: participantId.value,
      });
    } catch (error) {
      useToast().toast.danger(t('could-not-save-consent'));
    } finally {
      authentication.value.submittingResponse = false;
    }
  };
  const handleTrackingConsent = async (authStepInput: AuthenticationStepInput) => {
    authentication.value.submittingResponse = true;
    try {
      authStepInput.value === '1'
        ? await SessionService.postVerifyTrackingConsentLegacy(
            dealroomId.value,
            participantId.value,
            authId.value
          )
        : await SessionService.postRejectTrackingConsentLegacy(
            dealroomId.value,
            participantId.value,
            authId.value
          );
    } catch (error) {
      useToast().toast.danger(t('could-not-save-consent'));
    } finally {
      authentication.value.submittingResponse = false;
    }
  };

  const loadCurrentParticipantId = async () => {
    try {
      const participant = await DsrService.loadParticipant();
      setParticipantId(participant.id);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    token,
    dealroomId,
    participantId,
    authId,
    tokenLoaded,
    sessionError,
    authentication,
    status,
    entityId,
    tokenError,
    tokenLoading,
    loadToken,
    handleStatus,
    setIds,
    setNewToken,
    setStatus,
    setError,
    setToken,
    handleGDPRConsent,
    handleTrackingConsent,
    declineConsent,
    setAuthenticationAnswers,
    submitAnswer,
    submitConsent,
    storeEmail,
    getStoredEmail,
    setTokenError,
    setTokenLoading,
    setDealroomId,
    setParticipantId,
    loadCurrentParticipantId,
  };
});
