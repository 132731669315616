import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Ref } from 'vue';
import type { EditorBlock, ImageNode } from '../types/models';
import { NodeType } from '../types/enums/attributes';

interface ImageMap {
  [imageId: string]: { srcUrl: string; externalId: string; blurhash: string };
}

export const useLoadedImagesStore = defineStore('loaded-images', () => {
  const images: Ref<ImageMap[]> = ref([]);

  const setImage = (imageId: string, srcUrl: string, externalId: string, blurhash: string) => {
    if (!images.value.some(image => image[imageId])) {
      images.value.push({ [imageId]: { srcUrl, externalId, blurhash } });
      return;
    }

    images.value = images.value.map(image =>
      image[imageId] ? { [imageId]: { srcUrl, externalId, blurhash } } : image
    );
  };

  const mapImagesToBlock = (block: EditorBlock) => ({
    ...block,
    content: {
      ...block.content,
      sections: block.content.sections.map(section => ({
        ...section,
        rows: section.rows.map(row => ({
          ...row,
          cells: row.cells.map(cell => ({
            ...cell,
            nodes: cell.nodes.map((node: ImageNode) =>
              node.type === NodeType.Image && node.imageId && !node.srcUrl
                ? {
                    ...node,
                    ...images.value.find(image => image[node.imageId])?.[node.imageId],
                  }
                : node
            ),
          })),
        })),
      })),
    },
  });

  const reset = () => {
    images.value = [];
  };

  return { setImage, mapImagesToBlock, reset, images };
});
