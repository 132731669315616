<template>
  <FriedModalSubContainer>
    <template #header>
      <FriedModalHeader :header="t('authenticate-to-access-document')" />
    </template>
    <Consent
      v-for="(consentStep, i) in consentSteps"
      :key="i"
      :consent-step="consentStep"
      :value="getValue(consentStep)"
      @change="handleConsentChange"
    />
    <template #footer>
      <FriedModalButtonsFooter>
        <FriedButton
          v-if="!submittingResponse"
          data-external=""
          :button-type="ButtonType.Secondary"
          class="margin-bottom"
          @click="handleDecline"
        >
          {{ t('decline') }}
        </FriedButton>
        <FriedButton
          data-external="consent-save-button"
          :disabled="!acceptedGDPR"
          :loading="submittingResponse"
          @click="handleAnswer"
        >
          {{ t('save') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModalSubContainer>
</template>

<script lang="ts">
import { ButtonType } from '@getaccept/fried-tofu';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import type { PropType } from 'vue';
import { onBeforeUnmount, onMounted, computed, ref, defineComponent } from 'vue';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import Consent from '@getaccept/lib-shared-new/src/lobby/components/Consent.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    Consent,
  },
  props: {
    consentSteps: {
      type: Array as PropType<AuthenticationStep[]>,
      required: true,
    },
    submittingResponse: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['decline', 'answer'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const acceptGDPR = ref(false);
    const acceptTracking = ref(false);

    const acceptedGDPR = computed((): boolean => {
      if (!gdprIsActive.value) {
        return true;
      }
      return acceptGDPR.value;
    });

    const gdprIsActive = computed((): boolean =>
      props.consentSteps.some(
        (step: AuthenticationStep) => step.type === AuthenticationStepType.GdprConsent
      )
    );

    const trackingConsentIsActive = computed((): boolean =>
      props.consentSteps.some(
        (step: AuthenticationStep) => step.type === AuthenticationStepType.TrackingConsent
      )
    );

    const authSteps = computed((): AuthenticationStepInput[] => {
      const steps: AuthenticationStepInput[] = [];
      if (gdprIsActive.value) {
        steps.push({
          type: AuthenticationStepType.GdprConsent,
          value: acceptGDPR.value ? '1' : '0',
        });
      }

      if (trackingConsentIsActive.value) {
        steps.push({
          type: AuthenticationStepType.TrackingConsent,
          value: acceptTracking.value ? '1' : '0',
        });
      }
      return steps;
    });

    const handleConsentChange = (value: boolean, type: AuthenticationStepType) => {
      if (type === AuthenticationStepType.TrackingConsent) {
        acceptTracking.value = value;
      } else {
        acceptGDPR.value = value;
      }
    };

    const handleDecline = () => {
      emit('decline');
    };

    const handleAnswer = () => {
      if (acceptedGDPR.value) {
        emit('answer', authSteps.value);
      }
    };

    const getValue = (consentStep: AuthenticationStep): boolean => {
      if (consentStep.type === AuthenticationStepType.TrackingConsent) {
        return acceptTracking.value;
      }
      return acceptGDPR.value;
    };

    const bindEnter = ({ key }: KeyboardEvent) => {
      if (key === 'Enter') {
        handleAnswer();
      }
    };

    onMounted(() => {
      document.addEventListener('keydown', bindEnter);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', bindEnter);
    });

    return {
      ButtonType,
      acceptGDPR,
      acceptTracking,
      acceptedGDPR,
      gdprIsActive,
      trackingConsentIsActive,
      authSteps,
      handleConsentChange,
      handleDecline,
      handleAnswer,
      getValue,
      bindEnter,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@media only screen and (max-height: 812px) and (max-width: 600px) {
  .margin-bottom {
    margin-bottom: var(--spacing-50);
  }
}
</style>
