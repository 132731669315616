<template>
  <FriedModalSubContainer>
    <template #header>
      <FriedModalHeader
        data-external="qna-header-authenticate-to-access-doc"
        :header="$t('cr_answer_the_question')"
      />
    </template>
    <FriedParagraph>{{ $t('cr_answer_question_description') }}</FriedParagraph>
    <div class="qna-input-container">
      <FriedH4>
        <i18n-t keypath="cr_verification_question">
          <template #senderQuestion>
            {{ authStep.data.question }}
          </template>
        </i18n-t>
      </FriedH4>
      <FriedInput
        v-model="value"
        data-external="qna-modal-sender-requested-answer-input"
        class="qna-input"
        :label="$t('cr_enter_the_answer')"
        :autocomplete="'off'"
        :disabled="submittingResponse"
        :error-message="errorMessage"
        @update:model-value="hasChangedDebounce"
        @key-down-enter="handleAnswer"
      />
    </div>
    <template #footer>
      <FriedModalButtonsFooter>
        <FriedButton
          id="validate"
          data-external="qna-modal-sender-requested-password-validate-btn"
          :disabled="disableValidate"
          :loading="submittingResponse"
          @click="handleAnswer"
        >
          {{ $t('validate') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModalSubContainer>
</template>

<script lang="ts">
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import type { PropType } from 'vue';
import { watch, defineComponent, ref, computed } from 'vue';
import debounce from 'debounce';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    authStep: { type: Object as PropType<AuthenticationStep>, required: true },
    submittingResponse: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
  },
  emits: ['answer'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const value = ref('');
    const errorMessage = ref('');
    const hasChanged = ref(null);

    const setError = () => {
      errorMessage.value = props.error ? t('error_message_wrong_answer') : '';
    };

    const disableValidate = computed(
      () => !value.value.length || (!hasChanged.value && hasChanged.value !== null)
    );

    const handleChanged = () => {
      hasChanged.value = true;
    };
    const hasChangedDebounce = debounce(handleChanged, 300);

    watch(() => props.error, setError);

    const handleAnswer = () => {
      emit('answer', { type: AuthenticationStepType.VerifyQna, value: value.value });
      hasChanged.value = false;
    };

    return {
      handleAnswer,
      value,
      errorMessage,
      hasChangedDebounce,
      hasChanged,
      disableValidate,
      handleChanged,
      setError,
    };
  },
});
</script>

<style lang="scss" scoped>
$label-height: var(--spacing-100);
$input-height: 51px;
$border: 0.05rem solid var(--gray-96);

.qna-input-container {
  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-50);

  .qna-input {
    margin-top: var(--spacing-50);
    width: 100%;
  }

  .footer {
    margin-top: var(--spacing-50);
  }
}
</style>
