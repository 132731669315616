import { PusherClient } from '@getaccept/pusher';
import { getPrefixedPublicApiBaseUrl } from '@getaccept/lib-shared-new/src/helpers/get-prefixed-api-base-url';
import { UrlHelper } from '@getaccept/lib-shared-new/src/helpers/url.helper';

const domain = getPrefixedPublicApiBaseUrl(
  process.env.VUE_APP_PUBLIC_API_BASE_URL,
  window.location.hostname
);

export const pusherClient = new PusherClient(UrlHelper.getHttpsUrl(domain));
