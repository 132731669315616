<template>
  <ReportEmail v-if="isReportEmail" :is-dsr="isDsr" />
  <UniversalLinkLobby v-else-if="isUniversalLink" />
  <DealroomApp v-else-if="isDsr" />
  <SigningApp v-else />
</template>
<script lang="ts">
import DealroomApp from '@getaccept/dsr-published-site/src/App.vue';
import { UniversalLinkHelper } from '@getaccept/lib-shared-new/src/universal-link/helpers/universal-link.helper';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { Settings } from 'luxon';
import { computed, defineAsyncComponent, defineComponent, onMounted } from 'vue';
import { friedTofuSettings } from '@getaccept/fried-tofu';
import { VueRouterRoutingProvider } from '@bugsnag/vue-router-performance';
import SigningApp from './App.vue';
import ReportEmail from './report-email/components/ReportEmail.vue';
import { loadLanguageAsync, userLanguage } from './lang';
import router from './router';

export default defineComponent({
  components: {
    DealroomApp,
    SigningApp,
    UniversalLinkLobby: defineAsyncComponent(
      () => import('./universal-link/components/UniversalLinkLobby.vue')
    ),
    ReportEmail,
  },
  setup() {
    const isDsr = computed(() => window.location.pathname.includes('/dsr'));

    const isUniversalLink = computed(() =>
      UniversalLinkHelper.isUniversalLink(window.location.href)
    );

    const isReportEmail = computed(() => !!window.location.href.includes('/report-email'));

    const initBugSnagPerformanceMonitoring = () => {
      if (process.env.VUE_APP_BUGSNAG) {
        BugsnagPerformance.start({
          apiKey: process.env.VUE_APP_BUGSNAG,
          releaseStage: process.env.VUE_APP_BUGSNAG_RELEASE_STAGE || 'development',
          appVersion: process.env.VUE_APP_BUGSNAG_APP_VERSION || '0.0.0',
          routingProvider: new VueRouterRoutingProvider(router as any, '/'),
        });
      }
    };

    onMounted(() => {
      loadLanguageAsync(userLanguage());
      Settings.defaultLocale = userLanguage();
      friedTofuSettings.locale = userLanguage();
      initBugSnagPerformanceMonitoring();
    });

    return {
      isDsr,
      isUniversalLink,
      isReportEmail,
    };
  },
});
</script>
