<template>
  <UniversalLinkLobby v-if="isUniversalLink" :entity-id="entityId" />
  <LobbyWrapper v-else />
</template>

<script lang="ts">
import { watch, defineAsyncComponent, computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import LobbyWrapper from './identify/components/LobbyWrapper.vue';
import { useThemeStore } from './theme/theme.store';

export default defineComponent({
  components: {
    LobbyWrapper,
    UniversalLinkLobby: defineAsyncComponent(
      () => import('./identify/components/UniversalLinkLobby.vue')
    ),
  },
  setup() {
    const themeStore = useThemeStore();
    const route = useRoute();
    const isUniversalLink = computed(() => !route.params.participantId && !route.params.authId);

    const entityId = computed(() => route.params.entityId?.toString());
    const documentId = computed(() => route.params.documentId?.toString());
    const hasParams = computed(() => entityId.value || documentId.value);

    watch(
      hasParams,
      () => {
        if (!hasParams.value) {
          return;
        }
        themeStore.loadTheme({ entityId: entityId.value, documentId: documentId.value });
      },
      { immediate: true }
    );

    return {
      isUniversalLink,
      entityId,
    };
  },
});
</script>

<style lang="scss" scoped>
html {
  height: 100%;
  font-family: var(--default-font-family);

  body {
    margin: 0;
    height: 100%;
  }
}
</style>
