import axios from 'axios';
import type {
  BankIDStatusResponse,
  BankIdStartResponse,
  ResetResponse,
} from '../../../signing/types/bankid-se-dots';

const staticBaseUrl = process.env.VUE_APP_EID_API_URL || 'eid.ga-staging.com';
const noCacheConfig = { headers: { 'Cache-Control': 'no-cache' } };

export class BankIdSeService {
  private static readonly baseUrl: string = `https://${staticBaseUrl}/bankid-se`;

  static async start(documentId: string, recipientId: string): Promise<BankIdStartResponse> {
    const url = `${this.baseUrl}/start`;
    const { data } = await axios.post(url, { documentId, recipientId });
    return data;
  }

  static async poll(
    orderRef: string,
    documentId: string,
    recipientId: string
  ): Promise<BankIDStatusResponse> {
    const url = `${this.baseUrl}/status/${orderRef}/?documentId=${documentId}&recipientId=${recipientId}`;
    const { data } = await axios.get(url, { ...noCacheConfig });
    if (data.errorCode) {
      throw new Error(`${data.errorCode} - ${data.details}`);
    }
    return data;
  }

  static async cancel(orderRef: string): Promise<boolean> {
    const url = `${this.baseUrl}/cancel/${orderRef}`;
    const { data } = await axios.post(url);
    return data;
  }

  static async reset(orderRef: string): Promise<ResetResponse> {
    const url = `${this.baseUrl}/reset/${orderRef}`;
    const { data } = await axios.get(url, { ...noCacheConfig });
    return data;
  }
}
