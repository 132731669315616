import gql from 'graphql-tag';

export const fieldFragment = gql`
  fragment fieldFragment on DocumentField {
    id
    pageId
    fieldType
    fieldLabel
    fieldValue
    fieldTop
    fieldLeft
    fieldWidth
    fieldHeight
    createdAt
    isCollectable
    isEditable
    isRequired
    validationRegex
    validationWarning
    fieldOptions
    recipientId
    signatureBiometric
    verifiedInitials
  }
`;
