<template>
  <div class="person-container">
    <FriedAvatar :thumb-url="thumbUrl" :name="name" />
    <div class="text">
      <FriedParagraph class="title">{{ title }}</FriedParagraph>
      <FriedParagraph v-if="subtitle" :size="ParagraphSize.Small" class="subtitle">
        {{ subtitle }}
      </FriedParagraph>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ParagraphSize } from '@getaccept/fried-tofu';

export default defineComponent({
  props: {
    thumbUrl: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ParagraphSize,
    };
  },
});
</script>
<style lang="scss" scoped>
.person-container {
  display: grid;
  gap: var(--spacing-100);
  grid-template-columns: min-content 1fr;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  .text {
    overflow: hidden;
    display: grid;
    grid-template-rows: min-content min-content;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
