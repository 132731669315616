import gql from 'graphql-tag';

export class UniversalLinkMutations {
  static identifyDealroomParticipant = gql`
    mutation IdentifyDealroomParticipant(
      $gdprConsent: Boolean
      $dealroomId: String!
      $email: String!
      $firstName: String
      $lastName: String
      $title: String
      $companyName: String
      $entityId: String
    ) {
      identifyDealroomParticipant(
        gdprConsent: $gdprConsent
        dealroomId: $dealroomId
        email: $email
        firstName: $firstName
        lastName: $lastName
        title: $title
        companyName: $companyName
        entityId: $entityId
      ) {
        roomLink
      }
    }
  `;
}
