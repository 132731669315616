import { ApolloClient, createHttpLink, ApolloLink, Observable } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import type { OperationDefinitionNode } from 'graphql';
import bugsnagClient, { breadcrumbLink } from '@getaccept/lib-shared-new/src/bugsnag';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { UniversalLinkHelper } from '@getaccept/lib-shared-new/src/universal-link/helpers/universal-link.helper';
import { getPrefixedPublicApiBaseUrl } from '@getaccept/lib-shared-new/src/helpers/get-prefixed-api-base-url';
import { UrlHelper } from '@getaccept/lib-shared-new/src/helpers/url.helper';
import { AuthQuery } from './api/session/graphql/session.queries';
import { useSessionStore } from './session/store/session.store';
import { useUniversalLink } from './identify/composables/universal-link.composable';

const pubBaseUrl = process.env.VUE_APP_PUBLIC_API_BASE_URL;
const domain = pubBaseUrl
  ? getPrefixedPublicApiBaseUrl(pubBaseUrl, window.location.hostname)
  : 'localhost:3002';

const abortController = new AbortController();
export const abortApollo = () => abortController.abort();
const httpLink = createHttpLink({
  uri: ({ operationName }) => UrlHelper.getHttpsUrl(`${domain}/graphql?op=${operationName}`),
  fetchOptions: {
    signal: abortController.signal,
  },
});

// eslint-disable-next-line sonarjs/cognitive-complexity
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
  if (graphQLErrors) {
    const sessionExpiredErrors: boolean = graphQLErrors.some(
      error =>
        error.extensions?.code === 'UNAUTHENTICATED' &&
        error.message !== 'You do not have access to this dealroom'
    );

    const accessDenied: boolean = graphQLErrors.some(
      error =>
        error.extensions?.code === 'UNAUTHENTICATED' &&
        error.message === 'You do not have access to this dealroom'
    );
    const recalledError: boolean = graphQLErrors.some(
      error => error.extensions?.code === 'RECALLED'
    );

    const unpublishedError: boolean = graphQLErrors.some(
      error => error.extensions?.code === 'UNPUBLISHED'
    );

    const {
      setStatus,
      setError,
      setNewToken,
      dealroomId,
      entityId,
      authId,
      participantId,
      setTokenError,
      setTokenLoading,
    } = useSessionStore();

    if (recalledError) {
      setStatus(DocumentStatus.Recalled);
    }

    if (unpublishedError) {
      setStatus(DocumentStatus.Unpublished);
    }

    if (accessDenied) {
      setError(true);
    }

    const filteredErrors = graphQLErrors.filter(
      error =>
        !(error.extensions?.code === 'UNAUTHENTICATED' || error.extensions?.code === 'RECALLED')
    );

    if (sessionExpiredErrors) {
      setTokenLoading(true);

      return new Observable(observer => {
        apolloClient
          .query({
            query: AuthQuery,
            variables: {
              dealroomId,
              participantId,
              authId,
              entityId,
            },
          })
          .then(response => {
            setNewToken({
              token: response.data.dealroomAuth,
              authStepsInput: [],
            });
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            const universalLinkURL = new URL(window.location.href).searchParams.get(
              'universalLinkURL'
            );
            if (universalLinkURL && UniversalLinkHelper.isUniversalLink(universalLinkURL)) {
              const { getUniversalLinkStorageKey } = useUniversalLink();
              const universalLinkKey = UniversalLinkHelper.getUniversalLinkKey(universalLinkURL);
              localStorage.removeItem(getUniversalLinkStorageKey(universalLinkKey));
              window.location.href = universalLinkURL;
            }
            setTokenError(true);
            console.error('Token renegotiation failed', error);
          })
          .finally(() => {
            setTokenLoading(false);
          });
      });
    }
    if (bugsnagClient) {
      filteredErrors.forEach(graphQLError => {
        bugsnagClient.notify(new Error(JSON.stringify(graphQLError)), event => {
          event.groupingHash = graphQLError.message;
          event.addMetadata('GraphQL', {
            operationName: operation?.operationName,
            variables: operation?.variables,
            type: (operation?.query?.definitions[0] as OperationDefinitionNode)?.operation,
          });
        });
      });
    }
  }
  if (networkError && Object.keys(networkError).length && bugsnagClient && !graphQLErrors?.length) {
    bugsnagClient.notify(new Error(JSON.stringify(networkError)), event => {
      event.groupingHash = JSON.stringify(networkError);
      event.addMetadata('GraphQL', {
        operationName: operation?.operationName,
        variables: operation?.variables,
        type: (operation?.query?.definitions[0] as OperationDefinitionNode)?.operation,
      });
    });
  }
});

const getAuthToken = () => `Bearer ${useSessionStore().token.jwt}`;

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({ headers: { authorization: getAuthToken() } });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, breadcrumbLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
