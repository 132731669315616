import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { DsrService } from '../api/dsr/service/dsr.service';

export const useThemeStore = defineStore('theme', () => {
  const theme = ref<SigningTheme>(null);

  const loadTheme = async ({
    documentId,
    entityId,
  }: {
    documentId?: string;
    entityId?: string;
  }) => {
    try {
      const response: SigningTheme = await DsrService.loadTheme({ documentId, entityId });
      theme.value = response;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    theme,
    loadTheme,
  };
});
