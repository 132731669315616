import gql from 'graphql-tag';

export const AuthQuery = gql`
  query DealroomAuth(
    $dealroomId: String!
    $entityId: String
    $participantId: String!
    $authId: String
    $authSteps: [AuthStepInput!]
  ) {
    dealroomAuth(
      dealroomId: $dealroomId
      entityId: $entityId
      participantId: $participantId
      authId: $authId
      authSteps: $authSteps
    ) {
      jwt
      exp
      authSteps {
        type
        data {
          error
          errorType
          text
          textData {
            entity {
              name
            }
            document {
              name
            }
          }
          question
          mobile
          universalLinkUrls
        }
      }
      metaData {
        redirectUrl
        isFirstVisit
      }
      status
    }
  }
`;
