<template>
  <div class="toast-wrapper">
    <transition-group
      tag="div"
      name="toast"
      class="toast-container top-left"
      :style="offsetStyle"
      @leave="leave"
    >
      <Toast v-for="toast in topLeft" :key="toast.id" :toast="toast" />
    </transition-group>
    <transition-group
      tag="div"
      name="toast"
      class="toast-container top-right"
      :style="offsetStyle"
      @leave="leave"
    >
      <Toast v-for="toast in topRight" :key="toast.id" :toast="toast" />
    </transition-group>
    <transition-group
      tag="div"
      name="toast"
      class="toast-container bottom-left"
      :style="offsetStyle"
      @leave="leave"
    >
      <Toast v-for="toast in bottomLeft" :key="toast.id" :toast="toast" />
    </transition-group>
    <transition-group
      tag="div"
      name="toast"
      class="toast-container bottom-right"
      :style="offsetStyle"
      @leave="leave"
    >
      <Toast v-for="toast in bottomRight" :key="toast.id" :toast="toast" />
    </transition-group>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { useToast } from '../toast.composable';
import { ToastPlacement } from '../enums/toast-placement';
import Toast from './Toast.vue';

export default defineComponent({
  components: {
    Toast,
  },
  props: {
    offset: {
      type: Object as PropType<{ top: number; right: number; bottom: number; left: number }>,
      default: () => ({ top: 16, right: 16, bottom: 16, left: 16 }),
    },
  },
  setup(props) {
    const { queue } = useToast();

    const topLeft = computed(() =>
      queue.value.filter(toast => toast.options.placement === ToastPlacement.TopLeft).reverse()
    );
    const topRight = computed(() =>
      queue.value.filter(toast => toast.options.placement === ToastPlacement.TopRight).reverse()
    );
    const bottomLeft = computed(() =>
      queue.value.filter(toast => toast.options.placement === ToastPlacement.BottomLeft)
    );
    const bottomRight = computed(() =>
      queue.value.filter(toast => toast.options.placement === ToastPlacement.BottomRight)
    );
    const leave = (el: unknown) => {
      if (el instanceof HTMLElement) {
        el.style.top = `${el.offsetTop}px`;
        el.style.position = 'absolute';
      }
    };

    const offsetStyle = computed(() => ({
      top: `${props.offset.top}px`,
      right: `${props.offset.right}px`,
      bottom: `${props.offset.bottom}px`,
      left: `${props.offset.left}px`,
    }));

    return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      leave,
      offsetStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.toast-container {
  z-index: $z-alert;
  pointer-events: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.top-left {
    align-items: start;

    .toast-enter-from,
    .toast-leave-to {
      transform: translateX(-50px);
      opacity: 0;
    }
  }

  &.top-right {
    .toast-enter-from,
    .toast-leave-to {
      transform: translateX(50px);
      opacity: 0;
    }
  }

  &.bottom-left {
    flex-direction: column-reverse;
    align-items: start;

    .toast-enter-from,
    .toast-leave-to {
      transform: translateX(-50px);
      opacity: 0;
    }
  }

  &.bottom-right {
    flex-direction: column-reverse;

    .toast-enter-from,
    .toast-leave-to {
      transform: translateX(50px);
      opacity: 0;
    }
  }
}

.toast-move,
.toast-enter-active {
  transition:
    transform 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8),
    opacity 0.5s;
}

.toast-leave-active {
  transition:
    transform 0.5s cubic-bezier(0.47, -0.45, 0.41, 0.98),
    opacity 0.5s;
}

.toast-new {
  margin-bottom: var(--spacing-100);
}
</style>
