import { useSessionStore } from '@getaccept/dsr-published-site/src/session/store/session.store';
import { getGlobalHandle } from '@getaccept/lib-shared-new/src/helpers';
import type { RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
import { Settings } from 'luxon';
import { PathName } from '@getaccept/lib-shared-new/src/enums/PathNames';
import { languageLoaded, loadLanguageAsync, userLanguage } from './lang';
import { useSessionStore as useSigningSiteSessionStore } from './session/store/session.store';
import { getIdOptions } from './signing/constants/eid-options';
import { SigningHelper } from './signing/helpers/signing.helper';
import { useSigningStore } from './signing/store/signing.store';
import type { Id } from './signing/types/eid';
import type { BankIdSigningType, BankIdStep } from './signing/types/enums/bankid-se';
import type { CriiptoStatus } from './signing/types/enums/criipto-status';
import type { EidType } from './signing/types/enums/eid-type';
import { useRootStore } from './store/root.store';

const router = createRouter({
  history: createWebHistory(getGlobalHandle()._basePath || '/'),
  routes: [
    {
      path: '/v/preview/:documentId',
      meta: { title: 'Signing', signingType: 'v3', preview: true },
      component: () => import('./pages/Signing.vue'),
    },
    {
      path: '/v/:documentId/:recipientId/a/:authId/video/:videoId?',
      meta: { title: 'Signing', signingType: 'v3', urlType: 'v' },
      component: () => import('./pages/Signing.vue'),
    },
    {
      path: '/v/:documentId/:recipientId/a/:authId/report-email',
      meta: { title: 'Signing', signingType: 'v3', urlType: 'v' },
      component: () => import('./pages/Signing.vue'),
    },
    {
      path: '/v/:documentId/:recipientId/a/:authId/',
      meta: { title: 'Signing', signingType: 'v3', urlType: 'v' },
      component: () => import('./pages/Signing.vue'),
    },
    {
      path: '/share/d/:universalId/',
      meta: { universal: true },
      component: () => import('./universal-link/components/UniversalLinkLobby.vue'),
    },
    {
      path: '/dsr/:entityId/:dealroomId',
      meta: { universal: true },
      component: () => import('@getaccept/dsr-published-site').then(m => m.UniversalLinkLobby),
    },
    {
      path: '/dsr/:entityId/:dealroomId/:participantId/:view?',
      component: () => import('@getaccept/dsr-published-site').then(m => m.DsrPublishedView),
      name: PathName.DrPublished,
      props: ({ params: { view }, query: { drawer } }: RouteLocationNormalized) => ({
        view,
        drawer,
      }),
    },
    {
      path: '/dsr/:documentId/:recipientId/a/:authId/:view?',
      component: () => import('@getaccept/dsr-published-site').then(m => m.DsrPublishedView),
      name: PathName.DrPublishedOld,
      props: ({ params: { view }, query: { drawer } }: RouteLocationNormalized) => ({
        view,
        drawer,
      }),
    },
    {
      path: '/dsr/:documentId/:recipientId/a/:authId/report-email/',
      component: () => import('@getaccept/dsr-published-site').then(m => m.DsrPublishedView),
    },
  ],
});

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  try {
    if (!languageLoaded.value) {
      const userLang = userLanguage();
      await loadLanguageAsync(userLang);
      Settings.defaultLocale = userLang;
    }

    const {
      recipientId,
      documentId,
      userId,
      authId,
      videoId,
      dealroomId,
      participantId,
      entityId,
    }: any = to.params;
    const {
      token,
      orderRef,
      step,
      signingType,
      autoStartToken,
      eidStatus,
      eidType,
      entityId: queryEntityId,
    } = to.query;
    const { preview, universal } = to.meta;

    if (to.path.includes('/dsr') && !universal) {
      if (to.name && to.name === from.name) {
        return next();
      }
      const { setIds } = useSessionStore();
      // TODO: remove authId from DR session when we no longer support old model URLs
      if (authId) {
        setIds({
          dealroomId: documentId,
          participantId: recipientId,
          authId,
          entityId: queryEntityId,
        });
      } else {
        setIds({ dealroomId, participantId, authId: undefined, entityId });
      }
      return next();
    }
    const store = useRootStore();

    store.setIds({
      documentId,
      recipientId,
      authId,
      userId,
      videoReminderId: videoId,
    });
    const hasBankIdParams: boolean = SigningHelper.hasValidBankIdParams(
      orderRef as string,
      step as BankIdStep,
      signingType as BankIdSigningType,
      autoStartToken as string
    );
    const hasCriiptoParams: boolean = SigningHelper.hasValidCriiptoParams(
      eidType as EidType,
      eidStatus as CriiptoStatus
    );
    const signingStore = useSigningStore();
    if (hasBankIdParams) {
      signingStore.setBankIdQueryParams(
        orderRef as string,
        step as BankIdStep,
        signingType as BankIdSigningType,
        autoStartToken as string
      );
    } else if (hasCriiptoParams) {
      const selectedEid = getIdOptions().find((value: Id) => value.key === (eidType as EidType));
      signingStore.setCriiptoQueryParams(selectedEid, eidStatus as CriiptoStatus);
    }
    const sessionStore = useSigningSiteSessionStore();
    if (preview) {
      store.setPreviewMode(true);
      if (token) {
        sessionStore.setPreviewToken(token as string);
      }
    } else if (token && !preview) {
      sessionStore.loadTokenSuccess({ jwt: token as string }, []);
    }
    if (token || hasBankIdParams) {
      return router.push({
        path: to.path,
        query: {
          pageId: to.query.pageId || undefined,
        },
      });
    }

    return next();
  } catch (error) {
    bugsnagClient?.notify(error);
    return next();
  }
});

router.onError(error => {
  bugsnagClient?.notify(error);
});

export default router;
