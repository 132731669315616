<template>
  <div
    :style="!showLoader && backgroundStyle"
    :class="['dr-published-app', { 'has-fixed-background': hasFixedBackground }]"
  >
    <div v-if="showLoader" class="circle">
      <FriedSpinner class="loader" />
    </div>
    <Lobby
      v-else-if="showLobby"
      :consent-steps="consentSteps"
      :next-auth-step="nextAuthStep"
      :show-dsr-unavailable="showDsrUnavailable"
      :entity="entity"
      :theme="theme"
      :submitting-response="authentication.submittingResponse"
      @identify-participant="submitAnswer([$event])"
      @consent-answer="submitConsent"
    />
    <router-view v-else-if="loadedEntity" />
    <Toasts :offset="{ top: 88, bottom: 72, right: 16, left: 16 }" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, onBeforeUnmount, watch } from 'vue';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
import { Toasts } from '@getaccept/lib-shared-new/src/toast';
import { sanitizeEmail } from '@getaccept/lib-shared-new/src/helpers';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import { storeToRefs } from 'pinia';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import { AuthenticationHelper } from '@getaccept/lib-shared-new/src/authentication/helpers/authentication.helper';
import { BackgroundType } from '@getaccept/lib-shared-new/src/entity/types/enums/background-type';
import { DateTime } from 'luxon';
import { useThemeStore } from '../../theme/theme.store';
import { useLoadDSR } from '../../published/composables/load-dsr.composable';
import { useSessionStore } from '../../session/store/session.store';
import { pusherClient } from '../../api/pusher/pusher-client';
import { useData } from '../../composables/use-data.composable';
import { useEntityStore } from '../../entity/store/entity.store';
import Lobby from './Lobby.vue';

export default defineComponent({
  components: {
    Lobby,
    Toasts,
  },
  setup() {
    const recalled = computed(() => !!status.value && status.value === DocumentStatus.Recalled);
    const unpublished = computed(
      () => !!status.value && status.value === DocumentStatus.Unpublished
    );

    const skipShowLobby = ref(false);
    const loading = ref(true);

    const { loadEntity, loadedEntity } = useData();
    const { entity } = storeToRefs(useEntityStore());
    const { theme } = storeToRefs(useThemeStore());
    const { error: drError, dsr } = useLoadDSR();
    const sessionStore = useSessionStore();
    const {
      authId,
      dealroomId,
      participantId,
      token,
      status,
      entityId,
      sessionError,
      authentication,
      tokenError,
      tokenLoading,
    } = storeToRefs(sessionStore);

    const showLobby = computed(() =>
      skipShowLobby.value
        ? false
        : !!nextAuthStep.value ||
          !!consentSteps.value ||
          gdprDeclined.value ||
          showDsrUnavailable.value
    );

    const pageTitle = computed(() => (dsr.value ? dsr.value.name : 'GetAccept'));
    const hasFixedBackground = computed(
      () =>
        !theme.value ||
        theme.value.backgroundType === BackgroundType.Fixed ||
        !theme.value.backgroundImage
    );

    const hasParams = computed(() => {
      if (dealroomId.value) {
        return true;
      }
      return !!dealroomId.value && !!participantId.value && (!!entityId.value || !!authId.value);
    });

    const hasError = computed(() => sessionError.value || !hasParams.value || drError.value);

    const hasValidToken = computed(() => {
      const notExpired: boolean =
        (token.value.exp && DateTime.now() < DateTime.fromSeconds(token.value.exp)) ||
        !token.value.exp;
      const tokenFound = !!token.value.jwt;
      return tokenFound && notExpired;
    });

    const showDsrUnavailable = computed(
      () => hasError.value || unpublished.value || recalled.value || tokenError.value
    );

    const showLoader = computed(() => loading.value || tokenLoading.value);

    const backgroundStyle = computed(() =>
      theme.value?.backgroundImage
        ? `background-image: url(${theme.value?.backgroundImage});`
        : `background-image: url('/assets/img/background/signing-page-bg.svg');`
    );

    const preventIosInputZoom = () => {
      if (navigator.userAgent.indexOf('iPhone') > -1) {
        document
          .querySelector('[name=viewport]')
          .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
      }
    };

    const unhandledrejectionHandler = event => {
      if (bugsnagClient) {
        bugsnagClient.notify(event);
      }
    };

    const nextAuthStep = computed(() => AuthenticationHelper.getNextAuthStep(authentication.value));

    const consentSteps = computed(() => {
      if (!authentication.value.steps.length) {
        return null;
      }
      return authentication.value.steps.filter(
        (step: AuthenticationStep) =>
          (step.type === AuthenticationStepType.TrackingConsent && step.data.error !== false) ||
          (step.type === AuthenticationStepType.GdprConsent && step.data.error !== false)
      );
    });

    const gdprDeclined = computed(() => {
      if (!authentication.value.steps.length) {
        return false;
      }
      return authentication.value.steps.some(
        (step: AuthenticationStep) =>
          step.type === AuthenticationStepType.GdprConsent && !!step.data.error
      );
    });

    const isPusherConnected = ref(false);
    const connectPusher = (token: string) => {
      if (isPusherConnected.value) {
        pusherClient.updateToken(token);
      } else {
        isPusherConnected.value = true;
        pusherClient.connect(token);
      }
    };

    watch(
      () => hasValidToken.value,
      () => {
        if (hasValidToken.value) {
          loadEntity();
          connectPusher(token.value.jwt);
        }
      }
    );

    watch(nextAuthStep, () => {
      const email = new URL(window.location.href).searchParams.get('email');
      const storedEmail = sessionStore.getStoredEmail(participantId.value);

      if (!email && !storedEmail) {
        return;
      }

      if (nextAuthStep.value?.type === AuthenticationStepType.IdentifyRecipient) {
        sessionStore.submitAnswer([
          {
            type: nextAuthStep.value?.type,
            value: sanitizeEmail(email || storedEmail),
          },
        ]);
      }
    });

    onMounted(async () => {
      window.addEventListener('unhandledrejection', unhandledrejectionHandler);
      preventIosInputZoom();
      if (!hasValidToken.value && hasParams.value) {
        const tracking = new URL(window.location.href).searchParams.get('tracking');
        await sessionStore.loadToken(
          tracking ? [{ type: AuthenticationStepType.TrackingConsent, value: tracking }] : []
        );
      }
      if (hasValidToken.value && hasParams.value && !showLobby.value) {
        sessionStore.setTokenLoading(false);
        await loadEntity();
        connectPusher(token.value.jwt);
      }
      window.document.title = pageTitle.value;
      loading.value = false;
    });

    onBeforeUnmount(() => {
      window.removeEventListener('unhandledrejection', unhandledrejectionHandler);
    });

    return {
      loading,
      consentSteps,
      showDsrUnavailable,
      showLobby,
      skipShowLobby,
      backgroundStyle,
      hasValidToken,
      theme,
      entity,
      hasFixedBackground,
      pageTitle,
      hasParams,
      hasError,
      nextAuthStep,
      gdprDeclined,
      authentication,
      submitAnswer: sessionStore.submitAnswer,
      submitConsent: sessionStore.submitConsent,
      tokenLoading,
      showLoader,
      loadedEntity,
    };
  },
});
</script>

<style lang="scss" scoped>
html {
  height: 100%;
  font-family: var(--default-font-family);

  body {
    margin: 0;
    height: 100%;
  }

  .dr-published-app {
    background: repeat scroll center;
    color: var(--base-blue);
    background-size: contain;
    height: 100%;
    background-color: var(--gray-98);
    background-position: top left calc(var(--spacing-25) * -1);

    &.has-fixed-background {
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }

    .circle {
      display: flex;
      background: white;
      border-radius: 100%;
      padding: var(--spacing-50);
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .loader {
      width: auto;
      height: auto;
    }
  }
}
</style>
<style lang="scss">
:root {
  --topbar-height: 4rem;
  --topbar-padding: var(--topbar-height);
}
</style>
