import type { Ref } from 'vue';
import { ref } from 'vue';
import { useStorage } from '@vueuse/core';
import type { IdentifyRecipientPayload } from '@getaccept/lib-shared-new/src/universal-link/types/identify-recipient-payload';
import { useEnvironment } from '@getaccept/lib-shared-new/src/composables/environment.composable';
import type { VerifyLinkResponse } from '@getaccept/lib-shared-new/src/universal-link/types/verify-link-response';
import { UniversalLinkHelper } from '../helpers/universal-link.helper';
import { UniversalLinkService } from '../../api/universal-link/services/universal-link.service';

export interface UniversalLinkStorage {
  redirectURL: string;
}

export function useUniversalLink() {
  const loading: Ref<boolean> = ref(false);
  const failedToVerify: Ref<boolean> = ref(false);
  const failedRestricted: Ref<boolean> = ref(false);
  const verifyLinkResponse: Ref<VerifyLinkResponse | null> = ref(null);
  const getUniversalLinkStorageKey = (key: string) => `universalLink:${key}`;
  const universalLinkKey: string = UniversalLinkHelper.getUniversalLinkKey(window.location.href);
  const universalLinkStorage = useStorage<UniversalLinkStorage>(
    getUniversalLinkStorageKey(universalLinkKey),
    {
      redirectURL: null,
    }
  );

  const verifyLink = async (dealroomId: string, entityId: string): Promise<void> => {
    loading.value = true;
    failedToVerify.value = false;
    try {
      const response = await UniversalLinkService.verify(dealroomId, entityId);
      verifyLinkResponse.value = {
        ...response,
        entityId,
        dealroomId,
      };
      loading.value = false;
    } catch (e) {
      if (e.message === 'Dealroom access denied, Restricted') {
        failedRestricted.value = true;
      }
      loading.value = false;
      failedToVerify.value = true;
      verifyLinkResponse.value = null;
    }
  };

  const loadingRedirectURL: Ref<boolean> = ref(false);
  const redirectURLError = ref(null);
  const getRedirectUrl = async (
    gdprConsent: boolean,
    dealroomId: string,
    recipient: IdentifyRecipientPayload,
    entityId: string,
    trackingConsent: '0' | '1'
  ) => {
    loadingRedirectURL.value = true;
    redirectURLError.value = null;

    try {
      const url = await UniversalLinkService.identifyParticipant(
        gdprConsent,
        dealroomId,
        recipient,
        entityId
      );
      loadingRedirectURL.value = false;
      redirectURLError.value = null;
      const formattedUrl = new URL(url);
      formattedUrl.searchParams.set('email', recipient.email);
      if (trackingConsent) {
        formattedUrl.searchParams.set('tracking', trackingConsent);
      }
      const pageId = new URLSearchParams(window.location.search).get('pageId');
      if (pageId) {
        formattedUrl.searchParams.set('pageId', pageId);
      }

      const drawer = new URLSearchParams(window.location.search).get('drawer');
      if (drawer) {
        formattedUrl.searchParams.set('drawer', drawer);
      }

      const { isLocalHost, isSandbox } = useEnvironment();

      if (isLocalHost.value || isSandbox.value) {
        formattedUrl.protocol = window.location.protocol;
        formattedUrl.host = window.location.host;
      }

      universalLinkStorage.value.redirectURL = String(formattedUrl);
      window.location.assign(String(formattedUrl));
    } catch (error) {
      loadingRedirectURL.value = false;
      redirectURLError.value = error;
    }
  };
  return {
    verifyLink,
    loading,
    failedToVerify,
    verifyLinkResponse,
    failedRestricted,
    getRedirectUrl,
    redirectURLError,
    loadingRedirectURL,
    universalLinkStorage,
    getUniversalLinkStorageKey,
  };
}
