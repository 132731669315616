<template>
  <div class="container">
    <div v-if="logoUrl" class="logo">
      <img :src="logoUrl" :alt="t('logo')" />
    </div>
    <FriedH2>{{ t('dsr_unavailable_header') }}</FriedH2>
    <FriedParagraph>{{ t('dsr_unavailable_paragraph') }}</FriedParagraph>
    <div class="sub-content">
      <FriedH3>{{ t('dsr_now_what') }}</FriedH3>
      <FriedParagraph>{{ t('dsr_now_what_paragraph') }}</FriedParagraph>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DsrUnavailable',
  props: {
    logoUrl: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $xxs) {
    width: 200px;
  }

  .logo {
    width: 100%;
    margin-bottom: var(--spacing-150);

    @media screen and (max-width: $xs) {
      text-align: center;
    }

    > img {
      max-width: 50%;
      height: auto;
    }
  }

  h2 {
    margin-bottom: var(--spacing-50);
  }

  .sub-content {
    margin-top: var(--spacing-200);
  }
}
</style>
