import type { AuthenticationStep } from '../types/authentication-step';
import { AuthenticationStepType } from '../types/enums/authentication-step-type';
import type { Token } from '../../session/type/token';
import type { AuthenticationStepInput } from '../types/authentication-step-input';

export class AuthenticationHelper {
  static declinedConsent(authSteps: AuthenticationStep[], type: AuthenticationStepType): boolean {
    return authSteps.some((step: AuthenticationStep) => step.type === type);
  }

  static formatRedirectUrl(token: Token) {
    const pathRegExp = /\/([a-z,\d]+)\/([a-z,\d]+)\/a\/([a-z,\d]+)/g;
    const redirectUrl: string = window.location.href.replace(
      pathRegExp,
      token.metaData.redirectUrl
    );
    const formattedUrl = new URL(redirectUrl);
    formattedUrl.searchParams.append('token', token.jwt);
    return String(formattedUrl);
  }

  static getAuthenticationAnswers(
    authSteps: AuthenticationStep[],
    authAnswers: AuthenticationStepInput[]
  ): AuthenticationStepInput[] {
    const stepsWithError: AuthenticationStep[] = authSteps.filter(
      (step: AuthenticationStep) => step.data.error
    );
    return authAnswers.filter(
      (answer: AuthenticationStepInput) =>
        !stepsWithError.some((step: AuthenticationStep) => step.type === answer.type)
    );
  }

  static getExistingAuthStepsInput(
    authStepsInput: AuthenticationStepInput[],
    answers: AuthenticationStepInput[]
  ) {
    const existingAuthStepsInput: AuthenticationStepInput[] = [...answers];
    authStepsInput.forEach((authStepInput: AuthenticationStepInput) => {
      const alreadyHasAnswer: boolean = answers.some(
        (answer: AuthenticationStepInput) => answer.type === authStepInput.type
      );

      if (alreadyHasAnswer) {
        const index: number = answers.findIndex(
          (answer: AuthenticationStepInput) => answer.type === authStepInput.type
        );
        existingAuthStepsInput.splice(index, 1, authStepInput);
      } else {
        existingAuthStepsInput.push(authStepInput);
      }
    });
    return existingAuthStepsInput;
  }

  static getDeclinedConsents(steps: AuthenticationStep[]): AuthenticationStepInput[] {
    const declinedGDPRConsent = AuthenticationHelper.declinedConsent(
      steps,
      AuthenticationStepType.GdprConsent
    );
    const declinedTrackingConsent = AuthenticationHelper.declinedConsent(
      steps,
      AuthenticationStepType.TrackingConsent
    );
    const authStepsInput: AuthenticationStepInput[] = [];
    if (declinedGDPRConsent) {
      authStepsInput.push({ value: '0', type: AuthenticationStepType.GdprConsent });
    }
    if (declinedTrackingConsent) {
      authStepsInput.push({ value: '0', type: AuthenticationStepType.TrackingConsent });
    }
    return authStepsInput;
  }

  static getNextAuthStep = ({
    steps,
    answers,
  }: {
    steps: AuthenticationStep[];
    answers: AuthenticationStepInput[];
  }) => {
    if (!steps.length) {
      return null;
    }
    const sortedSteps: AuthenticationStep[] = [...steps].sort((step: AuthenticationStep) =>
      step.type === AuthenticationStepType.IdentifyRecipient ? -1 : 1
    );

    return sortedSteps.find((step: AuthenticationStep) => {
      if (
        step.data.error !== false &&
        step.type !== AuthenticationStepType.TrackingConsent &&
        step.type !== AuthenticationStepType.GdprConsent
      ) {
        return !answers.some((input: AuthenticationStepInput) => input.type === step.type);
      }
    });
  };
}
