<template>
  <div class="universal-form">
    <FriedH3 class="title">
      {{ t('dr_first_time_in_the_room') }}
    </FriedH3>
    <FriedParagraph class="description">{{ t('dr_please_enter_your_credentials') }}</FriedParagraph>
    <FriedInput
      v-model.trim="updatedEmail"
      class="email-input"
      :label="t('email')"
      :type="InputType.Email"
      autocomplete="email"
      data-external="dr-universal-link-email-input"
      :error="!!emailInvalidMessage"
      :error-message="emailInvalidMessage"
      required
      @keydown.enter="handleSubmit"
    />
    <ParticipantDetails
      :participant="updatedParticipant"
      @enter="handleSubmit"
      @update="handleInput"
    />
    <div class="buttons">
      <FriedButton
        v-if="canGoBack"
        :button-type="ButtonType.Secondary"
        :disabled="loadingRedirectURL"
        data-external="dr-universal-link-back-button"
        class="back-button"
        @click="$emit('try-again')"
      >
        {{ t('back') }}
      </FriedButton>
      <FriedButton
        data-external="dr-universal-link-enter-room-button"
        class="enter-room-button"
        :loading="loadingRedirectURL"
        :disabled="!updatedEmail || loadingRedirectURL || disabled"
        @click="handleSubmit"
      >
        {{ t('enter_room') }}
      </FriedButton>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { InputType, ButtonType } from '@getaccept/fried-tofu';
import { useI18n } from 'vue-i18n';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import { validateEmail } from '@getaccept/lib-shared-new/src/helpers';
import type { IdentifyRecipientPayload } from '@getaccept/lib-shared-new/src/universal-link/types/identify-recipient-payload';
import { RecipientsHelper } from '@getaccept/lib-shared-new/src/recipients/helpers/recipients.helper';
import type { VerifyLinkResponse } from '@getaccept/lib-shared-new/src/universal-link/types/verify-link-response';
import { useUniversalLink } from '../composables/universal-link.composable';
import { useSessionStore } from '../../session/store/session.store';
import ParticipantDetails from './ParticipantDetails.vue';

export default defineComponent({
  components: {
    ParticipantDetails,
  },
  props: {
    consents: {
      type: Array as PropType<
        {
          type: AuthenticationStepType;
          value: string | boolean;
        }[]
      >,
      default: () => [],
    },
    email: {
      type: String,
      default: '',
    },
    canGoBack: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    verifyLinkResponse: {
      type: Object as PropType<VerifyLinkResponse>,
      default: () => ({}),
    },
  },
  emits: ['try-again'],
  setup(props) {
    const { t } = useI18n();
    const { getRedirectUrl, loadingRedirectURL, redirectURLError } = useUniversalLink();
    const sessionsStore = useSessionStore();
    const { entityId, dealroomId: sessionDealroomId } = storeToRefs(sessionsStore);
    const updatedEmail = ref(props.email);
    const updatedParticipant = ref<{
      firstName: string;
      lastName: string;
      title?: string;
      companyName?: string;
    }>({ firstName: '', lastName: '' });

    const gdprConsent = computed(
      () =>
        props.consents.find(({ type }) => type === AuthenticationStepType.GdprConsent)?.value ??
        true
    );

    const trackingConsent = computed(
      () =>
        (props.consents.find(({ type }) => type === AuthenticationStepType.TrackingConsent)
          ?.value as '0' | '1') ?? null
    );

    const invalidEmail = ref(false);

    const dealroomId = computed(
      () =>
        props.verifyLinkResponse?.documentId ||
        props.verifyLinkResponse?.dealroomId ||
        sessionDealroomId.value
    );

    const handleSubmit = () => {
      if (!updatedEmail.value || loadingRedirectURL.value || props.disabled) {
        return;
      }

      if (!validateEmail(updatedEmail.value?.toLowerCase())) {
        invalidEmail.value = true;
        return;
      }

      getRedirectUrl(
        Boolean(gdprConsent.value),
        dealroomId.value,
        { ...updatedParticipant.value, email: updatedEmail.value },
        props.verifyLinkResponse.entityId || entityId.value,
        trackingConsent.value
      );
    };

    const emailInvalidMessage = computed(() => {
      if (!invalidEmail.value && !redirectURLError.value) {
        return '';
      }

      if (
        redirectURLError.value?.message.includes(
          'This email address belongs to the owner of the room.'
        )
      ) {
        return t('email_belongs_to_room_owner');
      }

      return t('enter_a_valid_email');
    });

    const handleInput = (updatePayload: IdentifyRecipientPayload) => {
      updatedParticipant.value = { ...updatedParticipant.value, ...updatePayload };
    };

    const setParticipantValuesFromProp = (email: string) => {
      const participantData = RecipientsHelper.getRecipientDataFromEmail(
        RecipientsHelper.formatSearchString(email)
      );
      updatedParticipant.value.firstName = participantData.firstName;
      updatedParticipant.value.lastName = participantData.lastName;
    };

    onMounted(() => {
      const urlEmail = new URL(window.location.href).searchParams.get('email');
      if (urlEmail) {
        updatedEmail.value = urlEmail;
        const url = new URL(window.location.href);
        url.searchParams.delete('email');
        window.history.replaceState({}, '', url.toString());
      }
      if (!props.email) {
        return;
      }

      setParticipantValuesFromProp(props.email);
    });

    return {
      t,
      updatedParticipant,
      updatedEmail,
      handleInput,
      handleSubmit,
      InputType,
      ButtonType,
      loadingRedirectURL,
      emailInvalidMessage,
      redirectURLError,
    };
  },
});
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: var(--spacing-50);
}

.description {
  margin-bottom: var(--spacing-100);
}

.email-input {
  width: 100%;
  margin-bottom: var(--spacing-50);
}

.enter-room-button,
.back-button {
  width: 100%;
}

.buttons {
  display: flex;
  margin-top: var(--spacing-150);
  column-gap: var(--spacing-75);
}
</style>
