<template>
  <div class="signing-site-top-bar" data-external="signing-top-bar-container">
    <img v-if="logoUrl" class="logo" :src="logoUrl" :alt="t('logotype')" />
    <FriedGetAcceptLogo v-else class="logo" :aria-label="t('logotype')" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

export interface Props {
  logoUrl?: string;
}

withDefaults(defineProps<Props>(), {
  logoUrl: '',
});
const { t } = useI18n();
</script>

<style lang="scss" scoped>
.signing-site-top-bar {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: $z3;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-100);
  width: 100%;
  height: var(--topbar-height);
  padding: var(--spacing-50) var(--spacing-100);
  box-shadow: var(--shadow-2);
  background-color: var(--white);

  .logo {
    max-width: 10rem;
    max-height: 100%;
    object-fit: contain;
    align-self: center;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: right;
  }
}
</style>
