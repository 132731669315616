export class UniversalLinkHelper {
  static universalLinkRegex = /(?:share)\/(?:d\/)(.*?)(?:\/|$|\?)/;

  static isUniversalLink(url: string) {
    return UniversalLinkHelper.universalLinkRegex.test(url);
  }

  static getUniversalLinkKey(url: string): string {
    let key = '';
    if (UniversalLinkHelper.isUniversalLink(url)) {
      const [, universalLinkId]: RegExpMatchArray = url.match(
        UniversalLinkHelper.universalLinkRegex
      );
      key = `GA_SIGNING_PAGE_UNIVERSAL_${universalLinkId}`;
    }
    return key;
  }
}
