<template>
  <div class="private-form">
    <FriedH3 class="title">{{ t('dr_welcome_to_room') }}</FriedH3>
    <FriedParagraph class="description">{{ t('dr_please_enter_your_email_below') }}</FriedParagraph>
    <FriedInput
      v-model.trim="updatedEmail"
      class="email-input"
      :label="t('email')"
      :type="InputType.Email"
      autocomplete="email"
      data-external="dsr-room-access-email-input"
      :error-message="emailInvalidMessage"
      required
      @keydown.enter="handleEnter"
    />
    <FriedButton
      :disabled="!updatedEmail || disabled"
      data-external="dsr-enter-room-button"
      class="enter-room-button"
      @click="handleEnter"
    >
      {{ t('enter_room') }}
    </FriedButton>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { InputType } from '@getaccept/fried-tofu';
import { useI18n } from 'vue-i18n';
import { validateEmail } from '@getaccept/lib-shared-new/src/helpers';

export default defineComponent({
  props: {
    email: { type: String, default: '' },
    disabled: { type: Boolean },
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const updatedEmail = ref(props.email);
    const invalidEmail = ref(false);

    const handleEnter = () => {
      if (!validateEmail(updatedEmail.value?.toLowerCase())) {
        invalidEmail.value = true;
        return;
      }

      emit('submit', updatedEmail.value);
    };

    const emailInvalidMessage = computed(() =>
      invalidEmail.value ? t('enter_a_valid_email') : ''
    );

    return { t, updatedEmail, handleEnter, InputType, invalidEmail, emailInvalidMessage };
  },
});
</script>
<style lang="scss" scoped>
.private-form {
  .title {
    margin-bottom: var(--spacing-50);
  }

  .description {
    margin-bottom: var(--spacing-100);
  }

  .email-input {
    width: 100%;
    margin-bottom: var(--spacing-50);
    margin-top: var(--spacing-50);
  }

  .enter-room-button {
    margin-top: var(--spacing-150);
    width: 100%;
  }
}
</style>
