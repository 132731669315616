import { getImageURLQuery, viewerRecipientInputQuery } from '@getaccept/editor-lib-new';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import type {
  InviteRecipientData,
  SigningPageRecipient,
} from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import Axios from 'axios';
import { RecipientRole } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-role';
import { apolloClient } from '../../../apollo';
import type { InviteRecipientInfo } from '../../../signing/types/invite-recipient-info';
import {
  addRecipientViewMutation,
  downloadDocumentMutation,
  inviteMutation,
} from '../graphql/document.mutations';
import {
  documentQuery,
  documentRevisionsQuery,
  externalId,
  getDownloadUrlQuery,
  getRecipientsSSNQuery,
  isDocumentViewable,
  partiallySignedEventQuery,
  recipientsQuery,
} from '../graphql/document.queries';
import type { RecipientSSN } from '../types/recipient-ssn';

export class DocumentService {
  static async getDocument(): Promise<SigningPageDocument> {
    const {
      data: { document },
    } = await apolloClient.query({
      query: documentQuery,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    const recipients = document.recipients.map((recipient: SigningPageRecipient) =>
      recipient.role === RecipientRole.InternalApproverAPIFallback
        ? { ...recipient, role: RecipientRole.Approver }
        : recipient
    );
    return { ...document, recipients };
  }

  static async loadExternalId(): Promise<string> {
    const {
      data: { document },
    } = await apolloClient.query({
      query: externalId,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return document.externalId;
  }

  static async getDocumentRevisions(): Promise<Partial<SigningPageDocument>> {
    const {
      data: { document },
    } = await apolloClient.query({
      query: documentRevisionsQuery,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return document;
  }

  static async loadEditorRecipientInput(): Promise<EditorRecipientInput[]> {
    const {
      data: { editorRecipientInput },
    } = await apolloClient.query({
      query: viewerRecipientInputQuery,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return editorRecipientInput;
  }

  static async addRecipientView(revision: number): Promise<boolean> {
    const {
      data: { addRecipientView },
    } = await apolloClient.mutate({
      mutation: addRecipientViewMutation,
      variables: { revision },
    });
    return addRecipientView;
  }

  static async getRecipientsSSN(): Promise<RecipientSSN[]> {
    const {
      data: { getRecipientsSSN },
    } = await apolloClient.query({
      query: getRecipientsSSNQuery,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return getRecipientsSSN;
  }

  static async getDownloadUrl(): Promise<string> {
    const {
      data: { getDownloadUrl },
    } = await apolloClient.query({
      query: getDownloadUrlQuery,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return getDownloadUrl;
  }

  static async getRecipients(): Promise<SigningPageRecipient[]> {
    const {
      data: {
        document: { recipients },
      },
    } = await apolloClient.query({
      query: recipientsQuery,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });

    // eslint-disable-next-line sonarjs/no-identical-functions
    return recipients.map((recipient: SigningPageRecipient) =>
      recipient.role === RecipientRole.InternalApproverAPIFallback
        ? { ...recipient, role: RecipientRole.Approver }
        : recipient
    );
  }

  static async download(): Promise<void> {
    await apolloClient.mutate({
      mutation: downloadDocumentMutation,
    });
  }

  static async invite(payload: InviteRecipientData): Promise<Partial<SigningPageRecipient>> {
    const { data } = await apolloClient.mutate({
      mutation: inviteMutation,
      variables: { payload },
    });
    return data;
  }

  static async logEvent(
    documentId: string,
    recipientId: string,
    authId: string,
    event: any
  ): Promise<void> {
    const body = new FormData();
    body.append('event_name', event);
    body.append('event_meta_id', '');
    body.append('event_meta_data', '');
    await Axios.post(`/view/${documentId}/${recipientId}/${authId}/event`, body);
  }

  static async reject(
    reason: string,
    documentId: string,
    recipientId: string,
    authId: string
  ): Promise<void> {
    const body = new FormData();
    body.append('reason', reason);
    await Axios.post(`/view/${documentId}/${recipientId}/${authId}/reject`, body);
  }

  static async forward(
    documentId: string,
    recipientId: string,
    authId: string,
    recipientInfo: InviteRecipientInfo
  ): Promise<{ status: number; error: string }> {
    const body = new FormData();
    body.append('first-name', recipientInfo.firstName);
    body.append('last-name', recipientInfo.lastName);
    body.append('email', recipientInfo.email);
    body.append('mobile', recipientInfo.mobile);
    body.append('title', recipientInfo.title);
    body.append('company_name', recipientInfo.companyName);
    body.append('company_number', recipientInfo.companyNumber);
    body.append('transfer', recipientInfo.transferSignature.toString());
    const { data } = await Axios.post(`/view/${documentId}/${recipientId}/${authId}/forward`, body);
    return data;
  }

  static async getEditorImageURL(
    id: string,
    height?: string
  ): Promise<{ url: string; externalId: string; blurhash: string }> {
    const {
      data: { image },
    } = await apolloClient.query({
      query: getImageURLQuery,
      variables: { id, height },
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });

    return {
      url: image?.url || '',
      externalId: image?.externalId || undefined,
      blurhash: image?.blurhash || '',
    };
  }

  static async loadPartiallySignedFields(): Promise<Partial<PartiallySignedEvent[]>> {
    const {
      data: { partiallySignedEvent },
    } = await apolloClient.query({
      query: partiallySignedEventQuery,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return partiallySignedEvent;
  }

  static async isDocumentViewable(): Promise<boolean> {
    const {
      data: { documentViewable },
    } = await apolloClient.query({
      query: isDocumentViewable,
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });
    return documentViewable;
  }
}
