import type { LocalStorageSubKey } from '../type/subkey';

export class LocalStorageHelper {
  private static key = 'GA_SIGNING_PAGE';

  static getRecipientIdFromUrl(url: string): string {
    const regex = /(?:v3|v|dsr)\/([^/]*?)\/([^/]*?)\/a\/([^/]*?)(?:\/|$)/;
    const match: boolean = regex.test(url);
    if (match) {
      const [, , recipientId]: RegExpMatchArray = url.match(regex);
      return recipientId;
    }
    return '';
  }
  static getDocumentIdFromPreviewUrl(url: string): string {
    const regex = /\/v\/preview\/([^/]*?)(?:\/|$|\?)/;
    const match: boolean = regex.test(url);
    if (match) {
      const [, documentId]: RegExpMatchArray = url.match(regex);
      return documentId;
    }
    return '';
  }

  static buildKey = (url: string, subkey: LocalStorageSubKey): string =>
    `${this.key}_${subkey}_${this.getRecipientIdFromUrl(url)}`;

  static buildPreviewKey = (url: string, subkey: LocalStorageSubKey): string =>
    `${this.key}_PREVIEW_${subkey}_${this.getDocumentIdFromPreviewUrl(url)}`;
}
